import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

import ArrowLeft from 'assets/service/chevron_left.png';
import ArrowRight from 'assets/service/chevron_right.png';
import ReviewItem from 'components/Home/ReviewItem';
import { IFranchiseeList, ImgUrlState, IPolicyList } from 'types/service';
import ServiceItem from './ServiceItem';

interface IProps {
  imgUrl: ImgUrlState[];
  isPolicy?: boolean;
  stores?: IFranchiseeList[] | IPolicyList[];
  link?: string;
  rending?: boolean;
}

const reactionIndex = isMobile ? 2 : 1;
const reactionTranslate = isMobile ? -348 : -1040;
const reactionLength = isMobile ? 4 : 7;

function FranchiseeCarousel({ imgUrl, stores, link, rending = false }: IProps) {
  const [currIndex, setCurrIndex] = useState(rending ? 0 : reactionIndex);
  const slideRef = useRef<HTMLDivElement | null>(null);
  let timeOutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    initSlidePosition(rending ? 0 : reactionIndex);
  }, []);

  const initSlidePosition = (position: number) => {
    slideRef.current!.style.transform = `translateX(calc(${
      reactionTranslate * position
    }px))`;
  };

  const onClickLeft = async () => {
    // 모바일 랜딩페이지 슬라이드(무한x)
    if (rending && currIndex === 0) {
      return;
    }
    if (rending) {
      moveSlide(currIndex - 1);
      setCurrIndex(currIndex - 1);
      return;
    }

    await moveSlide(currIndex - 1);
    clearTimeout(timeOutRef.current as NodeJS.Timeout);
    timeOutRef.current = setTimeout(() => {
      if (currIndex === reactionIndex) {
        setCurrIndex(imgUrl.length - reactionLength);
        initSlidePosition(imgUrl.length - reactionLength);
      } else {
        setCurrIndex(currIndex - 1);
      }
      slideRef.current!.style.transition = '0s';
      slideRef.current!.style.pointerEvents = 'all';
    }, 500);
  };

  const onClickRight = async () => {
    // 모바일 랜딩페이지 슬라이드(무한x)
    if (rending && currIndex === 3) {
      return;
    }
    if (rending) {
      moveSlide(currIndex + 1);
      setCurrIndex(currIndex + 1);
      return;
    }

    await moveSlide(currIndex + 1);
    clearTimeout(timeOutRef.current as NodeJS.Timeout);
    timeOutRef.current = setTimeout(() => {
      if (currIndex === imgUrl.length - reactionLength) {
        setCurrIndex(reactionIndex);
        initSlidePosition(reactionIndex);
      } else {
        setCurrIndex(currIndex + 1);
      }
      slideRef.current!.style.transition = '0s';
      slideRef.current!.style.pointerEvents = 'all';
    }, 500);
  };

  const moveSlide = (nextValue: number) => {
    slideRef.current!.style.transition = '300ms';
    slideRef.current!.style.transform = `translateX(calc(${
      reactionTranslate * nextValue
    }px))`;
  };

  return (
    <CarouselContainer>
      <CarouselContent>
        <Container>
          <ReviewWrapper ref={slideRef}>
            {stores
              ? stores.map((item) => (
                  <ServiceItem
                    key={item.id}
                    item={item}
                    link={link!}
                    isPolicy={item.isPolicy}
                    isLandingPage
                  />
                ))
              : imgUrl.map((item: ImgUrlState, idx: number) => (
                  <ReviewItem
                    key={idx}
                    imgUrl={item.img}
                    width={isMobile ? '330px' : '500px'}
                    height={isMobile ? '220px' : '280px'}
                    alt={item.alt}
                  />
                ))}
          </ReviewWrapper>
          <ScrollToLeftBtn onClick={onClickLeft} isFranchisee>
            <Icon
              src={ArrowLeft}
              width={isMobile ? '18px' : '20px'}
              height={isMobile ? '18px' : '20px'}
              alt='이전.'
            />
          </ScrollToLeftBtn>
          <ScrollToRightBtn onClick={onClickRight} isFranchisee>
            <Icon
              src={ArrowRight}
              width={isMobile ? '18px' : '20px'}
              height={isMobile ? '18px' : '20px'}
              alt='다음.'
            />
          </ScrollToRightBtn>
        </Container>
      </CarouselContent>
    </CarouselContainer>
  );
}

const CarouselContainer = styled.div`
  position: relative;
  @media ${(props) => props.theme.mobile} {
    margin-top: 42px;
  }
`;

const CarouselContent = styled.div`
  width: 100%;
  height: 300px;
  overflow-x: hidden;
  @media ${(props) => props.theme.mobile} {
    height: 235px;
    overflow-x: scroll;
    overflow-y: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ReviewWrapper = styled.div<{ ref: any }>`
  display: flex;
  gap: 20px;
  width: 5180px;
  @media ${(props) => props.theme.mobile} {
    gap: 18px;
    width: 1164px;
  }
  touch-action: pan-y;
`;

const ScrollBtn = styled.button<{ isFranchisee?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.12);
  position: absolute;
  top: 50%;
  cursor: pointer;
  opacity: 0.8;
  transform: translateY(-50%);
  @media ${(props) => props.theme.mobile} {
    /* display: none; */
    width: 48px;
    height: 48px;
  }
`;
const ScrollToLeftBtn = styled(ScrollBtn)`
  left: 8px;
  @media ${(props) => props.theme.mobile} {
    left: 4px;
  }
`;
const ScrollToRightBtn = styled(ScrollBtn)`
  right: 8px;
  @media ${(props) => props.theme.mobile} {
    right: 4px;
  }
`;

const Icon = styled.img`
  object-fit: contain;
`;

export default FranchiseeCarousel;
