import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import FlexWrap from 'components/_common/FlexWrap';
import InnerLayout from 'components/_common/InnerLayout';
import BenefitTypeCard from 'components/Home/BenefitTypeCard';
import { benefitType } from 'model/homeDataClasses';
import Typography from 'components/_common/Typography';
import useDeviceCheck from 'hooks/useDeviceCheck';

function TaxFreeBenefitsSection() {
  const { isTablet } = useDeviceCheck();
  const imageSlideRef1 = useRef<HTMLDivElement[]>([]);
  const imageSlideRef2 = useRef<HTMLDivElement[]>([]);
  const observer = new IntersectionObserver((targets) => {
    if (!targets[0].isIntersecting) {
      return;
    }
    imageSlideRef1.current[0].style.animationPlayState = 'running';
    imageSlideRef2.current[0].style.animationPlayState = 'running';
    observer.disconnect();
  });

  useEffect(() => {
    observer.observe(imageSlideRef1.current[0]);
  }, []);

  return (
    <Container>
      <Inner>
        <TitleContainer>
          <Typography size='1.3125rem' fontWeight='500' color='#1856CD'>
            대부분의 매장이 누릴 수 있는
          </Typography>
          <Typography
            size={isTablet ? '1.75rem' : '2rem'}
            fontWeight='700'
            color='#191919'
          >
            면세 판매 혜택, 놓치지 마세요!
          </Typography>
        </TitleContainer>
      </Inner>
      <SlideContainer>
        <Slide
          ref={(el) => (imageSlideRef1.current[0] = el as HTMLDivElement)}
          translateX={isTablet ? '-11.875rem' : '-15.125rem'}
        >
          {benefitType.map((item, index) => (
            <BenefitTypeCard key={index} text={item.text} icon={item.icon} />
          ))}
        </Slide>
        <Slide
          ref={(el) => (imageSlideRef2.current[0] = el as HTMLDivElement)}
          translateX={isTablet ? '-11.875rem' : '-15.125rem'}
          isReverse
        >
          {benefitType.map((item, index) => (
            <BenefitTypeCard key={index} text={item.text} icon={item.icon} />
          ))}
        </Slide>
      </SlideContainer>
    </Container>
  );
}
const Container = styled.section`
  padding: 6.75rem 0;
  overflow: hidden;
  @media ${(props) => props.theme.tablet} {
    padding: 5.5rem 0 7.5rem 0;
  }
`;
const Slide = styled.div<{ isReverse?: boolean; translateX: string }>`
  display: flex;
  width: calc(15.125rem * 30);
  overflow: hidden;
  gap: 1.25rem;
  animation: taxFreeSlide linear infinite;
  animation-direction: ${(props) => props.isReverse && 'reverse'};
  animation-duration: 120s;
  animation-play-state: paused;
  box-shadow: 0px 0px 40px 0px #dde4ef;
  @keyframes taxFreeSlide {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: ${(props) => `translateX(calc(${props.translateX} * 15))`};
    }
  }
  @media ${(props) => props.theme.tablet} {
    width: calc(11.875rem * 30);
    animation-duration: 134s;
  }
`;
const Inner = styled(InnerLayout)`
  text-align: center;
`;
const SlideContainer = styled(FlexWrap)`
  margin-top: 3.88rem;
  flex-direction: column;
  gap: 2rem;
  @media ${(props) => props.theme.tablet} {
    margin-top: 1.69rem;
    gap: 1.25rem;
  }
`;
const TitleContainer = styled(FlexWrap)`
  flex-direction: column;
`;

export default TaxFreeBenefitsSection;
