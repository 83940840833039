import { createGlobalStyle, DefaultTheme } from 'styled-components';
import reset from 'styled-reset';

export const theme: DefaultTheme = {
  primaryColor: '#1B84A2',
  darkPrimaryColor: '#005F83',
  primaryTransparent: 'rgba(27, 132, 162, 0.8)',
  secondaryColor: '#FF6464',
  primaryBgColor: '#05191F',
  primaryTextColor: '#146076',
  warningTextColor: '#FF5239',
  fontColorTitle: '#000',
  fontColorContent: '#3A3B3E',
  fontColorFooter: '#5F6165',
  fontColorSubContent: '#9FA1A7',
  secondaryBgColor: '#F2F2F2',
  mobile: '(max-width: 576px)',
  tablet: '(max-width: 1023px)',
  desktop: '(min-width: 1024px)',
  bannerMobile: '(max-width: 575px)',
  bannerTablet: '(min-width: 576px) and (max-width: 1023px)',
  bannerDesktop: '(min-width: 1024px) and (max-width: 1279px)',
  bannerLargeDesktop: '(min-width: 1280px)',
};

export const GlobalStyle = createGlobalStyle`
    ${reset};
    
    * {
        box-sizing: border-box;
        font-family: 'Pretendard', sans-serif;
      }
    html{
      background-color: #fff;
      font-family: 'Pretendard', sans-serif;
      font-weight: 500;
      @media screen and (min-width: 1024px){
        font-size: 16px;
      }
      @media screen and (max-width: 1023px){
        font-size: 14px;
      }
    }
    button{
      border:none;
      cursor:pointer;
      background-color:transparent;
      padding: 0;
      :hover{
        opacity:0.8;
      }
      transition:200ms all ease-in;
    }
    a {
      text-decoration: none;
      color: ${(props) => props.theme.fontColorTitle};
    }
     /* Works for Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
      /* Works for Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
    .custom-shape-divider-bottom-1,.custom-shape-divider-bottom-2 {
    position: absolute;
    z-index:-1;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-bottom-1 svg {
    position: relative;
    display: block;
    width: calc(190% + 1.3px);
    height: 520px;
    @media ${(props) => props.theme.tablet}{
      width: calc(200% + 1.3px);
      height: 440px;
    }
    @media ${(props) => props.theme.mobile}{
      width: calc(200% + 1.3px);
      height: 40px;
    }
}
.custom-shape-divider-bottom-2 svg{
  position: relative;
    display: block;
    width: calc(290% + 1.3px);
    height: 400px;
    @media ${(props) => props.theme.tablet}{
      width: calc(200% + 1.3px);
      height: 300px;
    }
    @media ${(props) => props.theme.mobile}{
      width: calc(200% + 1.3px);
      height: 200px;
    }
}

.custom-shape-divider-bottom-1 .shape-fill, .custom-shape-divider-bottom-2{
    fill: #F4F8FF;
}
`;
