import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';

import FlexWrap from 'components/_common/FlexWrap';
import { PolicyItemContent } from 'constants/Policy';
import { serviceRoutes } from 'routes';
import styled from 'styled-components';
import { IPolicyList } from 'types/service';

interface IProps {
  policyList: IPolicyList[];
}

function PolicyList({ policyList }: IProps) {
  return (
    <List>
      {policyList
        .slice(0)
        .reverse()
        .map((item, index) => (
          <Link
            key={index}
            to={serviceRoutes.policyDetail}
            state={{ detail: item.detail, index: item.id - 1 }}
          >
            <Item>
              <FlexWrap dir='column' alignItems='flex-start'>
                <Title>{item.title}</Title>
                <SubTitle>{item.subTitle}</SubTitle>
                <Date>{item.createDate}</Date>
              </FlexWrap>
              <Image
                src={item.image}
                width={isMobile ? '80px' : '280px'}
                height={isMobile ? '64px' : '217px'}
              />
            </Item>
          </Link>
        ))}
    </List>
  );
}

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 56px;
  margin-top: 68px;
`;

const Item = styled(FlexWrap)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  :hover {
    p {
      position: relative;
      ::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #000000;
      }
    }
  }
  @media ${(props) => props.theme.mobile} {
    align-items: flex-start;
  }
`;

const Title = styled.p`
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
  @media ${(props) => props.theme.mobile} {
    font-size: 18px;
    line-height: 24px;
  }
`;

const SubTitle = styled.span`
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  color: #808080;
  margin-top: 12px;
  @media ${(props) => props.theme.mobile} {
    font-size: 14px;
    line-height: 20px;
  }
`;

const Date = styled.span`
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: #b3b3b3;
  margin-top: 32px;
  @media ${(props) => props.theme.mobile} {
    font-size: 12px;
    line-height: 20px;
    margin-top: 4px;
  }
`;

const Image = styled.img`
  object-fit: fill;
  border-radius: 8px;
`;

export default PolicyList;
