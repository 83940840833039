import React from 'react';
import styled, { css } from 'styled-components';
import { FormProvider, useForm } from 'react-hook-form';

import ScanFrame from 'components/Trial/scan/ScanFrame';
import PurchaseInfoFrame from 'components/Trial/scan/PurchaseInfoFrame';
import RefundResultFrame from 'components/Trial/scan/RefundResultFrame';
import DownloadFrame from './scan/DownloadFrame';
import SelectMode from './scan/SelectMode';
import useTrialState from 'hooks/useTrialState';

interface ISwiperProps {
  transformWidth: number;
  toggleTransition: boolean;
}

function TrialSection() {
  const { transformWidth, toggleTransition } = useTrialState();
  const methods = useForm();

  return (
    <FormProvider {...methods}>
      <StyledDiv>
        <Container>
          <Swiper
            transformWidth={transformWidth}
            toggleTransition={toggleTransition}
          >
            <SelectMode />
            <ScanFrame />
            <PurchaseInfoFrame />
            <RefundResultFrame />
            <DownloadFrame />
          </Swiper>
        </Container>
      </StyledDiv>
    </FormProvider>
  );
}

const StyledDiv = styled.div`
  position: absolute;
  top: 55px;
  left: 24px;
`;
const Container = styled.div`
  width: 350px;
  height: 712px;
  overflow: hidden;
`;
const Swiper = styled.div<ISwiperProps>`
  display: flex;
  height: 100%;
  gap: 80px;
  white-space: nowrap;
  transform: ${(props) => css`translateX(-${props.transformWidth}px)`};
  transition: ${(props) => (props.toggleTransition ? '300ms' : 'none')};
  border-radius: 45px;
`;
export default TrialSection;
