import React from 'react';
import styled from 'styled-components';

import FlexWrap from 'components/_common/FlexWrap';
import LazyImage from 'components/_common/LazyImage';
import Typography from 'components/_common/Typography';

interface IProps {
  text: string;
  icon: string;
}
function BenefitTypeCard({ text, icon }: IProps) {
  return (
    <Container justifyContent='center' alignItems='center'>
      <div>
        <LazyImage src={icon} alt={text} width='2.75rem' height='2.75rem' />
      </div>
      <Typography size='1rem' fontWeight='400'>
        {text}
      </Typography>
    </Container>
  );
}
const Container = styled(FlexWrap)`
  gap: 0.75rem;
  background-color: #ffffff;
  min-width: 13.875rem;
  height: 4.75rem;
  border-radius: 0.5rem;
  align-items: center;
  box-shadow: 4px 0px 10px 0px #e6f0f3;
  @media ${(props) => props.theme.tablet} {
    min-width: 10.625rem;
    height: 3.75rem;
  }
`;

export default BenefitTypeCard;
