import Benefit1 from 'assets/home/KTPBenefitSection/benefit_1.png';
import Benefit2 from 'assets/home/KTPBenefitSection/benefit_2.png';
import Benefit3 from 'assets/home/KTPBenefitSection/benefit_3.png';

import Item1 from 'assets/home/TaxFreeBenefitsSection/item_1.png';
import Item2 from 'assets/home/TaxFreeBenefitsSection/item_2.png';
import Item3 from 'assets/home/TaxFreeBenefitsSection/item_3.png';
import Item4 from 'assets/home/TaxFreeBenefitsSection/item_4.png';
import Item5 from 'assets/home/TaxFreeBenefitsSection/item_5.png';
import Item6 from 'assets/home/TaxFreeBenefitsSection/item_6.png';
import Item7 from 'assets/home/TaxFreeBenefitsSection/item_7.png';
import Item8 from 'assets/home/TaxFreeBenefitsSection/item_8.png';
import Item9 from 'assets/home/TaxFreeBenefitsSection/item_9.png';
import Item10 from 'assets/home/TaxFreeBenefitsSection/item_10.png';
import Item11 from 'assets/home/TaxFreeBenefitsSection/item_11.png';
import Item12 from 'assets/home/TaxFreeBenefitsSection/item_12.png';
import Item13 from 'assets/home/TaxFreeBenefitsSection/item_13.png';
import Item14 from 'assets/home/TaxFreeBenefitsSection/item_14.png';
import Item15 from 'assets/home/TaxFreeBenefitsSection/item_15.png';

import ReviewWebp1 from 'assets/home/ReviewSection/review_1.webp';
import ReviewWebp2 from 'assets/home/ReviewSection/review_2.webp';
import ReviewWebp3 from 'assets/home/ReviewSection/review_3.webp';
import ReviewWebp4 from 'assets/home/ReviewSection/review_4.webp';
import ReviewWebp5 from 'assets/home/ReviewSection/review_5.webp';
import ReviewJpg1 from 'assets/home/ReviewSection/review_1.png';
import ReviewJpg2 from 'assets/home/ReviewSection/review_2.png';
import ReviewJpg3 from 'assets/home/ReviewSection/review_3.png';
import ReviewJpg4 from 'assets/home/ReviewSection/review_4.png';
import ReviewJpg5 from 'assets/home/ReviewSection/review_5.png';

import MobileFlowWebp1 from 'assets/home/WhyKtpSection/mobile/scan.webp';
import MobileFlow1 from 'assets/home/WhyKtpSection/mobile/scan.png';
import MobileFlowWebp2 from 'assets/home/WhyKtpSection/mobile/purchase_info.webp';
import MobileFlow2 from 'assets/home/WhyKtpSection/mobile/purchase_info.png';
import MobileFlowWebp3 from 'assets/home/WhyKtpSection/mobile/refund_result.webp';
import MobileFlow3 from 'assets/home/WhyKtpSection/mobile/refund_result.png';
import MobileFlowWebp4 from 'assets/home/WhyKtpSection/mobile/statistics_amount.webp';
import MobileFlow4 from 'assets/home/WhyKtpSection/mobile/statistics_amount.png';
import MobileFlowWebp5 from 'assets/home/WhyKtpSection/mobile/statistics_count.webp';
import MobileFlow5 from 'assets/home/WhyKtpSection/mobile/statistics_count.png';
import MobileFlowWebp6 from 'assets/home/WhyKtpSection/mobile/vat_report.webp';
import MobileFlow6 from 'assets/home/WhyKtpSection/mobile/vat_report.png';
import MobileFlowWebp7 from 'assets/home/WhyKtpSection/mobile/vat_report_detail.webp';
import MobileFlow7 from 'assets/home/WhyKtpSection/mobile/vat_report_detail.png';

import PCFlowWebp1 from 'assets/home/WhyKtpSection/pc/scan.webp';
import PCFlow1 from 'assets/home/WhyKtpSection/pc/scan.png';
import PCFlowWebp2 from 'assets/home/WhyKtpSection/pc/purchase_info.webp';
import PCFlow2 from 'assets/home/WhyKtpSection/pc/purchase_info.png';
import PCFlowWebp3 from 'assets/home/WhyKtpSection/pc/refund_result.webp';
import PCFlow3 from 'assets/home/WhyKtpSection/pc/refund_result.png';
import PCFlowWebp4 from 'assets/home/WhyKtpSection/pc/statistics_amount.webp';
import PCFlow4 from 'assets/home/WhyKtpSection/pc/statistics_amount.png';
import PCFlowWebp5 from 'assets/home/WhyKtpSection/pc/statistics_count.webp';
import PCFlow5 from 'assets/home/WhyKtpSection/pc/statistics_count.png';
import PCFlowWebp6 from 'assets/home/WhyKtpSection/pc/vat_report.webp';
import PCFlow6 from 'assets/home/WhyKtpSection/pc/vat_report.png';
import PCFlowWebp7 from 'assets/home/WhyKtpSection/pc/vat_report_detail.webp';
import PCFlow7 from 'assets/home/WhyKtpSection/pc/vat_report_detail.png';
import { checkWebpSupport } from 'utils/checkWebpSupport';

export const ktpFlowMobileImg = [
  {
    src: { webp: MobileFlowWebp1, img: MobileFlow1 },
    alt: 'KTP 여권 스캔 화면.',
  },
  {
    src: { webp: MobileFlowWebp2, img: MobileFlow2 },
    alt: 'KTP 상품금액 입력 화면.',
  },
  {
    src: { webp: MobileFlowWebp3, img: MobileFlow3 },
    alt: 'KTP 환급 완료 화면.',
  },
  {
    src: { webp: MobileFlowWebp4, img: MobileFlow4 },
    alt: 'KTP 매출 통계(금액) 화면.',
  },
  {
    src: { webp: MobileFlowWebp5, img: MobileFlow5 },
    alt: 'KTP 매출 통계(건수) 화면.',
  },
  {
    src: { webp: MobileFlowWebp6, img: MobileFlow6 },
    alt: 'KTP 부가세 신고자료 화면.',
  },
  {
    src: { webp: MobileFlowWebp7, img: MobileFlow7 },
    alt: 'KTP 환급실적명세서 화면.',
  },
];
export const ktpFlowPCImg = [
  [
    {
      src: { webp: PCFlowWebp1, img: PCFlow1 },
      alt: 'KTP 여권 스캔 화면.',
    },
    {
      src: { webp: PCFlowWebp2, img: PCFlow2 },
      alt: 'KTP 상품금액 입력 화면.',
    },
    { src: { webp: PCFlowWebp3, img: PCFlow3 }, alt: 'KTP 환급 완료 화면.' },
  ],
  [
    {
      src: { webp: PCFlowWebp4, img: PCFlow4 },
      alt: 'KTP 매출 통계(금액) 화면.',
    },
    {
      src: { webp: PCFlowWebp5, img: PCFlow5 },
      alt: 'KTP 매출 통계(건수) 화면.',
    },
  ],
  [
    {
      src: { webp: PCFlowWebp6, img: PCFlow6 },
      alt: 'KTP 부가세 신고자료 화면.',
    },
    {
      src: { webp: PCFlowWebp7, img: PCFlow7 },
      alt: 'KTP 환급실적명세서 화면.',
    },
  ],
];
export const BenefitsData: Array<{
  titleLeft: string;
  titleBold: string;
  imgSrc: string;
  imgAlt: string;
  contentOne: string;
  contentTwo: string;
  contentThree: string;
}> = [
  {
    imgSrc: Benefit1,
    imgAlt: '택스프리.',
    titleLeft: '도입비·월 이용료 ',
    titleBold: '0원',
    contentOne: '서비스 사용을 위한',
    contentTwo: '초기 구축 및 도입 비용,',
    contentThree: '월 사용료 발생이 전혀 없습니다.',
  },
  {
    imgSrc: Benefit2,
    imgAlt: '고객 지원.',
    titleLeft: 'TAX FREE 매장 ',
    titleBold: '전환 지원',
    contentOne: '매장 운영으로 바쁘신',
    contentTwo: '사업주분들을 위해 TAX FREE 판매에',
    contentThree: '필요한 서류 업무를 지원해드립니다.',
  },
  {
    imgSrc: Benefit3,
    imgAlt: '선물.',
    titleLeft: '홍보물 ',
    titleBold: '무료 제공',
    contentOne: 'TAX FREE 매장 홍보를 위한',
    contentTwo: '30만원 상당의 홍보물을',
    contentThree: '무료로 제공해드립니다.',
  },
];
export const benefitType = [
  {
    text: '호텔/숙박',
    icon: Item1,
  },
  { text: '성형/의료', icon: Item2 },
  { text: '미용', icon: Item3 },
  { text: '약국', icon: Item4 },
  { text: '의류', icon: Item5 },
  { text: '신발', icon: Item6 },
  { text: '건강식품', icon: Item7 },
  { text: '화장품', icon: Item8 },
  { text: '가전제품', icon: Item9 },
  { text: '전자기기', icon: Item10 },
  { text: '쥬얼리', icon: Item11 },
  { text: '안경', icon: Item12 },
  { text: '기념품', icon: Item13 },
  { text: '패션잡화', icon: Item14 },
  { text: '시계', icon: Item15 },
  {
    text: '호텔/숙박',
    icon: Item1,
  },
  { text: '성형/의료', icon: Item2 },
  { text: '미용', icon: Item3 },
  { text: '약국', icon: Item4 },
  { text: '의류', icon: Item5 },
  { text: '신발', icon: Item6 },
  { text: '건강식품', icon: Item7 },
  { text: '화장품', icon: Item8 },
  { text: '가전제품', icon: Item9 },
  { text: '전자기기', icon: Item10 },
  { text: '쥬얼리', icon: Item11 },
  { text: '안경', icon: Item12 },
  { text: '기념품', icon: Item13 },
  { text: '패션잡화', icon: Item14 },
  { text: '시계', icon: Item15 },
];
export const reviewData = [
  {
    filter: 1,
    text: '“생각했던 것보다\n사용방법이 쉽다고 느꼈어요.”',
    author: '남대문 그날의 안경원 대표님',
    imgUrl: checkWebpSupport(ReviewWebp1, ReviewJpg1),
    sectors: '안경',
    alt: '리뷰 가맹점 그날의안경원.',
    active: true,
  },
  {
    filter: 2,
    text: '“사용법이 간단해서\n부담이 없어 좋아요.”',
    author: '홍대 홍익약국 대표님',
    imgUrl: checkWebpSupport(ReviewWebp2, ReviewJpg2),
    sectors: '약국',
    alt: '리뷰 가맹점 홍익약국.',
    active: false,
  },
  {
    filter: 3,
    text: '“외국인 분들이 택스리펀드를 찾아,\n간편해보여서 도입하게 되었어요.”',
    author: '홍대 · 합정 · 상수 SAMPLAS 대표님',
    imgUrl: checkWebpSupport(ReviewWebp3, ReviewJpg3),
    sectors: '의류',
    alt: '리뷰 가맹점 SAMPLAS.',
    active: false,
  },
  {
    filter: 4,
    text: '“설치부터 사용법 교육까지,\n친절하게 설명해 주셨어요.”',
    author: '종로 뉴이 대표님',
    imgUrl: checkWebpSupport(ReviewWebp4, ReviewJpg4),
    sectors: '패션잡화',
    alt: '리뷰 가맹점 뉴이.',
    active: false,
  },
  {
    filter: 5,
    text: '“모바일 기반의 서비스여서\n사용법이 간단해서 좋아요.”',
    author: '인사동 신들네돌 대표님',
    imgUrl: checkWebpSupport(ReviewWebp5, ReviewJpg5),
    sectors: '원석·보석',
    alt: '리뷰 가맹점 신들네돌.',
    active: false,
  },
  {
    filter: 1,
    text: '“생각했던 것보다\n사용방법이 쉽다고 느꼈어요.”',
    author: '남대문 그날의 안경원 대표님',
    imgUrl: checkWebpSupport(ReviewWebp1, ReviewJpg1),
    sectors: '안경',
    alt: '리뷰 가맹점 그날의안경원.',
    active: true,
  },
  {
    filter: 2,
    text: '“사용법이 간단해서\n부담이 없어 좋아요.”',
    author: '홍대 홍익약국 대표님',
    imgUrl: checkWebpSupport(ReviewWebp2, ReviewJpg2),
    sectors: '약국',
    alt: '리뷰 가맹점 홍익약국.',
    active: false,
  },
];
