import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';

import noticeIcon from 'assets/trial/notice.png';
import ContentInner from 'components/Trial/_common/ContentInner';
import Notice, { HightlightType } from 'components/Trial/_common/Notice';
import PhoneContainer from 'components/Trial/_common/PhoneContainer';
import PhoneHeader from 'components/Trial/_common/PhoneHeader';
import Icon from 'components/_common/Icon';
import useTrialActions from 'hooks/useTrialActions';
import { addComma } from 'utils/common';
import {
  comma,
  formatPaymentPrice,
  formatRefundPrice,
  getKoreanPrice,
  removeLetter,
} from 'utils/format';
import PurchaseInfoGuide from '../modal/PurchaseInfoGuide';
import BottomSheet from './BottomSheet';
import useTrialState from 'hooks/useTrialState';

interface IInputProps {
  isBorder: boolean;
}

// 리렌더링 때문에 inputBox 컴포넌트로 분리해야 할지도
function PurchaseInfoFrame() {
  const { onSetRefundInfo } = useTrialActions();
  const [isDisable, setIsDisable] = useState(true);
  const [isDisplayBottomBtn, setIsDisplayBottomBtn] = useState(false);
  const [isBottomSheet, setIsBottomSheet] = useState(false);
  const [korPrice, setKorPrice] = useState(0);

  const bottomSheetRef = useRef<HTMLDivElement>(null);
  const { refundInfo } = useTrialState();
  const { sales } = refundInfo;
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target.value;
    const replaceValue = removeLetter(
      target.length > 1 ? target.replace(/^0/, '') : target
    );

    const formatedRefundPrice = String(formatRefundPrice(replaceValue));
    const formatedPaymentPrice = String(
      formatPaymentPrice(replaceValue, formatedRefundPrice)
    );

    onSetRefundInfo({
      sales: replaceValue,
      refunds: comma(formatedRefundPrice),
      payments: comma(formatedPaymentPrice),
    });

    if (+replaceValue >= 30000) {
      setIsDisable(false);
      clearErrors('payment');
    } else {
      setError('payment', {
        message: '30,000원 이상 금액부터 환급이 가능합니다.',
      });
      setIsDisable(true);
    }
    setKorPrice(+replaceValue);
  };
  const onSlideUp = () => {
    if (!bottomSheetRef.current) return;
    bottomSheetRef.current.style.transform = 'translateY(-100%)';
  };
  const onSubmit = () => {
    setIsDisplayBottomBtn(true);
    setIsBottomSheet(true);
    onSlideUp();
  };

  return (
    <StyledPhoneContainer>
      <PhoneHeader keyword='PAYMENT' />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ContentInner>
          <Notice isHighlight={HightlightType.HALF}>
            <span>상품금액을</span>
            <span style={{ fontWeight: 500 }}>입력해주세요</span>
          </Notice>
          <Wrapper>
            <InputLabel>
              상품금액 <RequiredDot>*</RequiredDot>
            </InputLabel>
            <InputBox isBorder>
              <InputWrapper isBorder>
                <Input
                  {...register('payment')}
                  autoComplete='off'
                  placeholder='상품금액을 입력하세요'
                  isBorder
                  onChange={onChange}
                  value={addComma(sales)}
                  type='text'
                  style={{
                    textAlign: 'right',
                    fontSize: 36,
                    lineHeight: '54px',
                  }}
                />
                <div style={{ marginLeft: 16, color: '#000' }}>원</div>
              </InputWrapper>
            </InputBox>

            {errors.payment ? (
              <ErrorMessage>
                <Icon imgUrl={noticeIcon} width='18px' height='18px' />
                {errors.payment.message}
              </ErrorMessage>
            ) : (
              <KorPrice>{getKoreanPrice(korPrice)}</KorPrice>
            )}
          </Wrapper>
        </ContentInner>
        <Button disabled={isDisable}>확인</Button>
      </Form>
      <PurchaseInfoGuide />
      <BottomSheet
        isDisable={isDisable}
        isDisplayBottomBtn={isDisplayBottomBtn}
        setIsDisplayBottomBtn={setIsDisplayBottomBtn}
        isBottomSheet={isBottomSheet}
        setIsBottomSheet={setIsBottomSheet}
        bottomSheetRef={bottomSheetRef}
        setKorPrice={setKorPrice}
      />
    </StyledPhoneContainer>
  );
}
const StyledPhoneContainer = styled(PhoneContainer)`
  position: relative;
  z-index: 6;
  background-color: white;
`;
const InputBox = styled.div<IInputProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  width: 100%;

  ${(props) =>
    !props.isBorder &&
    css`
      border-bottom: 1px solid #e5e6e8;
    `}
`;
const Form = styled.form``;
const InputLabel = styled.div`
  font-size: 16px;
  line-height: 24px;
`;
const InputWrapper = styled.div<IInputProps>`
  display: flex;
  align-items: ${(props) => (props.isBorder ? 'center' : 'flex-end')};
  width: 100%;
  & div {
    margin: ${(props) => (props.isBorder ? '0 0 0 4px' : '0 0 4px 4px')};
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: ${(props) => props.theme.fontColorContent};
  }
`;
const Input = styled.input<IInputProps>`
  ::placeholder {
    font-size: 16px;
    line-height: 26px;
    padding: 14px 15px;
  }
  ${(props) =>
    props.isBorder
      ? css`
          outline: none;
          border: none;
          border-bottom: 1px solid #cbccce;
          border-radius: 4px;
          width: 100%;
        `
      : css`
          outline: none;
          border: none;
          font-size: 22px;
          line-height: 30px;
          text-align: right;
          width: 100%;
        `}
`;

const Wrapper = styled.div`
  margin: 15px 0 0;
  width: 100%;
`;
const RequiredDot = styled.span`
  color: #ff5239;
`;
const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
  color: #ff5239;
`;
const Button = styled.button`
  position: absolute;
  outline: none;
  cursor: pointer;
  display: block;
  width: calc(100% - 32px);
  font-size: 30px;
  line-height: 54px;
  padding: 10px 0;
  border-radius: 4px;
  font-family: 'Pretendard', sans-serif;
  ${(props) =>
    props.disabled
      ? css`
          background: #edeeef;
          color: ${props.theme.fontColorSubContent};
        `
      : css`
          background: #246cf6;
          color: #fff;
        `}
  bottom: 20px;
  left: 16px;
`;
const KorPrice = styled.p`
  color: #5f6165;
  text-align: right;
`;
export default React.memo(PurchaseInfoFrame);
