import React from "react";
import styled from "styled-components";
import InnerLayout from "components/_common/InnerLayout";
import QuestionItem from "components/Faq/QuestionItem";
import { DummyQnaQuestion } from "model/qnaDataClasses";
import DownloadManual from "./DownloadManual";

// ToDo: Container - Presenter로 변경해서 qna array를 받아오는 구조로 변경
function QuestionSection() {
  const qna = new DummyQnaQuestion().read();

  return (
    <Container>
      <Inner>
        <DownloadManual />
        <Questions>
          {qna.map((item) => (
            <QuestionItem key={item.id} {...item} />
          ))}
        </Questions>
      </Inner>
    </Container>
  );
}
const Container = styled.div`
  @media ${(props) => props.theme.desktop} {
    padding: 100px 0 145px;
  }
  @media ${(props) => props.theme.mobile} {
    padding: 80px 0 145px;
  }
`;

const Inner = styled(InnerLayout)``;

const Questions = styled.ul``;

export default QuestionSection;
