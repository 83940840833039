import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import Typography from 'components/_common/Typography';
import { VariableBrandImages } from 'constants/Home';
import useDeviceCheck from 'hooks/useDeviceCheck';

function VariableBrand() {
  const { isTablet } = useDeviceCheck();
  const imageSlideRef = useRef<HTMLDivElement[]>([]);
  const observer = new IntersectionObserver((targets) => {
    if (!targets[0].isIntersecting) {
      return;
    }
    imageSlideRef.current[0].style.animationPlayState = 'running';
    observer.disconnect();
  });

  useEffect(() => {
    observer.observe(imageSlideRef.current[0]);
  }, []);

  return (
    <Container>
      <TitleContainer>
        <Typography
          size={isTablet ? '1.75rem' : '2rem'}
          fontWeight='700'
          color='#191919'
        >
          다양한 브랜드와 함께 하고 있어요!
        </Typography>
      </TitleContainer>
      <SlideContainer>
        <BrandBox>@ 여러분의 브랜드로 채워주세요</BrandBox>
        <Slide
          ref={(el) => (imageSlideRef.current[0] = el as HTMLDivElement)}
          translateX={isTablet ? '-9.8015rem' : '-25.375rem'}
        >
          {VariableBrandImages.map((img, idx) => (
            <SlideImg key={idx} src={img} alt='함께하는 브랜드.' />
          ))}
        </Slide>
      </SlideContainer>
    </Container>
  );
}

const Container = styled.section`
  padding: 6.75rem 0;
  text-align: center;
  overflow: hidden;
  @media ${(props) => props.theme.tablet} {
    padding: 4.9rem 0;
  }
`;
const TitleContainer = styled.div``;
const SlideContainer = styled.div`
  position: relative;
`;
const Slide = styled.div<{ translateX: string }>`
  display: flex;
  width: calc(25.375rem * 20);
  overflow: hidden;
  gap: 5rem;
  animation: slide 40s linear infinite;
  animation-play-state: paused;
  margin-top: 4.44rem;
  @keyframes slide {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: ${(props) => `translateX(calc(${props.translateX} * 10))`};
    }
  }
  @media ${(props) => props.theme.tablet} {
    margin-top: 3.11rem;
    width: calc(9.8015rem * 20);
    gap: 0.75rem;
  }
`;
const SlideImg = styled.img`
  width: 20.375rem;
  height: 7.625rem;
  object-fit: contain;
  @media ${(props) => props.theme.tablet} {
    width: 9.0515rem;
    height: 4.90819rem;
  }
`;
const BrandBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  padding: 2.5rem;
  border: 6px dashed #246cf6;
  border-radius: 1.25rem;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1;
  font-size: 1rem;
  font-weight: 700;
  color: #246cf6;
  filter: drop-shadow(24px 24px 60px #e6f0f3);
  transform: translate(-50%, -50%);
  @media ${(props) => props.theme.tablet} {
    min-width: 16rem;
    padding: 1.56rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.625rem;
  }
`;

export default VariableBrand;
