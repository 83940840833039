import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';

import InnerLayout from 'components/_common/InnerLayout';
import ServiceItem from './ServiceItem';
import { IFranchiseeList, ImgUrlState, IPolicyList } from 'types/service';
import arrowIcon from 'assets/home/ReviewSection/arrowRight.png';
import FranchiseeCarousel from './FranchiseeCarousel';

interface IProps {
  boldTitle?: string;
  title: string;
  link: string;
  stores: IFranchiseeList[] | IPolicyList[];
  isRending?: boolean;
  moreText?: string;
  moreLink?: string;
  isPolicy?: boolean;
}

function RecentItem({
  boldTitle,
  title,
  link,
  stores,
  moreText,
  moreLink = '',
  isRending = false,
  isPolicy = false,
}: IProps) {
  const [imgUrl, setImgUrl] = useState<ImgUrlState[]>([]);

  const isMore = moreText && !!moreLink;
  useEffect(() => {
    const newImgUrl: ImgUrlState[] = [];
    stores.forEach((item) => {
      const newItem = {
        img: item.image,
        alt: item.alt,
      };
      newImgUrl.push(newItem);
    });
    setImgUrl(newImgUrl);
  }, []);

  return (
    <Container isRending={isRending}>
      <Inner isRending={isRending}>
        <Title isRending={isRending}>
          <Bold>{boldTitle}</Bold>
          {isMobile && <br />}
          {title}
        </Title>
        {isMobile && isRending ? (
          <FranchiseeCarousel
            imgUrl={imgUrl}
            stores={stores}
            isPolicy={isPolicy}
            link={link}
            rending
          />
        ) : (
          <List>
            {stores.map((item) => (
              <ServiceItem
                key={item.id}
                item={item}
                link={link}
                isPolicy={item.isPolicy}
              />
            ))}
          </List>
        )}
        {isMore && (
          <MoveMore to={moreLink}>
            <MoreText>{moreText}</MoreText>
            <Icon
              width={isMobile ? '16px' : '24px'}
              height={isMobile ? '16px' : '24px'}
              src={arrowIcon}
              alt='화살표.'
            />
          </MoveMore>
        )}
      </Inner>
    </Container>
  );
}

const Container = styled.section<{ isRending: boolean }>`
  padding: ${(props) => (props.isRending ? '0' : '56px 0 96px 0')};
  background-color: ${(props) => (props.isRending ? '#FFFFFF' : '#f2f2f2')};
  text-align: center;
`;

const Inner = styled(InnerLayout)<{ isRending: boolean }>`
  @media ${(props) => props.theme.mobile} {
    padding: ${(props) => (props.isRending ? '0 0 0 20px' : ' 0 20px')};
  }
`;

const Bold = styled.span`
  color: #808080;
`;

const Title = styled.p<{ isRending: boolean }>`
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
  text-align: ${(props) => (props.isRending ? 'center' : 'left')};
  @media ${(props) => props.theme.mobile} {
    font-size: 22px;
    line-height: 30px;
  }
`;

const List = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 80px;
  @media ${(props) => props.theme.mobile} {
    gap: 32px 15px;
    margin-top: 26px;
  }
`;

const MoveMore = styled(Link)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  margin-top: 56px;
  padding-right: 20px;
  cursor: pointer;
  @media ${(props) => props.theme.mobile} {
    margin-top: 48px;
  }
`;

const MoreText = styled.p`
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #4d4d4d;
  @media ${(props) => props.theme.mobile} {
    font-size: 20px;
    line-height: 28px;
  }
`;

const Icon = styled.img``;

export default RecentItem;
