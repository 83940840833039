import React from 'react';
import styled, { css } from 'styled-components';

import FlexWrap from 'components/_common/FlexWrap';
import Typography from 'components/_common/Typography';
import useDeviceCheck from 'hooks/useDeviceCheck';

type KTPServiceItemProp = {
  service: serviceType;
};

type serviceType = {
  id: number;
  keywordTitle: string;
  keywords: string[];
  title: string;
  content: string;
  image: string;
};

function KTPServiceItem({ service }: KTPServiceItemProp) {
  const { isTablet } = useDeviceCheck();
  return (
    <Service>
      <TitleContainer>
        <Typography size='1.3125rem' fontWeight='500' color='#1856CD'>
          {service.keywordTitle}
        </Typography>
        <Typography
          size={isTablet ? '1.75rem' : '2rem'}
          fontWeight='700'
          color='#191919'
        >
          {service.title}
        </Typography>
      </TitleContainer>
      {isTablet ? (
        <>
          <ServiceImg src={service.image} alt='제공 서비스.' />
          <ContentContainer>
            <Keywords>
              {service.keywords.map((keyword, idx) => (
                <Keyword key={idx}>{keyword}</Keyword>
              ))}
            </Keywords>
            <Typography size='0.875rem' fontWeight='400' margin='1.25rem 0 0 0'>
              {service.content}
            </Typography>
          </ContentContainer>
        </>
      ) : (
        <>
          <ContentContainer>
            <Typography size='1rem' fontWeight='500' margin='1.31rem 0 0 0'>
              {service.content}
            </Typography>
            <Keywords>
              {service.keywords.map((keyword, idx) => (
                <Keyword key={idx}>{keyword}</Keyword>
              ))}
            </Keywords>
          </ContentContainer>
          <ServiceImg src={service.image} alt='제공 서비스.' />
        </>
      )}
    </Service>
  );
}

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media ${(props) => props.theme.tablet} {
    margin-bottom: 1.69rem;
  }
`;
const ContentContainer = styled.div``;

const Service = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  white-space: pre-wrap;
`;
const Keywords = styled(FlexWrap)`
  margin-top: 2rem;
  justify-content: center;
  gap: 0.75rem;
  margin-bottom: 2rem;
  @media ${(props) => props.theme.tablet} {
    margin-top: 1.19rem;
    margin-bottom: 0rem;
  }
`;
const Keyword = styled.div`
  border: 1.5px solid #3a3b3e;
  border-radius: 6.25rem;
  padding: 0.5rem 1.25rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 150%;
  color: #4d4d4d;
  background-color: #ffffff;
  @media ${(props) => props.theme.tablet} {
    padding: 0.25rem 1rem;
    font-size: 0.075rem;
  }
`;
const ServiceImg = styled.img`
  object-fit: contain;
  width: 41.0625rem;
  height: 21.875rem;
  @media ${(props) => props.theme.tablet} {
    width: 33.5rem;
    height: 27.5rem;
  }
  @media ${(props) => props.theme.mobile} {
    width: 20.125rem;
    height: 11.5rem;
  }
`;

export default KTPServiceItem;
