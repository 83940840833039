import { TaxRefundContent } from 'constants/TaxRefund';
import InfoSection from './InfoSection';
import ProcessSection from './ProcessSection';
import RefundAplication from 'components/_common/RefundAplication';

function RefundMedical() {
  const {
    infoSection: { medical },
    processSection: { medical: processMedical },
  } = TaxRefundContent;
  return (
    <>
      <InfoSection data={medical} />
      <ProcessSection data={processMedical} />
      <RefundAplication />
    </>
  );
}

export default RefundMedical;
