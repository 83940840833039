import React from 'react';
import styled from 'styled-components';

import TrialContextProvider from 'components/context/TrialContextProvider';
import LazyImage from 'components/_common/LazyImage';
import TrialSection from 'components/Trial/TrialSection';
import mobileImgWhite from 'assets/home/KtpExample/mobileWhite.png';
import Typography from 'components/_common/Typography';
import FlexWrap from 'components/_common/FlexWrap';
import StoreButtons from 'components/_common/StoreButtons';
import useDeviceCheck from 'hooks/useDeviceCheck';

function Experiencing() {
  const { isTablet } = useDeviceCheck();

  return (
    <Background>
      <Wrapper>
        <Container>
          <TopContent>
            {isTablet && (
              <>
                <Typography size='1.312rem' fontWeight='500' color='#A7C4FB'>
                  서비스
                </Typography>
                <Typography size='1.75rem' fontWeight='700' color='#FFFFFF'>
                  KTP 체험해보기
                </Typography>
              </>
            )}
          </TopContent>
          <TrialContextProvider>
            <TrialContainer>
              <LazyImage
                src={mobileImgWhite}
                width='400px'
                height='786px'
                alt='TaxFree Example.'
              />
              <TrialSection />
            </TrialContainer>
          </TrialContextProvider>
          <ContentContainer>
            <TopContent>
              {!isTablet && (
                <>
                  <Typography size='1.312rem' fontWeight='500' color='#A7C4FB'>
                    서비스
                  </Typography>
                  <Typography size='1.75rem' fontWeight='700' color='#FFFFFF'>
                    KTP
                    <br />
                    체험해보기
                  </Typography>
                </>
              )}
            </TopContent>
            <BottomContent>
              {isTablet && (
                <Typography
                  size='1.325rem'
                  fontWeight='500'
                  color='#FFFFFF'
                  margin='0 0 1.32rem'
                >
                  쉽고 빠른 환급 서비스를
                  <br />
                  체험해보세요!
                </Typography>
              )}
              <AppGuideContainer>
                <StoreButtons />
              </AppGuideContainer>
              {!isTablet && (
                <Typography size='1rem' fontWeight='500' color='#FFFFFF'>
                  쉽고 빠른 환급 서비스를
                  <br />
                  체험해보세요!
                </Typography>
              )}
            </BottomContent>
          </ContentContainer>
        </Container>
      </Wrapper>
    </Background>
  );
}
const Background = styled.div`
  background-color: #030303;
  width: 100%;
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1190px;
  width: 100%;
  margin: 0 auto;
  padding: 6.75rem 7.75rem 6.25rem;
  @media ${(props) => props.theme.tablet} {
    padding: 4.35rem 1.25rem 6.35rem;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: row;
  @media ${(props) => props.theme.tablet} {
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }
`;
const TrialContainer = styled.div`
  position: relative;
  display: flex;
  transform: scale(0.69);
  margin: -12% 0% -12% -8%;
  @media ${(props) => props.theme.tablet} {
    margin: -12% 0%;
  }
`;
const ContentContainer = styled(FlexWrap)`
  flex-direction: column;
  justify-content: space-between;
  margin-left: 5rem;
  @media ${(props) => props.theme.tablet} {
    align-items: center;
    margin: 0;
    width: 100%;
  }
`;
const TopContent = styled(FlexWrap)`
  flex-direction: column;
  white-space: pre-wrap;
  @media ${(props) => props.theme.tablet} {
    text-align: center;
    white-space: normal;
  }
`;
const BottomContent = styled(FlexWrap)`
  flex-direction: column;
  white-space: pre-wrap;
  @media ${(props) => props.theme.tablet} {
    align-items: center;
    justify-content: flex-start;
    text-align: center;
  }
`;
const AppGuideContainer = styled(FlexWrap)`
  margin-bottom: 1.5rem;
`;
export default Experiencing;
