import React, { MouseEventHandler, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

import DownloadIcon from 'assets/faq/download.png';
import EmailIcon from 'assets/faq/mail.png';
import Manual from 'assets/faq/manual.pdf';
import FlexWrap from 'components/_common/FlexWrap';
import Input from 'components/_common/Input';
import { IEmailFormValues, IError } from 'model/types';
import { countFileDownload, postEmail } from 'api';
import CustomModal from 'components/_common/CustomModal';
import LoadingView from 'components/_common/LoadingView';

function DownloadMenual() {
  const [isShowModal, setIsShowModal] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<{ email: string }>({
    defaultValues: {
      email: '',
    },
  });
  const mutation = useMutation<void, IError, IEmailFormValues>(
    (payload) => postEmail(payload),
    {
      onError: (err) => {
        alert('이메일 전송에 실패했습니다.\n다시 한번 시도해주세요.');
      },
      onSuccess: () => {
        setIsShowModal(true);
        reset();
      },
    }
  );
  const { refetch } = useQuery<number, IError>(
    'downloadCount',
    countFileDownload,
    {
      enabled: false,
      retry: false,
    }
  );
  const onClickEmail: SubmitHandler<IEmailFormValues> = (data, event) => {
    event?.preventDefault();
    mutation.mutate(data);
  };
  const onClickDownload: MouseEventHandler<HTMLAnchorElement> = () => {
    refetch();
  };
  const closeModal = () => {
    setIsShowModal(false);
  };

  return (
    <Container>
      {mutation.isLoading && <LoadingView />}
      <Title>자주 찾는 질문</Title>
      {/* <Message>
        이메일을 입력하거나 {isMobile && <br />}‘파일 다운로드 버튼’을 클릭하여
        {isMobile && <br />} KTP 앱 사용 메뉴얼을 확인해보세요.
      </Message> */}
      {/* <Form>
        <InputBtnContainer>
          <StyledInput
            {...register('email', {
              required: '이메일을 입력해주세요.',
              pattern: {
                value: /[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i,
                message: '이메일 형식이 올바르지 않습니다.',
              },
            })}
            isActiveOutline={false}
            type='text'
            error={errors.email}
            placeholder='이메일을 입력해주세요.'
          />
          <BtnContainer>
            <EmailBtn onClick={handleSubmit(onClickEmail)}>
              이메일로 받기
              <Icon
                src={EmailIcon}
                width='24px'
                height='24px'
                alt='이메일 받기.'
              />
            </EmailBtn>
          </BtnContainer>
        </InputBtnContainer>
      </Form> */}
      <Divider />
      <CustomModal
        isShow={isShowModal}
        title='이메일로 전송되었습니다.'
        content='입력하신 이메일 주소의 메일함을 확인해주세요.'
        btnText='확인'
        btnCallback={closeModal}
      />
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${(props) => props.theme.mobile} {
    padding: 0 16px;
  }
`;
const InputBtnContainer = styled(FlexWrap)`
  align-items: start;
  @media ${(props) => props.theme.mobile} {
    flex-direction: column;
    align-items: stretch;
    gap: 12px;
    width: 100%;
  }
`;
const Form = styled.form`
  @media ${(props) => props.theme.mobile} {
    width: 100%;
  }
`;
const BtnContainer = styled(FlexWrap)`
  margin-left: -5px;
  gap: 12px;
  @media ${(props) => props.theme.mobile} {
    margin: 0;
  }
`;
const StyledInput = styled(Input)`
  width: 600px;
  outline: none;
  @media ${(props) => props.theme.mobile} {
    width: 100%;
  }
`;
const Title = styled.h2`
  font-size: 28px;
  line-height: 40px;
  font-weight: 700;
  margin: 10px 0 20px;
  @media ${(props) => props.theme.mobile} {
    font-size: 24px;
    line-height: 32px;
  }
`;
const Message = styled.p`
  text-align: center;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 40px;
  font-weight: 400;
  @media ${(props) => props.theme.mobile} {
    margin-bottom: 48px;
  }
`;
const EmailBtn = styled.button`
  font-size: 20px;
  line-height: 28px;
  padding: 14px 30px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4d4d4d;
  color: white;
  :hover,
  :active {
    background-color: rgba(77, 77, 77, 0.8);
  }
  @media ${(props) => props.theme.mobile} {
    font-size: 18px;
    line-height: 24px;
    flex: 1;
    padding: 14px;
  }
`;
const DownloadBtn = styled.a`
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.primaryColor};
  color: white;
  padding: 14px 30px;
  font-size: 20px;
  line-height: 28px;
  cursor: pointer;
  transition: all 200ms ease-in;
  :hover,
  :active {
    background-color: ${(props) => props.theme.primaryTransparent};
  }
  @media ${(props) => props.theme.mobile} {
    font-size: 18px;
    line-height: 24px;
    flex: 1;
    padding: 14px;
  }
`;
const Icon = styled.img`
  margin-left: 8px;
  @media ${(props) => props.theme.mobile} {
    margin-left: 4px;
  }
`;
const Divider = styled.div`
  margin: 50px 0 0;
  width: 100%;
  @media ${(props) => props.theme.mobile} {
  }
`;
export default DownloadMenual;
