import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import { AxiosError } from 'axios';

import { applyFranchisee } from 'api';
import activeCheckImg from 'assets/common/active_check.png';
import arrowDownIcon from 'assets/common/arrow_down.png';
import emptyCheckImg from 'assets/common/empty_check.png';
import FlexWrap from 'components/_common/FlexWrap';
import Icon from 'components/_common/Icon';
import LoadingView from 'components/_common/LoadingView';
import { IApplicationFormValues } from 'model/types';
import RefundModal from 'components/Refund/RefundModal';

type InquiryFloatingBarProps = {
  isToggle: boolean;
  setIsToggle: Dispatch<SetStateAction<boolean>>;
  isInquiryFloatingBar: boolean;
};

function InquiryFloatingBar({
  isToggle,
  setIsToggle,
  isInquiryFloatingBar,
}: InquiryFloatingBarProps) {
  const [isDisplay, setIsDisplay] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [maxHeight, setMaxHeight] = useState(window.innerHeight);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const homeBannerHeight = useRef<number | undefined>(0);
  const { franchiseeName, name, email, phoneNumber, inquiry } = watch();

  const { isLoading, mutate: inquriyMutate } = useMutation<
    null,
    AxiosError,
    IApplicationFormValues
  >((payload) => applyFranchisee(payload), {
    onError: () => {
      alert('가맹점 신청에 실패했습니다.\n디시 한번 시도해주세요.');
    },
    onSuccess: () => {
      reset();
      setIsCheck(false);
      setIsToggle(false);
      alert('도입문의 신청이 완료되었습니다.');
    },
  });

  useEffect(() => {
    if (franchiseeName && name && email && phoneNumber && inquiry && isCheck) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [watch()]);

  const displayInquiry = () => {
    if (!homeBannerHeight.current) return;
    const currsetScrollY = window.scrollY;
    if (homeBannerHeight.current < currsetScrollY + 150) {
      setIsDisplay(true);
    } else {
      setIsDisplay(false);
    }
  };

  const changeHeight = () => {
    setMaxHeight(window.innerHeight * 0.7);
  };

  useEffect(() => {
    homeBannerHeight.current =
      document.getElementById('home-banner')?.offsetHeight;
    window.addEventListener('scroll', displayInquiry);
    window.addEventListener('resize', changeHeight);
    return () => {
      window.removeEventListener('scroll', displayInquiry);
      window.addEventListener('resize', changeHeight);
    };
  }, []);

  const onClickToggle = () => {
    setIsToggle(!isToggle);
  };
  const onSubmit = () => {
    if (isActive) {
      const payload = { name, phoneNumber, email, franchiseeName, inquiry };
      inquriyMutate(payload);
    } else {
      alert('개인정보 수집에 동의 및 필수 정보를 입력 해주세요.');
    }
  };
  return (
    <Container
      maxHeight={maxHeight + 'px'}
      isDisplay={isDisplay || isInquiryFloatingBar}
      isToggle={isToggle}
    >
      {isLoading && <LoadingView />}
      <Button onClick={onClickToggle}>
        <Title isToggle={isToggle}>도입문의</Title>
        <IconContainer isToggle={isToggle}>
          <Icon imgUrl={arrowDownIcon} width='24px' height='24px' />
        </IconContainer>
      </Button>

      <ToggleContainer isToggle={isToggle}>
        <DivideLine />
        <Form>
          <InputWrap>
            <LabelWrapper>
              <Label>매장(상호)명</Label>
              <RequiredDot>*</RequiredDot>
            </LabelWrapper>
            <Input
              {...register('franchiseeName', {
                required: '매장명 또는 상호명은 필수 항목입니다.',
              })}
              name='franchiseeName'
              placeholder='매장명 또는 상호명을 입력해주세요.'
              type='text'
            />
          </InputWrap>
          <InputWrap>
            <LabelWrapper>
              <Label>성함</Label>
              <RequiredDot>*</RequiredDot>
            </LabelWrapper>
            <Input
              {...register('name', {
                required: '성함은 필수 항목입니다.',
              })}
              name='name'
              placeholder='성함을 입력해주세요.'
              type='text'
            />
          </InputWrap>
          <InputWrap>
            <LabelWrapper>
              <Label>연락처</Label>
              <RequiredDot>*</RequiredDot>
            </LabelWrapper>
            <Input
              {...register('phoneNumber', {
                required: '연락처는 필수 항목입니다.',
                pattern: {
                  value: /^\d+$/,
                  message: '연락처는 숫자만 기입해주세요.',
                },
                minLength: {
                  value: 7,
                  message: '연락처 형식에 맞지 않습니다. 다시 입력해주세요.',
                },
                maxLength: {
                  value: 11,
                  message: '연락처 형식에 맞지 않습니다. 다시 입력해주세요.',
                },
              })}
              name='phoneNumber'
              placeholder='연락 가능한 번호를 입력해주세요.'
              type='number'
            />
            {errors.phoneNumber && (
              <ErrorWrapper>
                <Error>{errors.phoneNumber.message}</Error>
              </ErrorWrapper>
            )}
          </InputWrap>
          <InputWrap>
            <LabelWrapper>
              <Label>이메일</Label>
              <RequiredDot>*</RequiredDot>
            </LabelWrapper>
            <Input
              {...register('email', {
                required: '이메일은 필수 항목입니다.',
                pattern: {
                  value: /[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i,
                  message: '이메일 형식이 올바르지 않습니다.',
                },
              })}
              name='email'
              placeholder='이메일을 입력해주세요.'
              type='text'
            />
            {errors.email && (
              <ErrorWrapper>
                <Error>{errors.email.message}</Error>
              </ErrorWrapper>
            )}
          </InputWrap>
          <InputWrap>
            <LabelWrapper>
              <Label>문의내용</Label>
              <RequiredDot>*</RequiredDot>
            </LabelWrapper>
            <Textarea
              {...register('inquiry', {
                required: '문의 내용을 입력해주세요.',
              })}
              name='inquiry'
              placeholder='도입 문의, 서비스 이용 절차 등 궁금하신
              사항을 남겨주세요. 문의 내용을 확인하여
              빠르게 연락드리도록 하겠습니다.'
            />
          </InputWrap>
        </Form>
        <CheckBox>
          <CheckImg
            width='24px'
            height='24px'
            src={isCheck ? activeCheckImg : emptyCheckImg}
            onClick={() => setIsCheck((prev) => !prev)}
            alt='체크박스.'
          />
          <CheckContent>
            <Line onClick={() => setIsModal(true)}>
              개인정보 수집 및 이용안내
            </Line>
            에 동의합니다.
          </CheckContent>
          {isModal && <RefundModal setIsModal={setIsModal} />}
        </CheckBox>
        <InquiryButton onClick={handleSubmit(onSubmit)} isActive={isActive}>
          문의하기
        </InquiryButton>
      </ToggleContainer>
    </Container>
  );
}
const Title = styled.span<{ isToggle: boolean }>`
  font-size: 18px;
  font-weight: 500px;
  color: #1856cd;
  padding-left: ${(props) => (props.isToggle ? 0 : '24px')};
`;
const Button = styled.div`
  display: flex;
  align-items: center;
  min-width: 167px;
  gap: 4px;
  padding: 8px 0;
`;
const DivideLine = styled.div`
  width: 100%;
  height: 1px;
  margin: 0px 0 20px 0;
  background-color: #1856cd;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`;
const InputWrap = styled(FlexWrap)`
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
`;
const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 8px;
`;
const Label = styled.label`
  font-size: 0.875rem;
  line-height: 150%;
  font-weight: 500;
  color: #808080;
`;
const Input = styled.input`
  width: 100%;
  text-align: left;
  padding: 12px 20px;
  border-radius: 4px;
  border: 1px solid var(--grayscale-5808080, #808080);
  font-size: 0.875rem;
  line-height: 150%;
  font-weight: 400;
`;
const Line = styled.span`
  cursor: pointer;
  border-bottom: 1px solid #333;
`;
const RequiredDot = styled.div`
  color: #f13e4b;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 150%;
  margin-left: 4px;
  padding-bottom: 6px;
`;
const ToggleContainer = styled.div<{ isToggle: boolean }>`
  width: 100%;
  pointer-events: ${(props) => (props.isToggle ? 'auto' : 'none')};
  max-height: ${(props) => (props.isToggle ? '43rem' : '0px')};
`;
const Textarea = styled.textarea`
  &::placeholder {
    font-size: 14px;
    line-height: 20px;
    font-family: 'Pretendard';
    white-space: pre-line;
  }
  font-family: 'Pretendard';
  padding: 16px 20px;
  display: block;
  width: 100%;
  border-radius: 4px;
  font-size: 0.875rem;
  outline-color: ${(props) => props.theme.primaryColor};
  resize: none;
  min-height: 96px;
`;
const CheckBox = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 22px;
`;
const CheckImg = styled.img`
  cursor: pointer;
`;
const CheckContent = styled.div`
  font-size: 0.875rem;
  line-height: 150%;
  color: #333333;
`;
const InquiryButton = styled.button<{ isActive: boolean }>`
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 150%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 280px;
  height: 48px;
  border-radius: 4px;
  margin-top: 20px;
  background-color: ${(props) => (props.isActive ? '#1856CD' : '#808080')};
  color: #ffffff;
`;
const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 10px;
`;
const Error = styled.p`
  color: #f13e4b;
  font-size: 0.675rem;
  line-height: 150%;
  font-weight: 400;
`;
const IconContainer = styled.div<{ isToggle: boolean }>`
  display: flex;
  align-items: center;
  transform: ${(props) => props.isToggle && 'rotate(-180deg)'};
`;
const Container = styled(FlexWrap)<{
  isDisplay: boolean;
  isToggle: boolean;
  maxHeight?: string;
}>`
  flex-direction: column;
  position: fixed;
  top: 80px;
  right: 40px;
  z-index: 100;
  background-color: #ffffff;
  border-radius: 12px;
  opacity: ${(props) => (props.isDisplay ? 1 : 0)};
  border: 1.5px solid #1856cd;
  padding: 0px 24px;
  transition: all 300ms ease-in;
  box-shadow: 0px 4px 20px 0px rgba(25, 25, 25, 0.1);
  overflow: ${(props) => (props.isToggle ? 'auto' : 'hidden')};
  cursor: ${(props) => (props.isToggle ? 'auto' : 'pointer')};
  width: ${(props) => (props.isToggle ? '20.5rem' : '180px')};
  max-height: ${(props) => (props.isToggle ? props.maxHeight : '52px')};
  padding-bottom: ${(props) => (props.isToggle ? '32px' : '0px')};
  @media ${(props) => props.theme.tablet} {
    width: ${(props) => (props.isToggle ? '16.5rem' : '180px')};
  }
`;
export default InquiryFloatingBar;
