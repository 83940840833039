import React from 'react';
import styled from 'styled-components';

import PhoneContainer from 'components/Trial/_common/PhoneContainer';
import useTrialActions from 'hooks/useTrialActions';
import LoadingView from '../_common/LoadingView';
import PhoneHeader from '../_common/PhoneHeader';
import PassportMockup from 'assets/trial/passport_mockup.png';
import ViewTouch from 'assets/trial/view_touch.png';

function ScanFrame() {
  const { isLoading, passportRef, passportSlide } = useTrialActions();

  const onClickScanBox = () => {
    if (isLoading) {
      return;
    }
    passportSlide();
  };
  return (
    <PhoneContainer>
      <PhoneHeader keyword='SCAN' />
      <>
        <Container onClick={onClickScanBox}>
          <ScanBox
            style={{
              backgroundColor: 'rgba(255,255,255, 0.2)',
            }}
          >
            <PassportWrapper ref={passportRef}>
              <Passport
                width='100%'
                height='100%'
                src={PassportMockup}
                alt='여권.'
              />
              {isLoading && <LoadingView isDark isOpacity />}
            </PassportWrapper>
          </ScanBox>
          <NotificationBox>
            <Arrow width='61px' height='57px' src={ViewTouch} alt='알림.' />
            <GuideText>화면을 터치해주세요</GuideText>
          </NotificationBox>
        </Container>
      </>
    </PhoneContainer>
  );
}

const Container = styled.div`
  display: flex;
  width: 350px;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 160px 20px 0;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 48px 48px 43px 43px;
`;

const ScanBox = styled.div`
  width: 100%;
  height: 210px;
  background: rgba(0, 0, 0, 0.1);
  border: 3px solid #ffffff;
  border-radius: 10px;
  margin: -100px 0 16px;
`;

const PassportWrapper = styled.div`
  width: 335px;
  height: 210px;
  position: absolute;
  left: 0;
  top: auto !important;
  transition-duration: 300ms;
  transform: translate(calc(-100% + 16px), 0);
`;

const Passport = styled.img`
  object-fit: cover;
`;

const NotificationBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  & p {
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    max-width: 125px;
  }
`;

const Arrow = styled.img`
  margin: 0 12px 0 0;
  object-fit: contain;
`;

const GuideText = styled.p`
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
`;
export default React.memo(ScanFrame);
