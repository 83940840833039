import Icon from 'components/_common/Icon';
import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import closeIcon from 'assets/common/close.png';
import useTrialState from 'hooks/useTrialState';
import Typography from 'components/_common/Typography';
import FlexWrap from 'components/_common/FlexWrap';
import { comma } from 'utils/format';
import useTrialActions from 'hooks/useTrialActions';
import { useFormContext } from 'react-hook-form';

function BottomSheet({
  isDisable,
  setIsDisplayBottomBtn,
  bottomSheetRef,
  isDisplayBottomBtn,
  isBottomSheet,
  setIsBottomSheet,
  setKorPrice,
}: any) {
  const { refundInfo } = useTrialState();
  const { onMove } = useTrialActions();
  const { sales, refunds, payments } = refundInfo;
  const methods = useFormContext();

  let firstScrollRef = useRef(0);
  let lastScrollRef = useRef(0);

  const closeSlide = () => {
    setIsBottomSheet(false);
    if (!bottomSheetRef.current) return;
    bottomSheetRef.current.style.transform = 'translateY(-40%)';
  };

  const onMouseDown = (e: any) => {
    firstScrollRef = e.clientY;
  };
  const onMouseUp = (e: any) => {
    lastScrollRef = e.clientY;
    if (!bottomSheetRef.current) return;
    if (firstScrollRef > lastScrollRef) {
      bottomSheetRef.current.style.transform = 'translateY(-100%)';
      setIsBottomSheet(true);
    } else {
      setIsBottomSheet(false);
      bottomSheetRef.current.style.transform = 'translateY(-40%)';
    }
  };

  const onClickMove = () => {
    onMove();
    setKorPrice(0);
    setIsBottomSheet(false);
    setIsDisplayBottomBtn(false);
    methods.setValue('payment', '');
    if (!bottomSheetRef.current) return;
    bottomSheetRef.current.style.transform = 'translateY(0%)';
  };

  return (
    <>
      <Container
        ref={bottomSheetRef}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
      >
        <TabBar />
        {isBottomSheet && (
          <CancleBtn onClick={closeSlide}>
            <Icon imgUrl={closeIcon} width='24px' height='24px' />
          </CancleBtn>
        )}
        <Content isBottomSheet={isBottomSheet}>
          <Typography
            size='24px'
            fontWeight='500'
            lineHeight='32px'
            margin='0 0 16px 0'
          >
            Tax FREE 결제
          </Typography>
          <PaymentContainer>
            <Payment>
              <Typography size='14px' fontWeight='400' lineHeight='24px'>
                Sales Amount / 상품금액
              </Typography>
              <Typography size='20px' fontWeight='500' lineHeight='28px'>
                {comma(sales)}{' '}
                <Typography size='20px' fontWeight='400' lineHeight='28px'>
                  원
                </Typography>
              </Typography>
            </Payment>
            <Payment margin='16px 0 0 0'>
              <Typography size='14px' fontWeight='400' lineHeight='24px'>
                Refund Amount /환급액
              </Typography>
              <Typography size='20px' fontWeight='500' lineHeight='28px'>
                {refunds}
                <Typography size='20px' fontWeight='400' lineHeight='28px'>
                  원
                </Typography>
              </Typography>
            </Payment>
            <DivdeLine />
            <Payment>
              <Typography size='18px' fontWeight='500' lineHeight='26px'>
                Payment Amount
                <br /> / 결제금액
              </Typography>
              <Typography
                size='28px'
                fontWeight='500'
                lineHeight='32px'
                color='#246cf6'
              >
                {payments}
                <Typography size='20px' fontWeight='400' lineHeight='28px'>
                  원
                </Typography>
              </Typography>
            </Payment>{' '}
          </PaymentContainer>
        </Content>
      </Container>

      {isDisplayBottomBtn && (
        <Button disabled={isDisable} onClick={onClickMove}>
          {payments}원 결제하기
        </Button>
      )}
      {isBottomSheet && (
        <>
          <BackDrop />
        </>
      )}
    </>
  );
}

const Container = styled.div`
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 60%;
  bottom: -65%;
  background-color: #fff;
  border-radius: 40px;
  transition: all 300ms ease-in;
  cursor: pointer;
  box-shadow: 0px 0px 25px 0px #d8d8d8;
  user-select: none;
`;
const CancleBtn = styled.button`
  position: absolute;
  top: 36px;
  right: 20px;
`;
const TabBar = styled.div`
  position: absolute;
  width: 44px;
  height: 4px;
  background-color: #dedede;
  border-radius: 50px;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
`;
const BackDrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
`;
const Button = styled.button`
  position: absolute;
  outline: none;
  cursor: pointer;
  display: block;
  width: calc(100% - 32px);
  font-size: 30px;
  line-height: 54px;
  padding: 10px 0;
  border-radius: 4px;
  font-family: 'Pretendard', sans-serif;
  background: #3a3b3e;
  color: #fff;
  z-index: 10;
  ${(props) =>
    props.disabled
      ? css`
          background: #edeeef;
          color: ${props.theme.fontColorSubContent};
        `
      : css`
          background: #246cf6;
          color: #fff;
        `}
  @media ${(props) => props.theme.desktop} {
    bottom: 20px;
    left: 16px;
  }
  @media ${(props) => props.theme.tablet} {
    bottom: 20px;
    left: 16px;
  }
`;
const Content = styled.div<{ isBottomSheet: boolean }>`
  display: ${(props) => (props.isBottomSheet ? 'block' : 'none')};
  padding: 56px 20px 0 20px;
`;
const PaymentContainer = styled.div``;
const Payment = styled(FlexWrap)`
  align-items: center;
  justify-content: space-between;
`;
const DivdeLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e5e6e8;
  margin: 16px 0 12px 0;
`;
export default BottomSheet;
