import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import InnerLayout from 'components/_common/InnerLayout';
import ReviewItem from 'components/Home/ReviewItem';
import ContainerLayout from 'components/_common/ContainerLayout';
import { reviewData } from 'model/homeDataClasses';
import FlexWrap from 'components/_common/FlexWrap';
import useDeviceCheck from 'hooks/useDeviceCheck';

function ReviewSection() {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const slideContainerRef = useRef<HTMLDivElement[]>([]);
  const slideRef = useRef<any>(null);
  let intervalTimeOut = useRef<any>(0);
  let timeOutRef = useRef<any>(null);
  const { isTablet } = useDeviceCheck();

  const observer = new IntersectionObserver((targets) => {
    if (!targets[0].isIntersecting) {
      return;
    }
    observer.disconnect();
  });

  useEffect(() => {
    observer.observe(slideContainerRef.current[0]);
  }, []);
  useEffect(() => {
    changeOpacity();
    intervalTime();
    // initPosition();
  }, [currentIndex]);

  // const initPosition = () => {
  //   if (!slideRef.current) return;
  //   setCurrentIndex(1);
  //   slideRef.current!.style.transform = `translateX(calc(${-21.625}rem))`;
  // };

  const changeOpacity = () => {
    const currentImage = slideRef?.current?.querySelectorAll('.cardItem');
    currentImage.forEach((item: any, index: number) => {
      if (!item.style) return;
      if (currentIndex === index) {
        item.style.opacity = 1;
      } else {
        item.style.opacity = 0.5;
      }
    });
  };

  const intervalTime = async () => {
    clearInterval(intervalTimeOut.current);
    intervalTimeOut.current = await setInterval(() => {
      moveImage(currentIndex + 1);
    }, 3000);
    const transfromMove = isTablet ? -23.125 : -44.86925;
    if (!slideRef.current) return;
    slideRef.current!.style.transform = `translateX(calc(${
      currentIndex * transfromMove
    }rem))`;
  };

  const moveImage = (nextIndex: number) => {
    if (!slideRef.current) {
      return;
    }
    const time = nextIndex < reviewData.length - 2 ? 3000 : 300;
    slideRef.current.style.transition = '300ms';
    setCurrentIndex(nextIndex);
    clearTimeout(timeOutRef.current);

    timeOutRef.current = setTimeout(() => {
      if (!slideRef.current) {
        return;
      }
      nextIndex = nextIndex < reviewData.length - 3 ? nextIndex : 0;
      slideRef.current.style.transition = '0s';
      setCurrentIndex(nextIndex);
    }, time);
  };

  return (
    <Wrapper>
      <Inner>
        <FlexWrap dir='column' alignItems='center' gap='8px'>
          <SubTitle>가맹점의 솔직한 후기를 들어보세요</SubTitle>
        </FlexWrap>
        <CarouselContainer>
          {!isTablet && (
            <TextContainer dir='column'>
              <FlexWrap dir='column'>
                <SectorsText>{reviewData[currentIndex].sectors}</SectorsText>
                <ReviewText>{reviewData[currentIndex].text}</ReviewText>
              </FlexWrap>
              <FlexWrap dir='column'>
                <DivideLine />
                <AuthorText>{reviewData[currentIndex].author}</AuthorText>
              </FlexWrap>
            </TextContainer>
          )}
          <CarouselContent
            ref={(el) => (slideContainerRef.current[0] = el as HTMLDivElement)}
          >
            <ReviewWrapper ref={slideRef}>
              {reviewData.map((item, idx) => (
                <ReviewItem
                  key={idx}
                  text={item.text}
                  author={item.author}
                  imgUrl={item.imgUrl}
                  width={isTablet ? '21.625rem' : '41.86925rem'}
                  height={isTablet ? '13.125rem' : '22.5rem'}
                  alt={item.alt}
                />
              ))}
            </ReviewWrapper>
          </CarouselContent>
          {isTablet && (
            <TextContainer dir='column'>
              <SectorsText>{reviewData[currentIndex].sectors}</SectorsText>
              <ReviewText>{reviewData[currentIndex].text}</ReviewText>
              <DivideLine />
              <AuthorText>{reviewData[currentIndex].author}</AuthorText>
            </TextContainer>
          )}
        </CarouselContainer>
      </Inner>
    </Wrapper>
  );
}

const Wrapper = styled(ContainerLayout)`
  padding: 6.75rem 0 8.94rem 0;
  overflow-x: hidden;
  @media ${(props) => props.theme.tablet} {
    padding: 4.9rem 0;
  }
`;
const Inner = styled(InnerLayout)`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${(props) => props.theme.tablet} {
  }
`;
const SubTitle = styled.p`
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 4.44rem;
  @media ${(props) => props.theme.tablet} {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
`;

const CarouselContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  @media ${(props) => props.theme.tablet} {
    flex-direction: column;
  }
`;
const CarouselContent = styled.div`
  display: flex;
  width: 1190px;
  height: 370px;
  overflow-x: hidden;
  position: absolute;
  right: -60%;
  top: 0;
  @media ${(props) => props.theme.tablet} {
    position: static;
    /* width: calc(23.125rem * 9); */
    height: 13.125rem;
  }
`;
const ReviewWrapper = styled.div<{ ref: any }>`
  display: flex;
  gap: 3rem;
  justify-content: flex-start;
  @media ${(props) => props.theme.tablet} {
    width: 100vw;
    gap: 1.5rem;
  }
`;
const SectorsText = styled.p`
  width: fit-content;
  font-weight: 500;
  padding: 0.25rem 1.75rem;
  line-height: 150%;
  font-size: 1.125rem;
  border-radius: 6.25rem;
  background-color: #0b42ad;
  color: #ffffff;
  margin-bottom: 2.25rem;
  @media ${(props) => props.theme.tablet} {
    font-size: 0.875rem;
    margin-bottom: 0;
  }
`;
const ReviewText = styled.p`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 150%;
  white-space: pre-line;
  color: #191919;
  @media ${(props) => props.theme.tablet} {
    font-size: 1.3125rem;
  }
`;
const AuthorText = styled.p`
  font-size: 1.125rem;
  line-height: 150%;
  font-weight: 500;
  color: #4d4d4d;
  @media ${(props) => props.theme.tablet} {
    font-size: 0.875rem;
  }
`;
const TextContainer = styled(FlexWrap)`
  align-items: flex-start;
  justify-content: space-between;
  z-index: 0;
  background-color: #ffffff;
  height: 22.5rem;
  @media ${(props) => props.theme.tablet} {
    position: relative;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5rem;
    height: auto;
    text-align: center;
    margin-top: 2.5rem;
  }
`;
const DivideLine = styled.p`
  width: 2.5rem;
  height: 0.09375rem;
  margin-bottom: 1.15rem;
  background-color: #030303;
`;

export default ReviewSection;
