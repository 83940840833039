import React from 'react';
import { IPolicyList } from 'types/service';

interface IProps {
  item: IPolicyList;
}

function PolicyItem({ item }: IProps) {
  return (
    <>
      <span>{item.subTitle.substring(0, 9) + '...'}</span>
      <span>{item.createDate}</span>
    </>
  );
}

export default PolicyItem;
