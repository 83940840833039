import ProcessItem from 'components/GlobalPay/ProcessItem';
import FlexWrap from 'components/_common/FlexWrap';
import Typography from 'components/_common/Typography';
import { globalPayContentList } from 'constants/TaxRefund';
import useDeviceCheck from 'hooks/useDeviceCheck';
import { useState } from 'react';
import styled from 'styled-components';
import { applayDownload, maillCopy } from 'utils/common';
import GlobalModal from './GlobalModal';

function Procedure() {
  const { isTablet } = useDeviceCheck();
  const [isDownloadModal, setIsDownloadModal] = useState(false);
  const [isMailModal, setIsMailModal] = useState(false);
  const [isMailCopy, setIsMailCopy] = useState(false);

  const onClickCopy = () => {
    maillCopy(setIsMailCopy);
    if (isTablet) return;
    setIsMailModal(true);
  };
  const onClickDownload = () => {
    applayDownload();
    if (isTablet) {
      alert('신청에 필요한 서류를 다운로드합니다.');
    } else {
      setIsDownloadModal(true);
    }
  };
  return (
    <Wrapper>
      <Container>
        <TitleContainer>
          <Typography
            size={isTablet ? '1.375rem' : '2rem'}
            fontWeight='700'
            color='#191919'
          >
            서류 내고, 앱 다운받으면 신청 끝!
          </Typography>
        </TitleContainer>
        <List>
          {globalPayContentList.map((item, idx) => (
            <ProcessItem
              key={idx}
              //@ts-ignore
              item={item}
              idx={idx}
              actionCallback={
                item.action === 'DOWNLOAD'
                  ? onClickDownload
                  : item.action === 'COPY_LINK'
                  ? onClickCopy
                  : undefined
              }
            />
          ))}
        </List>
        {isMailModal && (
          <GlobalModal
            setState={setIsMailModal}
            isMail
            isMailCopy={isMailCopy}
          />
        )}
        {isDownloadModal && <GlobalModal setState={setIsDownloadModal} />}
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f4f8ff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
`;
const Container = styled.div`
  max-width: 1190px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6.75rem 7.75rem 6.25rem;
  @media ${(props) => props.theme.tablet} {
    padding: 3.35rem 1.25rem 3.35rem;
  }
`;
const List = styled(FlexWrap)`
  flex-direction: column;
  width: 100%;
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  justify-content: center;
  width: 100%;
  text-align: center;
`;

export default Procedure;
