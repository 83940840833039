import React from 'react';
import { isMobile } from 'react-device-detect';
import BrowserTitle from 'components/_common/BrowserTitle';
import TopBanner from 'components/_common/TopBanner';
import QuestionSection from 'components/Faq/QuestionSection';
import MainContainerLayout from 'components/_common/MainContainerLayout';
import MFaqBannerWebp from 'assets/bg/mobile/m_homeBanner.webp';
import FaqBanner from 'assets/bg/pc/homeBanner.png';
import MFaqBanner from 'assets/bg/mobile/m_homeBanner.png';
import { checkWebpSupport } from 'utils/checkWebpSupport';

function Faq() {
  return (
    <MainContainerLayout>
      <BrowserTitle
        title='자주 찾는 질문 FAQ | 텍스프리 KTP'
        description='사후면세란? TAX FREE(택스프리) 매장 전환 방법과 TAX REFUND(즉시 환급)에 대한 궁금증을 해결해보세요.'
        url='https://www.ktaxpay.com/faq'
      />
      <TopBanner
        title='텍스프리 FAQ'
        content='KTP에 대해 궁금하신 사항을 쉽고 빠르게 확인하실 수 있어요.'
        imgUrl={
          isMobile ? checkWebpSupport(MFaqBannerWebp, MFaqBanner) : FaqBanner
        }
      />
      <QuestionSection />
    </MainContainerLayout>
  );
}

export default Faq;
