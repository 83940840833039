import styled from 'styled-components';

type IconProps = {
  width: string;
  height: string;
  imgUrl: string;
  margin?: string;
  alt?: string;
};

function Icon({ width, height, imgUrl, margin, alt }: IconProps) {
  return (
    <Image
      src={imgUrl}
      width={width}
      height={height}
      margin={margin}
      alt={alt}
    />
  );
}

const Image = styled.img<{ margin?: string; width: string; height: string }>`
  object-fit: contain;
  margin: ${(props) => props.margin || '0px'};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;

export default Icon;
