import styled, { css } from "styled-components";
import FlexWrap from "./FlexWrap";

interface IProps {
  isShow: boolean;
  title: string;
  content: string;
  btnText: string;
  btnCallback?: () => void;
}
function CustomModal({ isShow, title, content, btnText, btnCallback }: IProps) {
  return (
    <Container isShow={isShow}>
      <Modal>
        <ModalTitle>{title}</ModalTitle>
        <Description>{content}</Description>
        <Button onClick={btnCallback}>{btnText}</Button>
      </Modal>
    </Container>
  );
}
const Container = styled.div<{ isShow: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) =>
    !props.isShow &&
    css`
      display: none;
    `};
  @media ${(props) => props.theme.mobile} {
    padding: 0 20px;
  }
`;
const Modal = styled(FlexWrap)`
  padding: 80px 0;
  flex-direction: column;
  align-items: center;
  width: 700px;
  background-color: white;
  border-radius: 4px;
  @media ${(props) => props.theme.mobile} {
    padding: 48px 20px 28px;
  }
`;
const ModalTitle = styled.h3`
  font-size: 28px;
  line-height: 40px;
  font-weight: 700;
  @media ${(props) => props.theme.mobile} {
    font-size: 22px;
    line-height: 30px;
  }
`;
const Description = styled.p`
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  margin: 20px 0 60px;
  text-align: center;
  word-break: keep-all;
  @media ${(props) => props.theme.mobile} {
    font-size: 18px;
    line-height: 24px;
    margin: 20px 0 28px;
  }
`;
const Button = styled.button`
  background-color: rgba(77, 77, 77, 1);
  color: white;
  border-radius: 4px;
  font-size: 20px;
  line-height: 28px;
  padding: 12px 80px;
  :hover,
  :active {
    background-color: rgba(77, 77, 77, 0.8);
  }
  @media ${(props) => props.theme.mobile} {
    padding: 12px 65px;
  }
`;
export default CustomModal;
