import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { isMobile } from 'react-device-detect';

function GoogleMaps({ position }: any) {
  const { lat, lng } = position;

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOLE_MAP!,
  });

  const containerStyle = {
    width: '100%',
    height: isMobile ? '240px' : '432px',
  };

  const center = {
    lat,
    lng,
  };

  return (
    <>
      {isLoaded && (
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={20}>
          <Marker position={center} />
        </GoogleMap>
      )}
    </>
  );
}

export default GoogleMaps;
