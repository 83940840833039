import { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';

export default function useSlideUpImage(thresholdValue?: number) {
  const [isActive, setIsActive] = useState(false);
  const divRef = useRef<HTMLDivElement | null>(null);
  const observerCallback: IntersectionObserverCallback = (entries) => {
    if (entries[0].isIntersecting) {
      setIsActive(true);
    }
  };
  useEffect(() => {
    let observer: IntersectionObserver;
    if (divRef.current) {
      observer = new IntersectionObserver(observerCallback, {
        threshold: isMobile ? 0.3 : thresholdValue || 0.6,
      });
      observer.observe(divRef.current);
    }
    return () => observer && observer.disconnect();
  }, []);
  return { isActive, divRef };
}
