import React from 'react';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router';
import styled from 'styled-components';

import FlexWrap from 'components/_common/FlexWrap';
import InnerLayout from 'components/_common/InnerLayout';
import { FranchiseeItemContent } from 'constants/Service';
import FranchiseeCarousel from './FranchiseeCarousel';
import GoogleMaps from './GoogleMaps';
import RecentItem from './RecentItem';
import { serviceRoutes } from 'routes';

function FranchiseeDetail() {
  const { state } = useLocation();

  return (
    <Container>
      <Inner>
        {/* 매장 간단한 설명 */}
        <FlexWrap dir='column' gap='36px' margin='0 20px 0 0'>
          <FlexWrap alignItems='center' gap={isMobile ? '16px' : '20px'}>
            <Name>{state.detail.title}</Name>
            <Icon
              src={state.detail.icon}
              width={isMobile ? '40px' : '48px'}
              height={isMobile ? '40px' : '48px'}
            />
          </FlexWrap>
          <Explain>{state.detail.content}</Explain>
        </FlexWrap>
        {/* 매장 정보 */}
        <FlexWrap
          dir='column'
          gap={isMobile ? '24px' : '12px'}
          margin='0 20px 0 0'
        >
          <Name>매장정보</Name>
          <FlexWrap
            dir={isMobile ? 'column' : 'row'}
            alignItems={isMobile ? 'flex-start' : 'center'}
            gap={isMobile ? '6px' : '42px'}
            margin={isMobile ? '4px 0 0 0' : '24px 0 0px 0'}
          >
            <StoreTitle>매장주소</StoreTitle>
            <StoreContent>{state.detail.address}</StoreContent>
          </FlexWrap>
          <FlexWrap
            dir={isMobile ? 'column' : 'row'}
            alignItems={isMobile ? 'flex-start' : 'center'}
            gap={isMobile ? '6px' : '42px'}
            margin={isMobile ? '0 0 4px 0' : '0 0 16px 0'}
          >
            <StoreTitle>전화번호</StoreTitle>
            <StoreContent>{state.detail.call}</StoreContent>
          </FlexWrap>
          <GoogleMaps position={state.detail.position} />
        </FlexWrap>
        {/* 매장 사진 */}
        <FlexWrap dir='column' gap='36px'>
          <Name>매장 사진</Name>
          <FranchiseeCarousel imgUrl={state.detail.imgUrl} />
        </FlexWrap>
      </Inner>
      <RecentItem
        title='최근에 올라온 가맹점 소개'
        link={serviceRoutes.franchiseeDetail}
        stores={FranchiseeItemContent.slice(0, 4)}
      />
    </Container>
  );
}

const Container = styled.section`
  background-color: #ffffff;
  text-align: center;
  padding: 54px 0 0 0;
`;

const Inner = styled(InnerLayout)`
  display: flex;
  flex-direction: column;
  gap: 68px;
  @media ${(props) => props.theme.mobile} {
    padding: 0 0 0 20px;
  }
`;

const Name = styled.p`
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
  text-align: left;
  @media ${(props) => props.theme.mobile} {
    font-size: 24px;
    line-height: 32px;
  }
`;

const Icon = styled.img`
  object-fit: contain;
`;

const Explain = styled.p`
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  @media ${(props) => props.theme.mobile} {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }
`;

const StoreTitle = styled.p`
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  @media ${(props) => props.theme.mobile} {
    font-size: 18px;
    line-height: 24px;
  }
`;

const StoreContent = styled.p`
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  @media ${(props) => props.theme.mobile} {
    font-size: 16px;
    line-height: 24px;
  }
`;

export default FranchiseeDetail;
