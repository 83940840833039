import React from 'react';

import TabButton from 'components/_common/TabButton';
import { serviceRoutes } from 'routes';
import PolicyContent from './PolicyContent';

const links = [
  {
    text: '가맹점 소개',
    link: serviceRoutes.franchisee,
    pathname: '/service',
  },
  {
    text: '지원정책 소식',
    link: serviceRoutes.policy,
    pathname: '/service/policy',
  },
];

function Policy() {
  return (
    <>
      <TabButton links={links} />
      <PolicyContent />
    </>
  );
}

export default Policy;
