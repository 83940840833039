import React, { TextareaHTMLAttributes } from 'react';
import { FieldError } from 'react-hook-form';
import styled from 'styled-components';
import { CalculateBox } from 'utils/common';
import ErrorAlert from 'assets/common/alert.png';
import { IComponentProps } from 'model/types';

interface IStyleProps {
  margin: string;
  padding: string;
}

type TextareaProps = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

interface IProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement>,
    IComponentProps {
  error?: FieldError | undefined;
  label?: string;
  isRequired?: boolean;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, IProps & TextareaProps>(
  (
    {
      error,
      label,
      isRequired = false,
      margin = [0],
      padding = [0],
      ...inputAttributes
    }: IProps,
    ref
  ): JSX.Element => {
    return (
      <Container margin={CalculateBox(margin)} padding={CalculateBox(padding)}>
        {Boolean(label) && (
          <LabelWrapper>
            <Label>{label}</Label>
            {isRequired && <RequiredDot>*</RequiredDot>}
          </LabelWrapper>
        )}
        <STextarea ref={ref} isError={Boolean(error)} {...inputAttributes} />
        {error && (
          <ErrorWrapper>
            <ErrorIcon
              width='13.5px'
              height='13.5px'
              src={ErrorAlert}
              alt='경고.'
            />
            <Error>{error.message}</Error>
          </ErrorWrapper>
        )}
      </Container>
    );
  }
);

const Container = styled.div<IStyleProps>`
  display: flex;
  flex-direction: column;
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  @media ${(props) => props.theme.desktop} {
    align-items: flex-start;
  }
  @media ${(props) => props.theme.tablet} {
    flex-direction: column;
  }
`;

const STextarea = styled.textarea<{ isError: boolean }>`
  &::placeholder {
    font-size: 1rem;
    line-height: 150%;
    font-family: 'Pretendard';
    white-space: pre-line;
  }
  padding: 12px 16px;
  display: block;
  width: 100%;
  border: 2px solid ${(props) => (props.isError ? '#f13e4b' : '#c4c4c4')};
  border-radius: 4px;
  font-size: 1rem;
  line-height: 150%;
  outline-color: ${(props) => props.theme.primaryColor};
  resize: none;
  min-height: 244px;

  @media ${(props) => props.theme.tablet} {
    min-height: 343px;
    padding: 14px 16px;
    font-size: 0.875rem;

    &::placeholder {
      font-size: 0.875rem;
    }
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const Label = styled.label`
  font-size: 1.3125rem;
  line-height: 150%;
`;

const RequiredDot = styled.div`
  color: #f13e4b;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-left: 6px;
`;

const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-right: 20px;
  margin: 10px 0 10px;
`;

const ErrorIcon = styled.img`
  margin-right: 10px;
`;

const Error = styled.div`
  color: #f13e4b;
  font-size: 12px;
  line-height: 22px;
  font-weight: 400;
`;
export default Textarea;
