import { addComma } from './common';
import { getRefundAmount } from './getRefundAmount';

export const removeLetter = (text: string) => {
  return text.replace(/[^0-9]/g, '');
};

export const comma = (x: string | number) => {
  return String(x).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatPaymentPrice = (
  originPrice: string,
  refundPrice: string
) => {
  return +originPrice - +refundPrice;
};
export const formatRefundPrice = (num: string) => {
  return getRefundAmount(+num);
};
export const formatNumber = (num: number) => {
  return String(num).length === 1 ? `0${num}` : num;
};

export function getNumberTime(date: Date) {
  const year = date.getFullYear().toString().slice(2);
  let month = String(date.getMonth() + 1);
  let day = date.getDate().toString();

  if (month.length === 1) {
    month = '0' + month;
  }
  if (day.length === 1) {
    day = '0' + day;
  }
  return year + month + day;
}

export const getKoreanPrice = (price: number) => {
  if (price === 0) {
    return '0원';
  }
  const hundredsOfMillions = Math.floor(price / 100000000); //억 단위
  const tensOfThousands = Math.floor((price % 100000000) / 10000); //만~천만 단위
  const underTensOfThousands = price % 10000; //만 미만 단위
  return `${hundredsOfMillions ? comma(hundredsOfMillions) + '억 ' : ''}${
    tensOfThousands ? comma(tensOfThousands) + '만' : ''
  }${underTensOfThousands ? ' ' : ''}${
    underTensOfThousands ? comma(underTensOfThousands) : ''
  }원`;
};
