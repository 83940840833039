import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import arrowIcon from 'assets/service/arrowRight.png';
import mapIcon from 'assets/service/map.png';
import FlexWrap from 'components/_common/FlexWrap';
import { IFranchiseeList, IPolicyList } from 'types/service';
import LazyImage from 'components/_common/LazyImage';
import { isMobile } from 'react-device-detect';
import PolicyItem from './PolicyItem';
import FranchisseItem from './FranchisseItem';

interface IProps {
  isPolicy?: boolean;
  item: IFranchiseeList | IPolicyList;
  link: string;
  isLandingPage?: boolean;
}

function ServiceItem({ item, link, isPolicy, isLandingPage = false }: IProps) {
  return (
    <>
      <Container to={link} state={{ detail: item.detail, index: item.id - 1 }}>
        <LazyImage
          src={item.image}
          width={isMobile && isLandingPage ? '330px' : '100%'}
          height={isMobile && isLandingPage ? '140px' : '150px'}
          // objectFit='contain'
          borderRadiusLeftRight='8px'
          alt={item.alt}
        />
        <Content>
          <Header>
            <SubTitle>
              {isPolicy ? (
                <PolicyItem item={item as IPolicyList} />
              ) : (
                <FranchisseItem item={item as IFranchiseeList} />
              )}
            </SubTitle>
          </Header>
          <FlexWrap justifyContent='space-between' alignItems='center'>
            <FlexWrap alignItems='center' margin='5px 0 0 0'>
              {isPolicy ? (
                <Title>{item.title.substring(0, 9) + '...'}</Title>
              ) : (
                <>
                  <Title>{item.title}</Title>
                  <Icon src={mapIcon} width='24px' height='24px' alt='위치.' />
                </>
              )}
            </FlexWrap>
            <Icon
              className='arrowIcon'
              src={arrowIcon}
              width={isMobile ? '12px' : '24px'}
              height={isMobile ? '12px' : '24px'}
              alt='화살표.'
            />
          </FlexWrap>
        </Content>
      </Container>
    </>
  );
}

const Container = styled(Link)`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  width: 280px;
  border-radius: 8px;
  -webkit-box-shadow: 6px 4px 12px 3px rgba(25, 25, 25, 0.3);
  box-shadow: 6px 4px 12px 3px rgba(25, 25, 25, 0.3);
  background-color: #ffffff;
  @media ${(props) => props.theme.mobile} {
    width: calc(50% - 7.5px);
  }
  :hover {
    .arrowIcon {
      animation-name: arrow;
      animation-duration: 0.8s;
      animation-iteration-count: infinite;
    }
  }
  @keyframes arrow {
    from {
      transform: translateX(0px);
    }
    to {
      transform: translateX(10px);
    }
  }
`;

const Content = styled.div`
  width: 100%;
  padding: 16px;
  @media ${(props) => props.theme.mobile} {
    padding: 16px 12px 16px 12px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Title = styled.p`
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  color: #191919;
  @media ${(props) => props.theme.mobile} {
    font-size: 18px;
    line-height: 24px;
  }
`;

const SubTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Icon = styled.img`
  object-fit: contain;
  :hover {
  }
`;

export default ServiceItem;
