import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import ToggleButton from 'components/_common/ToggleButton';
import { IQnaItemProps } from 'model/qnaDataClasses';

interface IActiveProps {
  isActive: boolean;
}

interface IAnswerProps extends IActiveProps {
  activeHeight: number;
}

function QuestionItem({ title, content, height }: IQnaItemProps) {
  const [isActive, setIsActive] = useState(false);
  const onToggleHide: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      setIsActive((prevState) => !prevState);
    },
    []
  );

  return (
    <Question>
      <QuestionWrapper onClick={onToggleHide} isActive={isActive}>
        <Type isActive={isActive}>
          <span>Q.</span>
          <div>{title}</div>
        </Type>
        <ToggleButton isActive={isActive} />
      </QuestionWrapper>
      <Answer isActive={isActive} activeHeight={height}>
        <span>A.</span>
        <AnswerContent>
          {content.map((sub, index) => (
            <ContentWrapper key={index} isMarin={content.length - 1 !== index}>
              <AnswerContent>{sub.subTitle}</AnswerContent>
              {sub.subContent?.map((item, index) => (
                <SubContentWrapper key={index}>
                  <span>•</span>
                  <AnswerContent>{item}</AnswerContent>
                </SubContentWrapper>
              ))}
            </ContentWrapper>
          ))}
        </AnswerContent>
      </Answer>
    </Question>
  );
}

const Question = styled.li`
  position: relative;
`;

const QuestionWrapper = styled.div<IActiveProps>`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f5f6f7;
  z-index: 5;
  ${(props) =>
    props.isActive &&
    css`
      color: ${(props) => props.theme.primaryColor};
    `}
  @media ${(props) => props.theme.desktop} {
    padding: 35px 0;
  }
  @media ${(props) => props.theme.tablet} {
    padding: 20px 0;
    margin: 0 16px;
  }
`;

const Type = styled.h2<IActiveProps>`
  display: flex;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  & span {
    margin-right: 11px;
  }
  @media ${(props) => props.theme.mobile} {
    font-size: 20px;
    line-height: 28px;
  }
`;
const Answer = styled.div<IAnswerProps>`
  font-size: 17px;
  line-height: 25px;
  font-weight: 400;
  background-color: rgba(149, 196, 214, 0.1);
  color: ${(props) => props.theme.fontColorContent};
  z-index: 2;
  min-height: 0;
  display: flex;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: flex-start;
  ${(props) =>
    !props.isActive
      ? css`
          height: 0;
          overflow: hidden;
          opacity: 0;
          padding: 0 37px;
        `
      : css`
          overflow: visible;
          opacity: 1;
          padding: 37px;
        `}
  & span {
    margin-right: 15px;
    color: #000;
    font-size: 20px;
    line-height: 29px;
  }
  @media ${(props) => props.theme.mobile} {
    align-items: flex-start;
    & span {
      margin-right: 15px;
      color: #000;
      font-size: 16px;
      line-height: 24px;
    }
    ${(props) =>
      !props.isActive
        ? css`
            height: 0;
            overflow: hidden;
            opacity: 0;
            padding: 0 36px 0 44px;
          `
        : css`
            overflow: visible;
            opacity: 1;
            padding: 20px 36px 20px 44px;
          `}
  }
`;

const AnswerContent = styled.div`
  font-weight: 400;
  color: ${(props) => props.theme.fontColorContent};
  font-size: 20px;
  line-height: 28px;
  @media ${(props) => props.theme.mobile} {
    font-size: 18px;
    line-height: 24px;
  }
`;

const ContentWrapper = styled.div<{ isMarin: boolean }>`
  ${(props) => props.isMarin && 'margin: 0 0 25px;'};
  white-space: pre-wrap;
`;

const SubContentWrapper = styled.div`
  display: flex;
  & span {
    margin-right: 5px;
  }
`;

export default QuestionItem;
