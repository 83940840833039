import React from 'react';
import styled, { css } from 'styled-components';

export enum HightlightType {
  ALL = 'all',
  HALF = 'half',
  NONE = 'none',
}

interface ISNoticeProps {
  isHighlight: HightlightType;
}

const SNotice = styled.div<ISNoticeProps>`
  font-size: 22px;
  line-height: 30px;
  margin: 0 0 30px;
  & span {
    display: block;
  }
  ${(props) =>
    props.isHighlight === HightlightType.HALF &&
    css`
      & span:nth-of-type(1) {
        font-weight: 400;
      }
    `}
  ${(props) =>
    props.isHighlight === HightlightType.NONE &&
    css`
      & span {
        font-weight: 400;
      }
    `}
`;

interface INoticeProps {
  isHighlight?: HightlightType;
  children: React.ReactNode;
}

function Notice({ children, isHighlight = HightlightType.ALL }: INoticeProps) {
  return <SNotice isHighlight={isHighlight}>{children}</SNotice>;
}

export default Notice;
