import Type1Png from 'assets/globalPay/business_type_1.png';
import Type2Png from 'assets/globalPay/business_type_2.png';
import Type3Png from 'assets/globalPay/business_type_3.png';
import Type4Png from 'assets/globalPay/business_type_4.png';
import Type5Png from 'assets/globalPay/business_type_5.png';
import Type1Webp from 'assets/globalPay/business_type_1.webp';
import Type2Webp from 'assets/globalPay/business_type_2.webp';
import Type3Webp from 'assets/globalPay/business_type_3.webp';
import Type4Webp from 'assets/globalPay/business_type_4.webp';
import Type5Webp from 'assets/globalPay/business_type_5.webp';
import { checkWebpSupport } from 'utils/checkWebpSupport';

export const benefitKeywords = [
  { keyword: '텍스 리펀', benefit: '이용료 무료' },
  { keyword: '해외 간편 결제', benefit: '최저 수수료' },
  { keyword: '10만원 상당', benefit: '홍보물 제공' },
];
export const businessTypeList = [
  {
    title: '식당/바',
    imgUrl: checkWebpSupport(Type1Webp, Type1Png),
  },
  {
    title: '카페/베이커리',
    imgUrl: checkWebpSupport(Type2Webp, Type2Png),
  },
  {
    title: '뷰티/케어',
    imgUrl: checkWebpSupport(Type3Webp, Type3Png),
  },
  {
    title: '의류/잡화',
    imgUrl: checkWebpSupport(Type4Webp, Type4Png),
  },
  {
    title: '숙박/공간대여',
    imgUrl: checkWebpSupport(Type5Webp, Type5Png),
  },
];
