import FlexWrap from 'components/_common/FlexWrap';
import { businessTypeList } from 'constants/GlobalPay';
import React from 'react';
import styled from 'styled-components';

function BusinessType() {
  return (
    <Background>
      <Wrapper>
        <Container>
          <Title>해외 간편 결제로 고객 편의를 높여보세요!</Title>
          <Content>KTP로 쉽고 빠르게 해외 간편 결제를 도입해보세요.</Content>
          <ItemContainer>
            {businessTypeList.map((item, idx) => (
              <TypeItem key={idx} title={item.title} imgUrl={item.imgUrl} />
            ))}
          </ItemContainer>
        </Container>
      </Wrapper>
      <div className='custom-shape-divider-bottom-2'>
        <svg
          data-name='Layer 1'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 1200 120'
          preserveAspectRatio='none'
        >
          <path
            d='M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z'
            className='shape-fill'
          ></path>
        </svg>
      </div>
    </Background>
  );
}
const Background = styled.div`
  position: relative;
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1190px;
  width: 100%;
  margin: 0 auto;
  padding: 6.75rem 7.75rem 6.25rem;
  @media ${(props) => props.theme.tablet} {
    padding: 3.35rem 1.25rem 3.35rem;
  }
`;
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: pre-wrap;
`;
const Title = styled.h2`
  font-size: 2rem;
  line-height: 150%;
  margin-bottom: 1rem;
  font-weight: 700;
  @media ${(props) => props.theme.tablet} {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
`;
const Content = styled.p`
  font-size: 1.375rem;
  line-height: 150%;
  color: #80848a;
  margin-bottom: 5.6rem;
  @media ${(props) => props.theme.tablet} {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
`;
const ItemContainer = styled(FlexWrap)`
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
  @media ${(props) => props.theme.tablet} {
    width: 28rem;
    flex-wrap: wrap;
  }
`;
export default BusinessType;

type TypeItemProps = {
  title: string;
  imgUrl: string;
};
function TypeItem({ title, imgUrl }: TypeItemProps) {
  return (
    <FlexWrap
      gap='1rem'
      justifyContent='center'
      alignItems='center'
      dir='column'
    >
      <ImgCircle src={imgUrl} />
      <ItemLabel>{title}</ItemLabel>
    </FlexWrap>
  );
}

const ImgCircle = styled.img`
  width: 10rem;
  height: 10rem;
  @media ${(props) => props.theme.tablet} {
    width: 7.5rem;
    height: 7.5rem;
  }
`;
const ItemLabel = styled.span`
  font-size: 1.125rem;
  line-height: 150%;
  color: #030303;
`;
