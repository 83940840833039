import React from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

import CheckIcon from 'assets/application/check_circle.png';
import FlexWrap from 'components/_common/FlexWrap';

function InquiryResult() {
  return (
    <FlexWrap dir='column' alignItems='center'>
      {isMobile ? (
        <StyledImg width='64px' height='64px' src={CheckIcon} alt='확인.' />
      ) : (
        <StyledImg width='100px' height='100px' src={CheckIcon} alt='확인.' />
      )}
      <StyledText>
        문의가 정상적으로 접수되었습니다.
        <br />
        확인 후, 빠른 시일 내에{isMobile && <br />} 회신 드리겠습니다.
      </StyledText>
    </FlexWrap>
  );
}
const StyledImg = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 80px;
  @media ${(props) => props.theme.mobile} {
    width: 64px;
    height: 64px;
    margin-bottom: 48px;
  }
`;
const StyledText = styled.p`
  font-size: 32px;
  line-height: 44px;
  text-align: center;
  margin-bottom: 140px;
  @media ${(props) => props.theme.mobile} {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 80px;
  }
`;
export default InquiryResult;
