import React, { ReactElement } from 'react';
import styled from 'styled-components';

import InnerLayout from 'components/_common/InnerLayout';
import PolicyTitleImage from './PolicyTitleImage';
import { IPolicyDetail } from 'types/service';

interface IProps {
  detail: IPolicyDetail;
  PolicyContentArray: ReactElement[];
}

function PolicyDetail1({ detail, PolicyContentArray }: IProps) {
  if (!PolicyContentArray) {
    return null;
  }

  return (
    <Container>
      <Inner>
        {PolicyContentArray.map((item, index) => (
          <PolicyTitleImage
            idx={index}
            image={detail.image[index]}
            contentTitle={detail.contentTitle[index]}
            PolicyItem={item}
            length={PolicyContentArray.length}
          />
        ))}
      </Inner>
    </Container>
  );
}

const Container = styled.section`
  background-color: #ffffff;
  text-align: center;
  padding: 54px 0;
  @media ${(props) => props.theme.mobile} {
    padding-bottom: 0px;
  }
`;

const Inner = styled(InnerLayout)`
  display: flex;
  flex-direction: column;
  gap: 48px;
  h4 {
    margin-bottom: -24px;
  }
  @media ${(props) => props.theme.mobile} {
    gap: 42px;
    h4 {
      margin-bottom: -18px;
    }
  }
`;

const Image = styled.img`
  border-radius: 6px;
  object-fit: contain;
`;

const Title = styled.h4`
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
  text-align: left;
  @media ${(props) => props.theme.mobile} {
    font-size: 20px;
    line-height: 28px;
  }
`;

export default PolicyDetail1;
