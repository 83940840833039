import React, { useState } from 'react';
import { useLocation } from 'react-router';
import styled, { css } from 'styled-components';

import LogoIcon from 'assets/common/logo_ktp.png';
import AppStoreIcon from 'assets/footer/app_store.png';
import GooglePlayIcon from 'assets/footer/google_play.png';
import BlogIcon from 'assets/footer/naver_blog.png';
import KakaoIcon from 'assets/footer/kakao_channel.png';
import ChevronDownIcon from 'assets/footer/chevron_down.png';
import ChevronUpIcon from 'assets/footer/chevron_up.png';
import InnerLayout from 'components/_common/InnerLayout';
import FlexWrap from 'components/_common/FlexWrap';
import LazyImage from 'components/_common/LazyImage';
import useDeviceCheck from 'hooks/useDeviceCheck';

function Footer() {
  const { pathname } = useLocation();
  const [isOpenToggle, setIsOpenToggle] = useState(false);
  const onToggle = () => {
    setIsOpenToggle((prev) => !prev);
  };
  const { isTablet } = useDeviceCheck();
  return (
    <Container
      isShow={[
        '/',
        '/refund',
        '/refund/medical',
        '/refund/lodgment',
        '/refund/global-payment',
        '/faq',
        '/application',
        '/trial',
        '/service',
        '/service/policy',
        '/service/franchisee/detail',
        '/service/policy/detail',
      ].includes(pathname)}
      isTrial={pathname === '/trial'}
    >
      <Inner>
        <FlexWrap alignItems='flex-start' dir={isTablet ? 'column' : 'row'}>
          <LazyWrapper>
            <LazyImage src={LogoIcon} alt='석세스모드.' />
          </LazyWrapper>

          {isTablet && (
            <>
              <ToggleBtn onClick={onToggle}>
                사업자 정보
                <ArrowIcon
                  src={isOpenToggle ? ChevronUpIcon : ChevronDownIcon}
                  width='24px'
                  height='24px'
                  alt='사업자 정보 더보기.'
                />
              </ToggleBtn>
              <Content>
                이메일: ktp@ktaxpay.com {isTablet ? <br /> : 'ㅣ '}고객센터:
                02-6275-8011
              </Content>
            </>
          )}
          <TogleBtnContainer>
            {!isTablet && (
              <ToggleBtn style={{ marginTop: 0 }} onClick={onToggle}>
                사업자 정보
                <ArrowIcon
                  src={isOpenToggle ? ChevronUpIcon : ChevronDownIcon}
                  width='24px'
                  height='24px'
                  alt='사업자 정보 더보기.'
                />
              </ToggleBtn>
            )}
            <ToggleArea
              isOpenToggle={isOpenToggle}
              dir={isTablet ? 'column' : 'row'}
            >
              <Content>
                상호명: 주식회사 케이티피 ㅣ 대표 : 주병천{' '}
                {isTablet ? <br /> : 'ㅣ '}
                사업자등록번호: 412-88-02679
                <br />
                주소 : 경기도 안양시 동안구 시민대로327번길 11-41, 3층 3265호
                <br />
              </Content>{' '}
            </ToggleArea>
            <Content>
              {!isTablet &&
                '이메일: ktp@ktaxpay.com | 고객센터: 02-6275-8011\n\nⓒ 2022 KTP Corp.'}
            </Content>
          </TogleBtnContainer>

          <FlexWrap>
            <CircleBtn
              href='https://blog.naver.com/successmode'
              target='_blank'
            >
              <CircleBtnIconWrapper>
                <LazyImage src={BlogIcon} alt='네이버 블로그.' />
              </CircleBtnIconWrapper>
            </CircleBtn>
            <CircleBtn href='http://pf.kakao.com/_fFgsb' target='_blank'>
              <CircleBtnIconWrapper>
                <LazyImage src={KakaoIcon} alt='카카오 채널.' />
              </CircleBtnIconWrapper>
            </CircleBtn>
          </FlexWrap>
          <StoreBtnWrapper isOpenToggle={isOpenToggle}>
            <StoreBtn
              href='https://play.google.com/store/apps/details?id=com.successmode.ktp'
              target='_blank'
            >
              <StoreBtnImgWrapper>
                <LazyImage src={GooglePlayIcon} alt='앱스토어.' />
              </StoreBtnImgWrapper>
            </StoreBtn>

            <StoreBtn
              href='https://apps.apple.com/kr/app/ktp/id1566544989'
              target='_blank'
            >
              <StoreBtnImgWrapper>
                <LazyImage src={AppStoreIcon} alt='구글플레이스토어.' />
              </StoreBtnImgWrapper>
            </StoreBtn>
          </StoreBtnWrapper>
        </FlexWrap>
        {isTablet && <CopyrightText>ⓒ 2022 KTP Corp.</CopyrightText>}
      </Inner>
    </Container>
  );
}
const Container = styled.footer<{ isShow: boolean; isTrial: boolean }>`
  background-color: #031014;
  padding: 5rem 0;
  ${(props) =>
    !props.isShow &&
    css`
      display: none;
    `}
  ${(props) =>
    props.isTrial &&
    css`
      bottom: 0;
      width: 100%;
    `}
`;
const Inner = styled(InnerLayout)`
  padding: 0px 20px;
  @media ${(props) => props.theme.tablet} {
  }
`;
const Content = styled.p`
  color: #ffffff;
  opacity: 0.75;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin-right: 90px;
  transition: all 300ms ease-in-out;
  height: auto;
  white-space: pre-wrap;
  @media ${(props) => props.theme.tablet} {
    margin-right: 0px;
  }
`;
const LazyWrapper = styled.div`
  width: 180px;
  height: 52px;
  margin-right: 40px;
  @media ${(props) => props.theme.tablet} {
    width: 100px;
    height: 29px;
  }
`;

const ToggleBtn = styled.button`
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  opacity: 0.75;
  padding: 0;
  font-weight: 400;
  margin-top: 26px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  @media ${(props) => props.theme.desktop} {
    /* display: none; */
  }
`;
const ToggleArea = styled(FlexWrap)<{ isOpenToggle: boolean }>`
  transition: all 300ms ease-in-out;
  display: block;
  ${(props) =>
    !props.isOpenToggle &&
    css`
      height: 0;
      display: none;
      margin: 0;
    `};
`;
const ArrowIcon = styled.img`
  margin-left: 4px;
`;
const CircleBtn = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.15);
  margin-left: 12px;
  transition: all 300ms ease-in-out;
  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
  @media ${(props) => props.theme.tablet} {
    margin: 12px 12px 0 0;
  }
`;
const StoreBtnWrapper = styled(FlexWrap)<{ isOpenToggle: boolean }>`
  @media ${(props) => props.theme.tablet} {
  }
`;
const StoreBtn = styled.a`
  margin-left: 14px;
  @media ${(props) => props.theme.tablet} {
    margin: 12px 12px 12px 0;
  }
`;
const StoreBtnImgWrapper = styled.div`
  transition: all ease-in-out 300ms;
  /* width: 160px; */
  height: 48px;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;
const CircleBtnIconWrapper = styled.div`
  transition: all ease-in-out 300ms;
  width: 32px;
  height: 30px;
`;
const CopyrightText = styled.p`
  color: #ffffff;
  opacity: 0.75;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  padding-top: 8px;
  width: 100%;
  @media ${(props) => props.theme.tablet} {
    margin: 12px 12px 12px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
  }
`;
const TogleBtnContainer = styled(FlexWrap)`
  flex-direction: column;
  margin-top: 0;
  width: 500px;
`;
export default Footer;
