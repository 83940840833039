import FlexWrap from 'components/_common/FlexWrap';
import React from 'react';
import styled from 'styled-components';

const Inner = styled(FlexWrap)`
  padding: 70px 20px 20px;
  width: 350px;
`;

interface IProps {
  children: React.ReactNode;
}

function ContentInner({ children }: IProps) {
  return <Inner dir='column'>{children}</Inner>;
}

export default ContentInner;
