import { ReactNode, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { SlideUp } from 'animation';
import mobileImg from 'assets/home/KtpExample/mobile.png';
import arrowIcon from 'assets/home/KtpExample/arrow.png';
import FlexWrap from 'components/_common/FlexWrap';
import LazyImage from 'components/_common/LazyImage';
import Typography from 'components/_common/Typography';
import Icon from 'components/_common/Icon';
import { useKTPExample } from 'hooks/useKTPExample';
import useDeviceCheck from 'hooks/useDeviceCheck';

type KTPExampleItemProps = {
  item: KTPExampleItemType;
  idx: number;
  length: number;
};

type KTPExampleItemType = {
  title: string;
  keyword: string;
  content: { explain: ReactNode; items: (string | undefined)[] };
  images: string[];
};

function KTPExampleItem({ item, idx }: KTPExampleItemProps) {
  const { isTablet, isMobile } = useDeviceCheck();
  const [currentIndex, setCurrentIndex] = useState(1);
  const divRef = useRef<HTMLDivElement>(null);
  const { title, keyword, content, images } = item;
  const { slideRef, isActive, setIsActive } = useKTPExample(
    images,
    currentIndex,
    setCurrentIndex,
    idx
  );

  const observerCallback: IntersectionObserverCallback = (entries) => {
    if (entries[0].isIntersecting) {
      setIsActive(true);
    }
  };

  useEffect(() => {
    let observer: IntersectionObserver;
    if (divRef.current) {
      observer = new IntersectionObserver(observerCallback, {
        threshold: 0.6,
      });
      observer.observe(divRef.current);
    }
    return () => observer && observer.disconnect();
  }, []);

  return (
    <Container ref={divRef} isReverse={(idx + 1) % 2 === 0}>
      <SlideWrap isActive={isActive} delay='0ms'>
        <LazyImage
          src={mobileImg}
          width={isTablet ? '220px' : '272px'}
          height={isTablet ? '420px' : '540px'}
          alt='TaxFree Example.'
        />
        <SlideContainer isReportRefund={idx === 1 || idx === 2}>
          <Slider ref={slideRef} isReportImg={idx === 2}>
            {images.map((img, index) => (
              <SlideImage
                key={index}
                src={img}
                width={isTablet ? '200px' : '240px'}
                height={isTablet ? '390px' : '500px'}
              />
            ))}
          </Slider>
        </SlideContainer>
      </SlideWrap>
      <ContentContainer>
        <TopContent>
          <Typography size='1.3rem' fontWeight='500' color='#1856CD'>
            {keyword}
          </Typography>
          <Typography
            size='1.75rem'
            fontWeight='700'
            color='#191919'
            margin='0 0 2rem'
          >
            {title}
          </Typography>
        </TopContent>
        <BottomContent>
          <FlexWrap alignItems='center' gap='12px'>
            {content.items.map((item, i) => (
              <>
                {idx === 0 ? (
                  <>
                    <Progress>{item}</Progress>
                    {content.items.length - 1 !== i && (
                      <Icon
                        imgUrl={arrowIcon}
                        width='1.375rem'
                        height='1.375rem'
                      />
                    )}
                  </>
                ) : idx === 2 ? (
                  <Icon imgUrl={item!} width='17.5rem' height='3rem' />
                ) : (
                  <Icon
                    imgUrl={item!}
                    width={isMobile ? '5rem' : '7rem'}
                    height='1.5rem'
                  />
                )}
              </>
            ))}
          </FlexWrap>
          <Typography
            size='1rem'
            fontWeight='500'
            color='#191919'
            margin='2rem 0 0'
          >
            {content.explain}
          </Typography>
        </BottomContent>
      </ContentContainer>
    </Container>
  );
}

const Container = styled.div<{ isReverse: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isReverse ? 'row-reverse' : 'row')};
  width: 100%;
  gap: 10rem;
  padding: 6.75rem 0;
  @media ${(props) => props.theme.tablet} {
    gap: 0;
    flex-direction: column;
    padding: 2.81rem 0;
  }
`;
const SlideContainer = styled.div<{ isReportRefund: boolean }>`
  display: flex;
  position: absolute;
  align-items: flex-start;
  top: 20px;
  left: 15px;
  gap: 32px;
  overflow: hidden;
  width: 240px;
  height: 492px;
  flex: 0;
  border-radius: 10px;
  z-index: -100;
  @media ${(props) => props.theme.tablet} {
    width: 200px;
    height: 390px;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
  }
`;
const SlideWrap = styled.div<{ isActive: boolean; delay: string }>`
  position: relative;
  opacity: 0;
  ${(props) =>
    props.isActive &&
    css`
      animation: ${SlideUp} 400ms ${props.delay} ease-in forwards;
    `}
  @media ${(props) => props.theme.tablet} {
    display: flex;
    justify-content: center;
  }
`;
const Slider = styled.div<{ isReportImg: boolean }>`
  display: flex;
  flex-direction: ${(props) => props.isReportImg && 'column'};
`;
const SlideImage = styled.img`
  border-radius: 15px;
`;
const ContentContainer = styled(FlexWrap)`
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  @media ${(props) => props.theme.tablet} {
    margin-top: 2.64rem;
    align-items: center;
    width: 100%;
  }
`;
const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;
  @media ${(props) => props.theme.tablet} {
    text-align: center;
    white-space: normal;
  }
`;

const BottomContent = styled(FlexWrap)`
  flex-direction: column;
  white-space: pre-wrap;
  gap: 12px;

  @media ${(props) => props.theme.tablet} {
    align-items: center;
    text-align: center;
  }
`;
const Progress = styled(FlexWrap)`
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 5rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #1856cd;
  background-color: #fff;
  border: 1px solid #1856cd;
  border-radius: 50%;
`;
export default KTPExampleItem;
