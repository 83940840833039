import styled from 'styled-components';

const InnerLayout = styled.div`
  max-width: 1190px;
  width: 100%;
  margin: 0 auto;
  @media ${(props) => props.theme.tablet} {
    padding: 0 20px;
  }
`;

export default InnerLayout;
