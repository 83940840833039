import { checkWebpSupport } from 'utils/checkWebpSupport';
import glassIcon from 'assets/service/kind/glasses.png';
import souvenirIcon from 'assets/service/kind/souvenir.png';
import accessoriesIcon from 'assets/service/kind/accessories.png';
import medicineIcon from 'assets/service/kind/medicine.png';
import fashionIcon from 'assets/service/kind/fashionAccessories.png';
import golfIcon from 'assets/service/kind/golf.png';
import clothingIcon from 'assets/service/kind/clothing.png';

// 1. 그날의 안경
import storeBg1Webp from 'assets/service/store/store1/storeBg1.webp';
import storeBg1 from 'assets/service/store/store1/storeBg1.png';
import store1Webp from 'assets/service/store/store1/store1.webp';
import store1 from 'assets/service/store/store1/store1.png';
import store1Detail1Webp from 'assets/service/store/store1/storeDetail1.jpeg';
import store1Detail1 from 'assets/service/store/store1/storeDetail1.jpeg';
import store1Detail2Webp from 'assets/service/store/store1/storeDetail2.jpeg';
import store1Detail2 from 'assets/service/store/store1/storeDetail2.jpeg';
import store1Detail3Webp from 'assets/service/store/store1/storeDetail3.jpeg';
import store1Detail3 from 'assets/service/store/store1/storeDetail3.jpeg';
import store1Detail4Webp from 'assets/service/store/store1/storeDetail4.jpeg';
import store1Detail4 from 'assets/service/store/store1/storeDetail4.jpeg';

// 2. 신들네돌
import store2Webp from 'assets/service/store/store2/store2.webp';
import store2 from 'assets/service/store/store2/store2.png';
import store2Detail1Webp from 'assets/service/store/store2/storeDetail1.webp';
import store2Detail1 from 'assets/service/store/store2/storeDetail1.png';
import store2Detail2Webp from 'assets/service/store/store2/storeDetail2.webp';
import store2Detail2 from 'assets/service/store/store2/storeDetail2.png';
import store2Detail3Webp from 'assets/service/store/store2/storeDetail3.webp';
import store2Detail3 from 'assets/service/store/store2/storeDetail3.png';
import store2Detail4Webp from 'assets/service/store/store2/storeDetail4.webp';
import store2Detail4 from 'assets/service/store/store2/storeDetail4.png';

// 3. 소명약국
import store3Webp from 'assets/service/store/store3/store3.webp';
import store3 from 'assets/service/store/store3/store3.png';
import store3Detail1Webp from 'assets/service/store/store3/storeDetail1.webp';
import store3Detail1 from 'assets/service/store/store3/storeDetail1.jpeg';
import store3Detail2Webp from 'assets/service/store/store3/storeDetail2.webp';
import store3Detail2 from 'assets/service/store/store3/storeDetail2.jpeg';
import store3Detail3Webp from 'assets/service/store/store3/storeDetail3.webp';
import store3Detail3 from 'assets/service/store/store3/storeDetail3.jpeg';
import store3Detail4Webp from 'assets/service/store/store3/storeDetail4.webp';
import store3Detail4 from 'assets/service/store/store3/storeDetail4.jpeg';

// 4. 라퐁텐
import store4Webp from 'assets/service/store/store4/store4.webp';
import store4 from 'assets/service/store/store4/store4.png';
import store4Detail1Webp from 'assets/service/store/store4/storeDetail1.webp';
import store4Detail1 from 'assets/service/store/store4/storeDetail1.jpeg';
import store4Detail2Webp from 'assets/service/store/store4/storeDetail2.webp';
import store4Detail2 from 'assets/service/store/store4/storeDetail2.jpeg';
import store4Detail3Webp from 'assets/service/store/store4/storeDetail3.webp';
import store4Detail3 from 'assets/service/store/store4/storeDetail3.jpeg';
import store4Detail4Webp from 'assets/service/store/store4/storeDetail4.webp';
import store4Detail4 from 'assets/service/store/store4/storeDetail4.jpeg';

// 5. 북촌약국
import store5Webp from 'assets/service/store/store5/store5.webp';
import store5 from 'assets/service/store/store5/store5.png';
import store5Detail1Webp from 'assets/service/store/store5/storeDetail1.webp';
import store5Detail1 from 'assets/service/store/store5/storeDetail1.png';
import store5Detail2Webp from 'assets/service/store/store5/storeDetail2.webp';
import store5Detail2 from 'assets/service/store/store5/storeDetail2.png';
import store5Detail3Webp from 'assets/service/store/store5/storeDetail3.webp';
import store5Detail3 from 'assets/service/store/store5/storeDetail3.png';
import store5Detail4Webp from 'assets/service/store/store5/storeDetail4.webp';
import store5Detail4 from 'assets/service/store/store5/storeDetail4.png';

// 6. 세미클론
import store6Webp from 'assets/service/store/store6/store6.webp';
import store6 from 'assets/service/store/store6/store6.jpeg';
import store6Detail1Webp from 'assets/service/store/store6/storeDetail1.webp';
import store6Detail1 from 'assets/service/store/store6/storeDetail1.jpeg';
import store6Detail2Webp from 'assets/service/store/store6/storeDetail2.webp';
import store6Detail2 from 'assets/service/store/store6/storeDetail2.jpeg';
import store6Detail3Webp from 'assets/service/store/store6/storeDetail3.webp';
import store6Detail3 from 'assets/service/store/store6/storeDetail3.jpeg';
import store6Detail4Webp from 'assets/service/store/store6/storeDetail4.webp';
import store6Detail4 from 'assets/service/store/store6/storeDetail4.jpeg';

// 7. 에덴도자기
import store7Webp from 'assets/service/store/store7/store7.webp';
import store7 from 'assets/service/store/store7/store7.jpeg';
import store7Detail1Webp from 'assets/service/store/store7/storeDetail1.webp';
import store7Detail1 from 'assets/service/store/store7/storeDetail1.jpeg';
import store7Detail2Webp from 'assets/service/store/store7/storeDetail2.webp';
import store7Detail2 from 'assets/service/store/store7/storeDetail2.jpeg';
import store7Detail3Webp from 'assets/service/store/store7/storeDetail3.webp';
import store7Detail3 from 'assets/service/store/store7/storeDetail3.jpeg';
import store7Detail4Webp from 'assets/service/store/store7/storeDetail4.webp';
import store7Detail4 from 'assets/service/store/store7/storeDetail4.jpeg';

// 8. 홍익약국
import store8Webp from 'assets/service/store/store8/store8.webp';
import store8 from 'assets/service/store/store8/store8.png';
import store8Detail1Webp from 'assets/service/store/store8/storeDetail1.webp';
import store8Detail1 from 'assets/service/store/store8/storeDetail1.png';
import store8Detail2Webp from 'assets/service/store/store8/storeDetail2.webp';
import store8Detail2 from 'assets/service/store/store8/storeDetail2.jpg';
import store8Detail3Webp from 'assets/service/store/store8/storeDetail3.webp';
import store8Detail3 from 'assets/service/store/store8/storeDetail3.jpg';
import store8Detail4Webp from 'assets/service/store/store8/storeDetail4.webp';
import store8Detail4 from 'assets/service/store/store8/storeDetail4.jpg';

// 9. 별꼴상회
import store9Webp from 'assets/service/store/store9/store9.webp';
import store9 from 'assets/service/store/store9/store9.png';
import store9Detail1Webp from 'assets/service/store/store9/storeDetail1.webp';
import store9Detail1 from 'assets/service/store/store9/storeDetail1.jpeg';
import store9Detail2Webp from 'assets/service/store/store9/storeDetail2.webp';
import store9Detail2 from 'assets/service/store/store9/storeDetail2.jpeg';
import store9Detail3Webp from 'assets/service/store/store9/storeDetail3.webp';
import store9Detail3 from 'assets/service/store/store9/storeDetail3.jpeg';
import store9Detail4Webp from 'assets/service/store/store9/storeDetail4.webp';
import store9Detail4 from 'assets/service/store/store9/storeDetail4.jpeg';

// 10. 스탠다드 안경원
import store10Webp from 'assets/service/store/store10/store10.webp';
import store10 from 'assets/service/store/store10/store10.png';
import store10Detail1Webp from 'assets/service/store/store10/storeDetail1.webp';
import store10Detail1 from 'assets/service/store/store10/storeDetail1.png';
import store10Detail2Webp from 'assets/service/store/store10/storeDetail2.webp';
import store10Detail2 from 'assets/service/store/store10/storeDetail2.png';
import store10Detail3Webp from 'assets/service/store/store10/storeDetail3.webp';
import store10Detail3 from 'assets/service/store/store10/storeDetail3.png';
import store10Detail4Webp from 'assets/service/store/store10/storeDetail4.webp';
import store10Detail4 from 'assets/service/store/store10/storeDetail4.png';

// 11. 씨샵 홍대 플래그쉽
import store11Webp from 'assets/service/store/store11/store11.webp';
import store11 from 'assets/service/store/store11/store11.jpg';
import store11Detail1Webp from 'assets/service/store/store11/storeDetail1.webp';
import store11Detail1 from 'assets/service/store/store11/storeDetail1.jpg';
import store11Detail2Webp from 'assets/service/store/store11/storeDetail2.webp';
import store11Detail2 from 'assets/service/store/store11/storeDetail2.jpg';
import store11Detail3Webp from 'assets/service/store/store11/storeDetail3.webp';
import store11Detail3 from 'assets/service/store/store11/storeDetail3.jpg';
import store11Detail4Webp from 'assets/service/store/store11/storeDetail4.webp';
import store11Detail4 from 'assets/service/store/store11/storeDetail4.jpg';

// 12. 새열린약국
import store12Webp from 'assets/service/store/store12/store12.webp';
import store12 from 'assets/service/store/store12/store12.jpg';
import store12Detail1Webp from 'assets/service/store/store12/storeDetail1.webp';
import store12Detail1 from 'assets/service/store/store12/storeDetail1.jpg';
import store12Detail2Webp from 'assets/service/store/store12/storeDetail2.webp';
import store12Detail2 from 'assets/service/store/store12/storeDetail2.jpg';
import store12Detail3Webp from 'assets/service/store/store12/storeDetail3.webp';
import store12Detail3 from 'assets/service/store/store12/storeDetail3.jpg';
import store12Detail4Webp from 'assets/service/store/store12/storeDetail4.webp';
import store12Detail4 from 'assets/service/store/store12/storeDetail4.jpg';

// 13. 제임스옵티크
import store13Webp from 'assets/service/store/store13/store13.webp';
import store13 from 'assets/service/store/store13/store13.jpg';
import store13Detail1Webp from 'assets/service/store/store13/storeDetail1.webp';
import store13Detail1 from 'assets/service/store/store13/storeDetail1.jpg';
import store13Detail2Webp from 'assets/service/store/store13/storeDetail2.webp';
import store13Detail2 from 'assets/service/store/store13/storeDetail2.jpg';
import store13Detail3Webp from 'assets/service/store/store13/storeDetail3.webp';
import store13Detail3 from 'assets/service/store/store13/storeDetail3.jpg';
import store13Detail4Webp from 'assets/service/store/store13/storeDetail4.webp';
import store13Detail4 from 'assets/service/store/store13/storeDetail4.jpg';

// 14. 뉴이(Nuer)
import store14Webp from 'assets/service/store/store14/store14.webp';
import store14 from 'assets/service/store/store14/store14.jpg';
import store14Detail1Webp from 'assets/service/store/store14/storeDetail1.webp';
import store14Detail1 from 'assets/service/store/store14/storeDetail1.jpg';
import store14Detail2Webp from 'assets/service/store/store14/storeDetail2.webp';
import store14Detail2 from 'assets/service/store/store14/storeDetail2.jpg';
import store14Detail3Webp from 'assets/service/store/store14/storeDetail3.webp';
import store14Detail3 from 'assets/service/store/store14/storeDetail3.jpg';
import store14Detail4Webp from 'assets/service/store/store14/storeDetail4.webp';
import store14Detail4 from 'assets/service/store/store14/storeDetail4.jpg';

// 15. 하나로 약국
import store15Webp from 'assets/service/store/store15/store15.webp';
import store15 from 'assets/service/store/store15/store15.jpeg';
import store15Detail1Webp from 'assets/service/store/store15/storeDetail1.webp';
import store15Detail1 from 'assets/service/store/store15/storeDetail1.jpeg';
import store15Detail2Webp from 'assets/service/store/store15/storeDetail2.webp';
import store15Detail2 from 'assets/service/store/store15/storeDetail2.jpeg';
import store15Detail3Webp from 'assets/service/store/store15/storeDetail3.webp';
import store15Detail3 from 'assets/service/store/store15/storeDetail3.jpeg';
import store15Detail4Webp from 'assets/service/store/store15/storeDetail4.webp';
import store15Detail4 from 'assets/service/store/store15/storeDetail4.jpg';

// 16. 타이틀리스트
import store16Webp from 'assets/service/store/store16/store16.webp';
import store16 from 'assets/service/store/store16/store16.jpg';
import store16Detail1Webp from 'assets/service/store/store16/storeDetail1.webp';
import store16Detail1 from 'assets/service/store/store16/storeDetail1.jpg';
import store16Detail2Webp from 'assets/service/store/store16/storeDetail2.webp';
import store16Detail2 from 'assets/service/store/store16/storeDetail2.jpg';
import store16Detail3Webp from 'assets/service/store/store16/storeDetail3.webp';
import store16Detail3 from 'assets/service/store/store16/storeDetail3.jpg';
import store16Detail4Webp from 'assets/service/store/store16/storeDetail4.webp';
import store16Detail4 from 'assets/service/store/store16/storeDetail4.jpg';

// 17. 캘러웨이
import store17Webp from 'assets/service/store/store17/store17.webp';
import store17 from 'assets/service/store/store17/store17.jpg';
import store17Detail1Webp from 'assets/service/store/store17/storeDetail1.webp';
import store17Detail1 from 'assets/service/store/store17/storeDetail1.jpg';
import store17Detail2Webp from 'assets/service/store/store17/storeDetail2.webp';
import store17Detail2 from 'assets/service/store/store17/storeDetail2.jpg';
import store17Detail3Webp from 'assets/service/store/store17/storeDetail3.webp';
import store17Detail3 from 'assets/service/store/store17/storeDetail3.jpg';
import store17Detail4Webp from 'assets/service/store/store17/storeDetail4.webp';
import store17Detail4 from 'assets/service/store/store17/storeDetail4.jpg';

// 18. 샘플라스(samplas)
import store18Webp from 'assets/service/store/store18/store18.webp';
import store18 from 'assets/service/store/store18/store18.jpg';
import store18Detail1Webp from 'assets/service/store/store18/storeDetail1.webp';
import store18Detail1 from 'assets/service/store/store18/storeDetail1.jpg';
import store18Detail2Webp from 'assets/service/store/store18/storeDetail2.webp';
import store18Detail2 from 'assets/service/store/store18/storeDetail2.jpg';
import store18Detail3Webp from 'assets/service/store/store18/storeDetail3.webp';
import store18Detail3 from 'assets/service/store/store18/storeDetail3.jpg';
import store18Detail4Webp from 'assets/service/store/store18/storeDetail4.webp';
import store18Detail4 from 'assets/service/store/store18/storeDetail4.jpg';

// 19. 프레시플러시
import store19Webp from 'assets/service/store/store19/store19.webp';
import store19 from 'assets/service/store/store19/store19.jpg';
import store19Detail1Webp from 'assets/service/store/store19/storeDetail1.webp';
import store19Detail1 from 'assets/service/store/store19/storeDetail1.jpg';
import store19Detail2Webp from 'assets/service/store/store19/storeDetail2.webp';
import store19Detail2 from 'assets/service/store/store19/storeDetail2.jpg';
import store19Detail3Webp from 'assets/service/store/store19/storeDetail3.webp';
import store19Detail3 from 'assets/service/store/store19/storeDetail3.jpg';
import store19Detail4Webp from 'assets/service/store/store19/storeDetail4.webp';
import store19Detail4 from 'assets/service/store/store19/storeDetail4.jpg';

// 19. 프레시플러시
import store20Webp from 'assets/service/store/store20/store20.webp';
import store20 from 'assets/service/store/store20/store20.jpg';
import store20Detail1Webp from 'assets/service/store/store20/storeDetail1.webp';
import store20Detail1 from 'assets/service/store/store20/storeDetail1.jpg';
import store20Detail2Webp from 'assets/service/store/store20/storeDetail2.webp';
import store20Detail2 from 'assets/service/store/store20/storeDetail2.jpg';
import store20Detail3Webp from 'assets/service/store/store20/storeDetail3.webp';
import store20Detail3 from 'assets/service/store/store20/storeDetail3.jpg';
import store20Detail4Webp from 'assets/service/store/store20/storeDetail4.webp';
import store20Detail4 from 'assets/service/store/store20/storeDetail4.jpg';

export const FilterSubLocal = [
  { id: 1, title: '전체', active: true, filter: '*' },
  { id: 2, title: '서울', active: false, filter: '서울' },
  { id: 3, title: '경기 / 인천', active: false, filter: '경기/인천' },
  { id: 4, title: '수도권 외', active: false, filter: '외' },
];

export const FilterSubSectors = [
  { id: 1, title: '전체', active: true, filter: '*' },
  { id: 2, title: '안경', active: false, filter: '안경' },
  { id: 3, title: '약국', active: false, filter: '약국' },
  { id: 4, title: '쥬얼리', active: false, filter: '쥬얼리' },
  { id: 5, title: '의류', active: false, filter: '의류' },
  { id: 6, title: '패션잡화', active: false, filter: '패션잡화' },
  { id: 7, title: '기념품', active: false, filter: '기념품' },
];

export const FranchiseeItemContent = [
  {
    id: 1,
    title: '그날의 안경',
    address: '남대문',
    sectors: '안경',
    image: checkWebpSupport(store1Webp, store1),
    alt: '그날의 안경.',
    icon: glassIcon,
    location: '서울',
    kind: '안경',
    keyword: '그날의안경 서울 남대문 안경',
    isPolicy: false,
    detail: {
      title: '그날의 안경',
      subTitle: '남대문 감성 아이웨어(eyewear)샵',
      bannerBg: checkWebpSupport(storeBg1Webp, storeBg1),
      icon: glassIcon,
      content:
        '그날의 안경원은 타르트옵티컬, 래쉬, 프레임몬타나, 하파크리스틴 등 다양한 프리미엄 아이웨어 브랜드를 만날 수 있는 감성 아이웨어(eyewear) 샵입니다. 30년 이상의 오랜 경력과 노하우를 가진 전문 안경사의 정확한 검안, 편안한 피팅으로 제작된 높은 품질의 아이웨어를 합리적인 가격으로 만날 수 있는 안경원입니다. 외국인 관광객이 필수적으로 찾는 남대문 시장 내에 위치해 있으며 회현역 5번 출구 도보 2분 이내의 거리로 접근 편의성이 높은 매장입니다.',
      address: '서울특별시 중구 남대문시장4길 22-2 그날의 안경원',
      call: '0507-1430-1828',
      imgUrl: [
        {
          img: checkWebpSupport(store1Detail3Webp, store1Detail3),
          alt: '그날의 안경 매장.',
        },
        {
          img: checkWebpSupport(store1Detail4Webp, store1Detail4),
          alt: '그날의 안경 매장.',
        },
        {
          img: checkWebpSupport(store1Detail1Webp, store1Detail1),
          alt: '그날의 안경 매장.',
        },
        {
          img: checkWebpSupport(store1Detail2Webp, store1Detail2),
          alt: '그날의 안경 매장.',
        },
        {
          img: checkWebpSupport(store1Detail3Webp, store1Detail3),
          alt: '그날의 안경 매장.',
        },
        {
          img: checkWebpSupport(store1Detail4Webp, store1Detail4),
          alt: '그날의 안경 매장.',
        },
        {
          img: checkWebpSupport(store1Detail1Webp, store1Detail1),
          alt: '그날의 안경 매장.',
        },
        {
          img: checkWebpSupport(store1Detail2Webp, store1Detail2),
          alt: '그날의 안경 매장.',
        },
        {
          img: checkWebpSupport(store1Detail3Webp, store1Detail3),
          alt: '그날의 안경 매장.',
        },
      ],
      position: { lat: 37.55914855, lng: 126.9771498 },
    },
  },
  {
    id: 2,
    title: '신들네돌',
    address: '인사동',
    sectors: '원석·보석',
    image: checkWebpSupport(store2Webp, store2),
    alt: '신들네돌.',
    icon: souvenirIcon,
    location: '서울',
    kind: '기념품',
    keyword: '신들네돌 서울 인사동 원석·보석 기념품',
    isPolicy: false,
    detail: {
      title: '신들네돌',
      subTitle: '인사동 문화의 거리 크리스탈, 수정 원석 및 악세사리 샵',
      bannerBg: checkWebpSupport(store2Webp, store2),
      icon: accessoriesIcon,
      content:
        '신들네돌은 미네랄원석, 크리스탈, 수정, 비취 등 다양한 원석과 원석을 활용한 쥬얼리 악세사리를 만날 수 있는 매장입니다. 수공예로 만들어진 퀄리티 높은 악세사리는 한국을 방문한 외국인 관광객들에게 여행의 순간을 기념하는 기념품으로 오랫동안 사랑받고 있습니다. 낙원상가, 북촌한옥마을, 창덕궁 등 외국인 관광객의 주요 여행동선에 위치해 있어 접근편의성이 높은 매장입니다.',
      address: '서울 종로구 인사동길 24 1층',
      call: '0507-1440-8873',
      imgUrl: [
        {
          img: checkWebpSupport(store2Detail3Webp, store2Detail3),
          alt: '신들네돌 매장.',
        },
        {
          img: checkWebpSupport(store2Detail4Webp, store2Detail4),
          alt: '신들네돌 매장.',
        },
        {
          img: checkWebpSupport(store2Detail1Webp, store2Detail1),
          alt: '신들네돌 매장.',
        },
        {
          img: checkWebpSupport(store2Detail2Webp, store2Detail2),
          alt: '신들네돌 매장.',
        },
        {
          img: checkWebpSupport(store2Detail3Webp, store2Detail3),
          alt: '신들네돌 매장.',
        },
        {
          img: checkWebpSupport(store2Detail4Webp, store2Detail4),
          alt: '신들네돌 매장.',
        },
        {
          img: checkWebpSupport(store2Detail1Webp, store2Detail1),
          alt: '신들네돌 매장.',
        },
        {
          img: checkWebpSupport(store2Detail2Webp, store2Detail2),
          alt: '신들네돌 매장.',
        },
        {
          img: checkWebpSupport(store2Detail3Webp, store2Detail3),
          alt: '신들네돌 매장.',
        },
      ],
      position: { lat: 37.5729236, lng: 126.9863893 },
    },
  },
  {
    id: 3,
    title: '소명약국',
    address: '이태원',
    sectors: '의약품',
    image: checkWebpSupport(store3Webp, store3),
    alt: '소명약국.',
    icon: medicineIcon,
    location: '서울',
    kind: '약국',
    keyword: '소명약국 서울 이태원 의약품',
    isPolicy: false,
    detail: {
      title: '소명약국',
      subTitle: '이태원역 인근 종합약국',
      bannerBg: checkWebpSupport(store3Webp, store3),
      icon: medicineIcon,
      content:
        '소명약국은 이태원역 인근에 위치한 종합 약국입니다. 호텔, 게스트 하우스 등 외국인 관광객 숙소 인근에 위치해 있어 접근 편의성이 높으며, 의약품 뿐 아니라 여행 중 필요한 다양한 위생 및 생활용품을 구입할 수 있는 약국입니다. 외국인 관광객들의 주요 쇼핑 품목인 한국 건강기능식품과 영양제 등도 다양하게 구비가 되어 있으며 외국어 응대가 능숙한 약사님들이 상주하고 있습니다.',
      address: '서울특별시 용산구 우사단로 43',
      call: '02-795-9954',
      imgUrl: [
        {
          img: checkWebpSupport(store3Detail3Webp, store3Detail3),
          alt: '소명약국.',
        },
        {
          img: checkWebpSupport(store3Detail4Webp, store3Detail4),
          alt: '소명약국.',
        },
        {
          img: checkWebpSupport(store3Detail1Webp, store3Detail1),
          alt: '소명약국.',
        },
        {
          img: checkWebpSupport(store3Detail2Webp, store3Detail2),
          alt: '소명약국.',
        },
        {
          img: checkWebpSupport(store3Detail3Webp, store3Detail3),
          alt: '소명약국.',
        },
        {
          img: checkWebpSupport(store3Detail4Webp, store3Detail4),
          alt: '소명약국.',
        },
        {
          img: checkWebpSupport(store3Detail1Webp, store3Detail1),
          alt: '소명약국.',
        },
        {
          img: checkWebpSupport(store3Detail2Webp, store3Detail2),
          alt: '소명약국.',
        },
        {
          img: checkWebpSupport(store3Detail3Webp, store3Detail3),
          alt: '소명약국.',
        },
      ],
      position: { lat: 37.5340946, lng: 126.9954132 },
    },
  },
  {
    id: 4,
    title: '라퐁텐',
    address: '홍대',
    sectors: '악세사리',
    image: checkWebpSupport(store4Webp, store4),
    alt: '라퐁텐.',
    icon: accessoriesIcon,
    location: '서울',
    kind: '패션잡화',
    keyword: '라퐁텐 서울 홍대 악세사리',
    isPolicy: false,
    detail: {
      title: '라퐁텐',
      subTitle: '홍대 커스텀 쥬얼리 브랜드 샵',
      bannerBg: checkWebpSupport(store4Webp, store4),
      icon: accessoriesIcon,
      content:
        '라퐁텐은 홍대 걷고싶은 거리에 위치한 커스텀 쥬얼리 브랜드 샵입니다. 심플하면서도 데일리한 디자인, 유니크한 디자인의 악세사리 등 높은 퀄리티의 다양한 악세사리를 합리적인 가격으로 구매할 수 있는 매장입니다. 홍대 걷고싶은 거리를 방문한 내국인 뿐 아니라, 외국인 관광객들에게도 많은 사랑을 받는 브랜드로 홍대를 방문한 외국인 관광객들의 방문이 많은 매장이기도 합니다.',
      address: '서울특별시 마포구 어울마당로 114 1층 매장 버스킹거리',
      call: '0507-1352-8576',
      imgUrl: [
        {
          img: checkWebpSupport(store4Detail3Webp, store4Detail3),
          alt: '라퐁텐.',
        },
        {
          img: checkWebpSupport(store4Detail4Webp, store4Detail4),
          alt: '라퐁텐.',
        },
        {
          img: checkWebpSupport(store4Detail1Webp, store4Detail1),
          alt: '라퐁텐.',
        },
        {
          img: checkWebpSupport(store4Detail2Webp, store4Detail2),
          alt: '라퐁텐.',
        },
        {
          img: checkWebpSupport(store4Detail3Webp, store4Detail3),
          alt: '라퐁텐.',
        },
        {
          img: checkWebpSupport(store4Detail4Webp, store4Detail4),
          alt: '라퐁텐.',
        },
        {
          img: checkWebpSupport(store4Detail1Webp, store4Detail1),
          alt: '라퐁텐.',
        },
        {
          img: checkWebpSupport(store4Detail2Webp, store4Detail2),
          alt: '라퐁텐.',
        },
        {
          img: checkWebpSupport(store4Detail3Webp, store4Detail3),
          alt: '라퐁텐.',
        },
      ],
      position: { lat: 37.5547987, lng: 126.9231852 },
    },
  },
  {
    id: 5,
    title: '북촌약국',
    address: '북촌',
    sectors: '의약품',
    image: checkWebpSupport(store5Webp, store5),
    alt: '북촌약국.',
    icon: medicineIcon,
    location: '서울',
    kind: '약국',
    keyword: '북촌약국 서울 북촌 의약품',
    isPolicy: false,
    detail: {
      title: '북촌약국',
      subTitle: '북촌한옥마을 종합약국',
      bannerBg: checkWebpSupport(store5Webp, store5),
      icon: medicineIcon,
      content:
        '북촌약국은 북촌 한옥마을 내에 위치한 종합 약국입니다. 북촌한옥마을, 국립현대미술관, 삼청동 등 외국인 관광객들의 관광 동선 내에 있어 외국인 관광객 방문이 많은 약국입니다. 마스크, 소독제 등의 방역 용품과 영양제, 위생 용품 등 다양한 제품을 제공하고 있으며 외국인 응대가 가능한 약사님들이 상주하고 있습니다.',
      address: '서울 종로구 율곡로3길 71 1층',
      call: '02-720-8081',
      imgUrl: [
        {
          img: checkWebpSupport(store5Detail3Webp, store5Detail3),
          alt: '북촌약국.',
        },
        {
          img: checkWebpSupport(store5Detail4Webp, store5Detail4),
          alt: '북촌약국.',
        },
        {
          img: checkWebpSupport(store5Detail1Webp, store5Detail1),
          alt: '북촌약국.',
        },
        {
          img: checkWebpSupport(store5Detail2Webp, store5Detail2),
          alt: '북촌약국.',
        },
        {
          img: checkWebpSupport(store5Detail3Webp, store5Detail3),
          alt: '북촌약국.',
        },
        {
          img: checkWebpSupport(store5Detail4Webp, store5Detail4),
          alt: '북촌약국.',
        },
        {
          img: checkWebpSupport(store5Detail1Webp, store5Detail1),
          alt: '북촌약국.',
        },
        {
          img: checkWebpSupport(store5Detail2Webp, store5Detail2),
          alt: '북촌약국.',
        },
        {
          img: checkWebpSupport(store5Detail3Webp, store5Detail3),
          alt: '북촌약국.',
        },
      ],
      position: { lat: 37.5787187, lng: 126.9820141 },
    },
  },
  {
    id: 6,
    title: '세미콜론',
    address: '명동',
    sectors: '패션잡화',
    image: checkWebpSupport(store6Webp, store6),
    alt: '세미콜론.',
    icon: fashionIcon,
    location: '서울',
    kind: '패션잡화',
    keyword: '세미콜론 서울 명동 패션잡화',
    isPolicy: false,
    detail: {
      title: '세미콜론',
      subTitle: '명동 라이프스타일 편집샵',
      bannerBg: checkWebpSupport(store6Webp, store6),
      icon: fashionIcon,
      content:
        '세미콜론은 핸드메이드 소이캔들, 디퓨저, 가방, 모자 등 다양한 패션 잡화를 판매하는 라이프 스타일 굿즈 편집샵입니다. 높은 퀄리티의 상품을 합리적인 가격에 구매할 수 있어, 선물을 구매하려는 외국인 관광객들에게 많은 사랑을 받고 있습니다. 세미콜론은 한국 여행 시, 필수로 방문해야 하는 명동 상권 내에 있으며 전철역(8번 출구)와 도보 3분 거리에 위치해 있어 많은 외국인 관광객들이 방문하는 매장입니다.',
      address: '서울 중구 명동8길 37-9 1층',
      call: '0507-1334-4817',
      imgUrl: [
        {
          img: checkWebpSupport(store6Detail3Webp, store6Detail3),
          alt: '세미콜론.',
        },
        {
          img: checkWebpSupport(store6Detail4Webp, store6Detail4),
          alt: '세미콜론.',
        },
        {
          img: checkWebpSupport(store6Detail1Webp, store6Detail1),
          alt: '세미콜론.',
        },
        {
          img: checkWebpSupport(store6Detail2Webp, store6Detail2),
          alt: '세미콜론.',
        },
        {
          img: checkWebpSupport(store6Detail3Webp, store6Detail3),
          alt: '세미콜론.',
        },
        {
          img: checkWebpSupport(store6Detail4Webp, store6Detail4),
          alt: '세미콜론.',
        },
        {
          img: checkWebpSupport(store6Detail1Webp, store6Detail1),
          alt: '세미콜론.',
        },
        {
          img: checkWebpSupport(store6Detail2Webp, store6Detail2),
          alt: '세미콜론.',
        },
        {
          img: checkWebpSupport(store6Detail3Webp, store6Detail3),
          alt: '세미콜론.',
        },
      ],
      position: { lat: 37.5621026, lng: 126.9853586 },
    },
  },
  {
    id: 7,
    title: '에덴도자기',
    address: '이태원',
    sectors: '기념품',
    image: checkWebpSupport(store7Webp, store7),
    alt: '에덴도자기.',
    icon: souvenirIcon,
    location: '서울',
    kind: '기념품',
    keyword: '에덴도자기 서울 이태원 기념품',
    isPolicy: false,
    detail: {
      title: '에덴도자기',
      subTitle: '이태원 전통 도예 악세사리 샵',
      bannerBg: checkWebpSupport(store7Webp, store7),
      icon: souvenirIcon,
      content:
        '에덴도자기는 한국의 멋을 담은 전통스러운 화병과 작은 악세사리, 그리고 대형 장식품까지 기념품으로 손색 없는 도자기를 판매하는 매장입니다. 이태원에서 꼭 가볼만한 명소로 한국관광공사에도 소개된 적이 있으며, 유니크한 기념품, 선물을 찾는 외국인 관광객에게 적합한 기념품샵입니다. 호텔, 게스트 하우스로 유명한 이태원에 위치해있어 많은 외국인 관광객들에게 한국 도자기의 아름다움을 알리고 있습니다.',
      address: '서울 용산구 이태원로 159-1',
      call: '02-793-0828',
      imgUrl: [
        {
          img: checkWebpSupport(store7Detail3Webp, store7Detail3),
          alt: '에덴도자기.',
        },
        {
          img: checkWebpSupport(store7Detail4Webp, store7Detail4),
          alt: '에덴도자기.',
        },
        {
          img: checkWebpSupport(store7Detail1Webp, store7Detail1),
          alt: '에덴도자기.',
        },
        {
          img: checkWebpSupport(store7Detail2Webp, store7Detail2),
          alt: '에덴도자기.',
        },
        {
          img: checkWebpSupport(store7Detail3Webp, store7Detail3),
          alt: '에덴도자기.',
        },
        {
          img: checkWebpSupport(store7Detail4Webp, store7Detail4),
          alt: '에덴도자기.',
        },
        {
          img: checkWebpSupport(store7Detail1Webp, store7Detail1),
          alt: '에덴도자기.',
        },
        {
          img: checkWebpSupport(store7Detail2Webp, store7Detail2),
          alt: '에덴도자기.',
        },
        {
          img: checkWebpSupport(store7Detail3Webp, store7Detail3),
          alt: '에덴도자기.',
        },
      ],
      position: { lat: 37.534443, lng: 126.9916604 },
    },
  },
  {
    id: 8,
    title: '홍익약국',
    address: '홍대',
    sectors: '의약품',
    image: checkWebpSupport(store8Webp, store8),
    alt: '홍익약국.',
    icon: medicineIcon,
    location: '서울',
    kind: '약국',
    keyword: '홍익약국 홍대 의약품',
    isPolicy: false,
    detail: {
      title: '홍익약국',
      subTitle: '홍대입구 종합약국',
      bannerBg: checkWebpSupport(store8Webp, store8),
      icon: medicineIcon,
      content:
        '홍대 홍익약국은 홍대입구역 8번 출구에에 위치한 종합 약국입니다. 홍대 젊음의 거리, 연희동 등 외국인 관광객들의 관광 동선 내에 있어 외국인 관광객 방문이 많은 약국입니다. 마스크, 소독제 등의 방역 용품, 영양제, 위생 용품 등 다양한 제품을 제공하고 있으며 외국인 응대가 가능한 약사님들이 상주하고 있습니다.',
      address: '서울 마포구 양화로18길 7 1층',
      call: '02-337-5414',
      imgUrl: [
        {
          img: checkWebpSupport(store8Detail3Webp, store8Detail3),
          alt: '홍익약국.',
        },
        {
          img: checkWebpSupport(store8Detail4Webp, store8Detail4),
          alt: '홍익약국.',
        },
        {
          img: checkWebpSupport(store8Detail1Webp, store8Detail1),
          alt: '홍익약국.',
        },
        {
          img: checkWebpSupport(store8Detail2Webp, store8Detail2),
          alt: '홍익약국.',
        },
        {
          img: checkWebpSupport(store8Detail3Webp, store8Detail3),
          alt: '홍익약국.',
        },
        {
          img: checkWebpSupport(store8Detail4Webp, store8Detail4),
          alt: '홍익약국.',
        },
        {
          img: checkWebpSupport(store8Detail1Webp, store8Detail1),
          alt: '홍익약국.',
        },
        {
          img: checkWebpSupport(store8Detail2Webp, store8Detail2),
          alt: '홍익약국.',
        },
        {
          img: checkWebpSupport(store8Detail3Webp, store8Detail3),
          alt: '홍익약국.',
        },
      ],
      position: { lat: 37.5568174, lng: 126.9245481 },
    },
  },
  {
    id: 9,
    title: '별꼴상회',
    address: '홍대',
    sectors: '성인용품',
    image: checkWebpSupport(store9Webp, store9),
    alt: '별꼴상회.',
    icon: souvenirIcon,
    location: '서울',
    kind: '기념품',
    keyword: '별꼴상회 서울 홍대 성인용품 기념품',
    isPolicy: false,
    detail: {
      title: '별꼴상회',
      subTitle: '홍대·연희동 이색 용품샵',
      bannerBg: checkWebpSupport(store9Webp, store9),
      icon: souvenirIcon,
      content:
        '별꼴상회는 홍대와 연희동 사이에 위치한 성인용품(ADULT) 샵으로 이색적인 여행 장소를 찾는 외국인 관광객들에게 인기가 많은 매장입니다. 1, 2층으로 나뉘어진 대형 매장으로 성인용품과 더불어 향수, 마스크 팩 등 뷰티 용품도 함께 판매를 하고 있습니다. 재밌는 볼거리와 상품들로 홍대 혹은 연희동, 신촌을 방문한 외국인 관광객들에게 꼭 들러야할 핫플레이스로 알려져 있습니다.',
      address: '서울 마포구 양화로 177-7 지1층~2층',
      call: '0507-1479-5850',
      imgUrl: [
        {
          img: checkWebpSupport(store9Detail3Webp, store9Detail3),
          alt: '별꼴상회.',
        },
        {
          img: checkWebpSupport(store9Detail4Webp, store9Detail4),
          alt: '별꼴상회.',
        },
        {
          img: checkWebpSupport(store9Detail1Webp, store9Detail1),
          alt: '별꼴상회.',
        },
        {
          img: checkWebpSupport(store9Detail2Webp, store9Detail2),
          alt: '별꼴상회.',
        },
        {
          img: checkWebpSupport(store9Detail3Webp, store9Detail3),
          alt: '별꼴상회.',
        },
        {
          img: checkWebpSupport(store9Detail4Webp, store9Detail4),
          alt: '별꼴상회.',
        },
        {
          img: checkWebpSupport(store9Detail1Webp, store9Detail1),
          alt: '별꼴상회.',
        },
        {
          img: checkWebpSupport(store9Detail2Webp, store9Detail2),
          alt: '별꼴상회.',
        },
        {
          img: checkWebpSupport(store9Detail3Webp, store9Detail3),
          alt: '별꼴상회.',
        },
      ],
      position: { lat: 37.557128, lng: 126.923879 },
    },
  },
  {
    id: 10,
    title: '스탠다드 안경원',
    address: '이수',
    sectors: '안경',
    image: checkWebpSupport(store10Webp, store10),
    alt: '스탠다드 안경원.',
    icon: glassIcon,
    location: '서울',
    kind: '안경',
    keyword: '스탠다드안경원 서울 이수 안경',
    isPolicy: false,
    detail: {
      title: '스탠다드 안경원',
      subTitle: '이수 아이웨어 피팅 전문샵',
      bannerBg: checkWebpSupport(store10Webp, store10),
      icon: glassIcon,
      content:
        '스탠다드 안경원은 이수역에 위치한 프리미엄 아이웨어 브랜드 샵입니다. 얼굴 특징을 고려한 FRAME FIT, 생활습관에 따라 달라지는 시력 검사, 왜곡이 없는 조제가공 등전문성을 보유한 피팅 전문샵으로 알려져 있습니다. 또한, 크롬하츠가메만넨, 타츠야마수나가, 텐아이반마르쿠스티 등 프리미엄 브랜드를 취급하는 매장으로 외국인 관광객들에게 높은 퀄리티의 아이웨어를 제공하고 있습니다.',
      address: '서울특별시 서초구 동작대로 134 1층',
      call: '02-533-6262',
      imgUrl: [
        {
          img: checkWebpSupport(store10Detail3Webp, store10Detail3),
          alt: '스탠다드 안경원.',
        },
        {
          img: checkWebpSupport(store10Detail4Webp, store10Detail4),
          alt: '스탠다드 안경원.',
        },
        {
          img: checkWebpSupport(store10Detail1Webp, store10Detail1),
          alt: '스탠다드 안경원.',
        },
        {
          img: checkWebpSupport(store10Detail2Webp, store10Detail2),
          alt: '스탠다드 안경원.',
        },
        {
          img: checkWebpSupport(store10Detail3Webp, store10Detail3),
          alt: '스탠다드 안경원.',
        },
        {
          img: checkWebpSupport(store10Detail4Webp, store10Detail4),
          alt: '스탠다드 안경원.',
        },
        {
          img: checkWebpSupport(store10Detail1Webp, store10Detail1),
          alt: '스탠다드 안경원.',
        },
        {
          img: checkWebpSupport(store10Detail2Webp, store10Detail2),
          alt: '스탠다드 안경원.',
        },
        {
          img: checkWebpSupport(store10Detail3Webp, store10Detail3),
          alt: '스탠다드 안경원.',
        },
      ],
      position: { lat: 37.4885724!, lng: 126.9827672 },
    },
  },
  {
    id: 11,
    title: '씨샵 홍대 플래그쉽',
    address: '홍대',
    sectors: '안경',
    image: checkWebpSupport(store11Webp, store11),
    alt: '씨샵 홍대 플래그쉽.',
    icon: glassIcon,
    location: '서울',
    kind: '안경',
    keyword: '씨샵홍대플래그쉽 서울 홍대 안경',
    isPolicy: false,
    detail: {
      title: '씨샵 홍대 플래그쉽',
      subTitle: '홍대 프리미엄 아이웨어 브랜드 샵',
      bannerBg: checkWebpSupport(store11Webp, store11),
      icon: glassIcon,
      content:
        '씨샵 홍대 플래그쉽은 홍대입구에 위치한 프리미엄 아이웨어 브랜드 샵입니다. 프리미엄 아이웨어 브랜드로 알려져 있는 CED 인터내셔널의 4번째 아이웨어 플래그쉽으로 패션·라이프스타일 아이웨어 브랜드 알펜(ALPEN)과 아메리칸 빈티지 아이웨어 브랜드 모스콧(MOSCOTT)를 제공하고 있습니다. 전 프레임 고유 가공 레퍼런스의 높은 완성도, 스페셜 케어 프로그램을 통한 파손 및 스크래치 보증, 양안시 전문가들의 정확한 검안 등의 프리미엄 서비스 또한 제공하고 있습니다.',
      address: '서울 마포구 어울마당로 50 1층',
      call: '0507-1493-5546',
      imgUrl: [
        {
          img: checkWebpSupport(store11Detail3Webp, store11Detail3),
          alt: '씨샵 홍대 플래그쉽.',
        },
        {
          img: checkWebpSupport(store11Detail4Webp, store11Detail4),
          alt: '씨샵 홍대 플래그쉽.',
        },
        {
          img: checkWebpSupport(store11Detail1Webp, store11Detail1),
          alt: '씨샵 홍대 플래그쉽.',
        },
        {
          img: checkWebpSupport(store11Detail2Webp, store11Detail2),
          alt: '씨샵 홍대 플래그쉽.',
        },
        {
          img: checkWebpSupport(store11Detail3Webp, store11Detail3),
          alt: '씨샵 홍대 플래그쉽.',
        },
        {
          img: checkWebpSupport(store11Detail4Webp, store11Detail4),
          alt: '씨샵 홍대 플래그쉽.',
        },
        {
          img: checkWebpSupport(store11Detail1Webp, store11Detail1),
          alt: '씨샵 홍대 플래그쉽.',
        },
        {
          img: checkWebpSupport(store11Detail2Webp, store11Detail2),
          alt: '씨샵 홍대 플래그쉽.',
        },
        {
          img: checkWebpSupport(store11Detail3Webp, store11Detail3),
          alt: '씨샵 홍대 플래그쉽.',
        },
      ],
      position: { lat: 37.5494027, lng: 126.9210459 },
    },
  },
  {
    id: 12,
    title: '새열린약국',
    address: '영등포',
    sectors: '의약품',
    image: checkWebpSupport(store12Webp, store12),
    alt: '새열린약국.',
    icon: medicineIcon,
    location: '서울',
    kind: '약국',
    keyword: '새열린약국 서울 영등포 의약품',
    isPolicy: false,
    detail: {
      title: '새열린약국',
      subTitle: '명동·을지로 종합 약국',
      bannerBg: checkWebpSupport(store12Webp, store12),
      icon: medicineIcon,
      content:
        '새열린약국은 을지로입구역과 명동 번화가 사이에 위치한 종합 약국입니다. 명동 쇼핑 거리, 명동 성당, 서울 시청 등 외국인 관광객들의 관광 동선 내에 있어 외국인 관광객 방문이 많은 약국입니다. 마스크, 소독제 등의 방역 용품, 영양제, 위생 용품 등 다양한 제품을 제공하고 있으며 외국인 응대가 가능한 약사님들이 상주하고 있습니다.',
      address: '서울 중구 남대문로 92 한국전력서울본부 별관 2층',
      call: '02-778-7188',
      imgUrl: [
        {
          img: checkWebpSupport(store12Detail3Webp, store12Detail3),
          alt: '새열린약국.',
        },
        {
          img: checkWebpSupport(store12Detail4Webp, store12Detail4),
          alt: '새열린약국.',
        },
        {
          img: checkWebpSupport(store12Detail1Webp, store12Detail1),
          alt: '새열린약국.',
        },
        {
          img: checkWebpSupport(store12Detail2Webp, store12Detail2),
          alt: '새열린약국.',
        },
        {
          img: checkWebpSupport(store12Detail3Webp, store12Detail3),
          alt: '새열린약국.',
        },
        {
          img: checkWebpSupport(store12Detail4Webp, store12Detail4),
          alt: '새열린약국.',
        },
        {
          img: checkWebpSupport(store12Detail1Webp, store12Detail1),
          alt: '새열린약국.',
        },
        {
          img: checkWebpSupport(store12Detail2Webp, store12Detail2),
          alt: '새열린약국.',
        },
        {
          img: checkWebpSupport(store12Detail3Webp, store12Detail3),
          alt: '새열린약국.',
        },
      ],
      position: { lat: 37.5634096, lng: 126.9837369 },
    },
  },
  {
    id: 13,
    title: '제임스옵티크',
    address: '부평',
    sectors: '안경',
    image: checkWebpSupport(store13Webp, store13),
    alt: '제임스옵티크.',
    icon: glassIcon,
    location: '경기/인천',
    kind: '안경',
    keyword: '제임스옵티크 경기/인천 부평 안경',
    isPolicy: false,
    detail: {
      title: '제임스옵티크',
      subTitle: '부평 하우스 브랜드 아이웨어 전문 샵',
      bannerBg: checkWebpSupport(store13Webp, store13),
      icon: glassIcon,
      content:
        '제임스옵티크는 부평역 인근에 위치한 하우스 브랜드 전문 아이웨어 브랜드 샵입니다. 국내 브랜드 젠틀몬스터, 그라픽플라스틱, 마스카, 래쉬, 어크루, 페이크미 등의 아이웨어를 만날 수 있으며 모스콧, 가메만넨, 안네발렌틴 등의 수입 브랜드도 제공하고 있습니다. 부평 번화가, 지하상가에 방문한 외국인 관광객들에게 다양한 브랜드의 안경과 높은 품질의 서비스를 제공하고 있습니다.',
      address: '인천 부평구 시장로 13-1 제임스옵티크',
      call: '0507-1488-8977',
      imgUrl: [
        {
          img: checkWebpSupport(store13Detail3Webp, store13Detail3),
          alt: '제임스옵티크.',
        },
        {
          img: checkWebpSupport(store13Detail4Webp, store13Detail4),
          alt: '제임스옵티크.',
        },
        {
          img: checkWebpSupport(store13Detail1Webp, store13Detail1),
          alt: '제임스옵티크.',
        },
        {
          img: checkWebpSupport(store13Detail2Webp, store13Detail2),
          alt: '제임스옵티크.',
        },
        {
          img: checkWebpSupport(store13Detail3Webp, store13Detail3),
          alt: '제임스옵티크.',
        },
        {
          img: checkWebpSupport(store13Detail4Webp, store13Detail4),
          alt: '제임스옵티크.',
        },
        {
          img: checkWebpSupport(store13Detail1Webp, store13Detail1),
          alt: '제임스옵티크.',
        },
        {
          img: checkWebpSupport(store13Detail2Webp, store13Detail2),
          alt: '제임스옵티크.',
        },
        {
          img: checkWebpSupport(store13Detail3Webp, store13Detail3),
          alt: '제임스옵티크.',
        },
      ],
      position: { lat: 37.4925526, lng: 126.7244856 },
    },
  },
  {
    id: 14,
    title: '뉴이(Nuer)',
    address: '종로',
    sectors: '패션잡화',
    image: checkWebpSupport(store14Webp, store14),
    alt: '뉴이(Nuer).',
    icon: fashionIcon,
    location: '서울',
    kind: '패션잡화',
    keyword: '뉴이(Nuer) 서울 종로 패션잡화',
    isPolicy: false,
    detail: {
      title: '뉴이(Nuer)',
      subTitle: '디자이너 브랜드 뉴이(Nuer) 쇼룸',
      bannerBg: checkWebpSupport(store14Webp, store14),
      icon: fashionIcon,
      content:
        '뉴이(Nuer)는 예약제로 운영되고 있는 디자이너 브랜드의 오프라인 쇼룸으로 창덕궁 인근에 위치해 있습니다. 고급스러운 디자인, 높은 퀄리티의 품질의 제품으로 최근 젊은 연령층 사이에서 입소문을 타고 있는 디자이너 브랜드입니다. 미니 숄더, 크로스 백 등 다양한 제품 라인과 뉴이만의의 색감, 합리적인 가격대로 쇼룸에 방문하는 외국인 관광객들에게도 많은 사랑을 받고 있습니다.',
      address: '서울 종로구 창덕궁길 100 1층 nuer',
      call: '010-8833-7785',
      imgUrl: [
        {
          img: checkWebpSupport(store14Detail3Webp, store14Detail3),
          alt: '뉴이(Nuer).',
        },
        {
          img: checkWebpSupport(store14Detail4Webp, store14Detail4),
          alt: '뉴이(Nuer).',
        },
        {
          img: checkWebpSupport(store14Detail1Webp, store14Detail1),
          alt: '뉴이(Nuer).',
        },
        {
          img: checkWebpSupport(store14Detail2Webp, store14Detail2),
          alt: '뉴이(Nuer).',
        },
        {
          img: checkWebpSupport(store14Detail3Webp, store14Detail3),
          alt: '뉴이(Nuer).',
        },
        {
          img: checkWebpSupport(store14Detail4Webp, store14Detail4),
          alt: '뉴이(Nuer).',
        },
        {
          img: checkWebpSupport(store14Detail1Webp, store14Detail1),
          alt: '뉴이(Nuer).',
        },
        {
          img: checkWebpSupport(store14Detail2Webp, store14Detail2),
          alt: '뉴이(Nuer).',
        },
        {
          img: checkWebpSupport(store14Detail3Webp, store14Detail3),
          alt: '뉴이(Nuer).',
        },
      ],
      position: { lat: 37.5818682, lng: 126.9895093 },
    },
  },
  {
    id: 15,
    title: '하나로약국',
    address: '을지로',
    sectors: '의약품',
    image: checkWebpSupport(store15Webp, store15),
    alt: '하나로약국.',
    icon: medicineIcon,
    location: '서울',
    kind: '약국',
    keyword: '하나로약국 서울 을지로 영등포 의약품',
    isPolicy: false,
    detail: {
      title: '하나로약국',
      subTitle: '영등포시장 종합 약국',
      bannerBg: checkWebpSupport(store15Webp, store15),
      icon: medicineIcon,
      content:
        '하나로약국은 영등포역과 영등포시장역 사이에 위치한 종합 약국입니다. 영등포 타임스퀘어, 영등포시장, 영등포 번화가 등 외국인 관광객들의 관광 동선 내에 있어 외국인 관광객 방문이 많은 약국입니다. 마스크, 소독제 등의 방역 용품, 영양제, 위생 용품 등 다양한 제품을 제공하고 있으며 외국인 응대가 가능한 약사님들이 상주하고 있습니다.',
      address: '서울 영등포구 영등포로 208-1',
      call: '02-2679-9696',
      imgUrl: [
        {
          img: checkWebpSupport(store15Detail3Webp, store15Detail3),
          alt: '하나로약국.',
        },
        {
          img: checkWebpSupport(store15Detail4Webp, store15Detail4),
          alt: '하나로약국.',
        },
        {
          img: checkWebpSupport(store15Detail1Webp, store15Detail1),
          alt: '하나로약국.',
        },
        {
          img: checkWebpSupport(store15Detail2Webp, store15Detail2),
          alt: '하나로약국.',
        },
        {
          img: checkWebpSupport(store15Detail3Webp, store15Detail3),
          alt: '하나로약국.',
        },
        {
          img: checkWebpSupport(store15Detail4Webp, store15Detail4),
          alt: '하나로약국.',
        },
        {
          img: checkWebpSupport(store15Detail1Webp, store15Detail1),
          alt: '하나로약국.',
        },
        {
          img: checkWebpSupport(store15Detail2Webp, store15Detail2),
          alt: '하나로약국.',
        },
        {
          img: checkWebpSupport(store15Detail3Webp, store15Detail3),
          alt: '하나로약국.',
        },
      ],
      position: { lat: 37.5196801, lng: 126.9044576 },
    },
  },
  {
    id: 16,
    title: '타이틀리스트',
    address: '의왕',
    sectors: '골프용품',
    image: checkWebpSupport(store16Webp, store16),
    alt: '타이틀리스트.',
    icon: golfIcon,
    location: '경기/인천',
    kind: '패션잡화',
    keyword: '타이틀리스트 경기/인천 의왕 골프용품 패션잡화',
    isPolicy: false,
    detail: {
      title: '타이틀리스트',
      subTitle: '의왕 타임빌라스 프리미엄 골프 브랜드샵',
      bannerBg: checkWebpSupport(store16Webp, store16),
      icon: golfIcon,
      content:
        '타이틀리스트 롯데프리미엄아울렛 점은 외국인 관광객 방문이 많은 의왕 롯데프리미엄아울렛 타임빌라스점에 입점해 있는 매장입니다. 글로벌 골프 브랜드로 골프볼, 클럽, 기어와 어패를 등 최고의 품질을과 퍼포먼스를 가진 골프 용품을 제공하고 있습니다. 용품 판매 뿐 아니라, 오랜 피팅 노하우를 가진 전문 피터의 ‘퍼포먼스 피팅’ 서비스를 제공하여 최적의 클럽을 찾아드리는 프리미엄 서비스도 제공하고 있습니다.',
      address: '경기 의왕시 바라산로 1',
      call: '031-329-4018',
      imgUrl: [
        {
          img: checkWebpSupport(store16Detail3Webp, store16Detail3),
          alt: '타이틀리스트.',
        },
        {
          img: checkWebpSupport(store16Detail4Webp, store16Detail4),
          alt: '타이틀리스트.',
        },
        {
          img: checkWebpSupport(store16Detail1Webp, store16Detail1),
          alt: '타이틀리스트.',
        },
        {
          img: checkWebpSupport(store16Detail2Webp, store16Detail2),
          alt: '타이틀리스트.',
        },
        {
          img: checkWebpSupport(store16Detail3Webp, store16Detail3),
          alt: '타이틀리스트.',
        },
        {
          img: checkWebpSupport(store16Detail4Webp, store16Detail4),
          alt: '타이틀리스트.',
        },
        {
          img: checkWebpSupport(store16Detail1Webp, store16Detail1),
          alt: '타이틀리스트.',
        },
        {
          img: checkWebpSupport(store16Detail2Webp, store16Detail2),
          alt: '타이틀리스트.',
        },
        {
          img: checkWebpSupport(store16Detail3Webp, store16Detail3),
          alt: '타이틀리스트.',
        },
      ],
      position: { lat: 37.3758616, lng: 127.0088697 },
    },
  },
  {
    id: 17,
    title: '캘러웨이',
    address: '의왕',
    sectors: '골프용품',
    image: checkWebpSupport(store17Webp, store17),
    alt: '캘러웨이.',
    icon: golfIcon,
    location: '경기/인천',
    kind: '패션잡화',
    keyword: '캘러웨이 경기/인천 의왕 골프용품 패션잡화',
    isPolicy: false,
    detail: {
      title: '캘러웨이',
      subTitle: '의왕 타임빌라스 프리미엄 골프 웨어 브랜드샵',
      bannerBg: checkWebpSupport(store17Webp, store17),
      icon: golfIcon,
      content:
        '캘러웨이 롯데프리미엄아울렛 점은 외국인 관광객 방문이 많은 의왕 롯데프리미엄아울렛 타임빌라스점에 입점해 있는 매장입니다. 캘러웨이 골프는 1982년 설립되었으며, 현재 세계 최대의 프리미엄 고성능 골프 제품 제조업체로 알려져 있습니다. 전 세계 110개 이상의 국가에서 골프 의류, 신발 및 클럽 등이 판매 되고 있으며 그러한 인지도를 바탕으로 최근 쇼핑관광지로 자리매김하고 있는 타임빌라스에 방문한 외국인 관광객들에게도 인기를 끌고 있습니다.',
      address: '경기 의왕시 바라산로 1',
      call: '0507-1482-4010',
      imgUrl: [
        {
          img: checkWebpSupport(store17Detail3Webp, store17Detail3),
          alt: '캘러웨이.',
        },
        {
          img: checkWebpSupport(store17Detail4Webp, store17Detail4),
          alt: '캘러웨이.',
        },
        {
          img: checkWebpSupport(store17Detail1Webp, store17Detail1),
          alt: '캘러웨이.',
        },
        {
          img: checkWebpSupport(store17Detail2Webp, store17Detail2),
          alt: '캘러웨이.',
        },
        {
          img: checkWebpSupport(store17Detail3Webp, store17Detail3),
          alt: '캘러웨이.',
        },
        {
          img: checkWebpSupport(store17Detail4Webp, store17Detail4),
          alt: '캘러웨이.',
        },
        {
          img: checkWebpSupport(store17Detail1Webp, store17Detail1),
          alt: '캘러웨이.',
        },
        {
          img: checkWebpSupport(store17Detail2Webp, store17Detail2),
          alt: '캘러웨이.',
        },
        {
          img: checkWebpSupport(store17Detail3Webp, store17Detail3),
          alt: '캘러웨이.',
        },
      ],
      position: { lat: 37.3758616, lng: 127.0088697 },
    },
  },
  {
    id: 18,
    title: '샘플라스(samplas)',
    address: '홍대',
    sectors: '의류',
    image: checkWebpSupport(store18Webp, store18),
    alt: '샘플라스(samplas).',
    icon: clothingIcon,
    location: '서울',
    kind: '의류',
    keyword: '샘플라스(samplas) 서울 홍대 의류',
    isPolicy: false,
    detail: {
      title: '샘플라스(samplas)',
      subTitle: '홍대입구·합정 프리미엄 편집샵',
      bannerBg: checkWebpSupport(store18Webp, store18),
      icon: clothingIcon,
      content:
        '샘플라스(samplas)는 홍대입구와 합정역 사이에 위치한 패션 브랜드 기반 편집매장&쇼룸 입니다. SURGERY, Meantime, UMAMAIISM 등 다양한 디자이너 브랜드를 직접 만날 수 있는 큐레이팅 편집샵으로 유니크한 아이템을 제공하고 있습니다. 패션 쇼핑 거리로 익히 알려진 홍대 거리에 위치해 있어, 독특한 패션 아이템을 찾는 외국인 관광객들에게 많은 사랑을 받고 있습니다.',
      address: '서울 마포구 독막로5길 23 2F SAMPLAS',
      call: '0507-1422-8591',
      imgUrl: [
        {
          img: checkWebpSupport(store18Detail1Webp, store18Detail1),
          alt: '샘플라스(samplas).',
        },
        {
          img: checkWebpSupport(store18Detail2Webp, store18Detail2),
          alt: '샘플라스(samplas).',
        },
        {
          img: checkWebpSupport(store18Detail3Webp, store18Detail3),
          alt: '샘플라스(samplas).',
        },
        {
          img: checkWebpSupport(store18Detail4Webp, store18Detail4),
          alt: '샘플라스(samplas).',
        },
      ],
      position: { lat: 37.5490846, lng: 126.9181851 },
    },
  },
  {
    id: 19,
    title: '프레시플러시',
    address: '홍대',
    sectors: '반려동물용품',
    image: checkWebpSupport(store19Webp, store19),
    alt: '프레시플러시.',
    icon: souvenirIcon,
    location: '서울',
    kind: '패션잡화',
    keyword: '프레시플러시 서울 홍대 반려동물용품 패션잡화',
    isPolicy: false,
    detail: {
      title: '프레시플러시',
      subTitle: '홍대입구 프리미엄 반려동물 용품샵',
      bannerBg: checkWebpSupport(store19Webp, store19),
      icon: souvenirIcon,
      content:
        '프레시플러시(FRESH PLUSH)는 홍대입구에 위치한 프리미엄 반려동물 용품샵입니다. 그로서리 마켓(Grocery Market)을 컨셉으로 시선을 끄는 반려동물 장난감으로 국내 뿐 아니라 외국인 관광객들에게도 인기를 끌고 있습니다. 소스, 과일 등 실제 식품과 동일할 정도의 높은 퀄리티를 가진 반려동물 장난감과 감각적이고 이국적인 매장 인테리어로 홍대입구의 핫플레이스가 되어가고 있습니다.',
      address: '서울 마포구 와우산로29다길 13 3층 프레시플러시',
      call: '070-8773-8479',
      imgUrl: [
        {
          img: checkWebpSupport(store19Detail1Webp, store19Detail1),
          alt: '프레시플러시.',
        },
        {
          img: checkWebpSupport(store19Detail2Webp, store19Detail2),
          alt: '프레시플러시.',
        },
        {
          img: checkWebpSupport(store19Detail3Webp, store19Detail3),
          alt: '프레시플러시.',
        },
        {
          img: checkWebpSupport(store19Detail4Webp, store19Detail4),
          alt: '프레시플러시.',
        },
      ],
      position: { lat: 37.5548316, lng: 126.9276983 },
    },
  },
  {
    id: 20,
    title: '슈올즈',
    address: '남대문',
    sectors: '패션잡화',
    image: checkWebpSupport(store20Webp, store20),
    alt: '슈올즈.',
    icon: fashionIcon,
    location: '서울',
    kind: '패션잡화',
    keyword: '슈올즈 서울 남대문 패션잡화',
    isPolicy: false,
    detail: {
      title: '프레시플러시',
      subTitle: '남대문시장 기능성 신발 전문샵',
      bannerBg: checkWebpSupport(store20Webp, store20),
      icon: fashionIcon,
      content:
        '슈올즈 남대문시장점은 외국인 관광객 방문이 많은 남대문 시장에 위치해 있습니다. 슈올즈는 국내 최대 기능성 신발 프랜차이즈로 다양한 특허를 보유, 특히 2022년 독일 발명전시회에서 특별상 금상을 수상한 기술력을 가지고 있습니다. 오래 걸어도 피곤하지 않고, 허리와 무릎 건강에 좋은 신발을 매장에서 직접 신어볼 수 있으며 남대문 시장을 방문한 외국인 관광객들에게도 합리적인 가격의 편한 신발로 입소문을 타고 있습니다.',
      address: '서울 중구 남대문시장4길 9 대도d동 외향6호',
      call: '0507-1382-6564',
      imgUrl: [
        {
          img: checkWebpSupport(store20Detail1Webp, store20Detail1),
          alt: '슈올즈.',
        },
        {
          img: checkWebpSupport(store20Detail2Webp, store20Detail2),
          alt: '슈올즈.',
        },
        {
          img: checkWebpSupport(store20Detail3Webp, store20Detail3),
          alt: '슈올즈.',
        },
        {
          img: checkWebpSupport(store20Detail4Webp, store20Detail4),
          alt: '슈올즈.',
        },
      ],
      position: { lat: 37.5590159, lng: 126.9775489 },
    },
  },
];
