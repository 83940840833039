import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import vidoFile from 'assets/videos/promotion_video.mp4';

function VideoSection() {
  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    let observer: any;
    if (videoRef.current) {
      observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            videoRef?.current?.play();
            observer.disconnect();
          }
        },
        {
          threshold: 1,
        }
      );
    }
    observer && observer.observe(videoRef.current);
    return () => observer.disconnect();
  }, [videoRef]);
  return (
    <Wrapper>
      <Container>
        <Title>빠르고 간편한 사용법!</Title>
        <SubTitle>
          <Bold>30초 영상</Bold>으로 확인해보세요
        </SubTitle>
        <Video
          preload='metadata'
          muted
          ref={videoRef}
          playsInline
          controls
          loop
        >
          <source src={vidoFile} type='video/mp4' />
        </Video>
      </Container>
    </Wrapper>
  );
}
const Wrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1190px;
  width: 100%;
  margin: 0 auto;
  padding: 6.75rem 7.75rem 6.25rem;

  @media ${(props) => props.theme.tablet} {
    padding: 3.75rem 1.25rem 6.57rem;
  }
  @media ${(props) => props.theme.mobile} {
    flex-direction: column;
    background: linear-gradient(180deg, #f4f8ff -5.96%, #e3edff 62.21%);
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
const Title = styled.h2`
  font-size: 1.31rem;
  margin-bottom: 0.44rem;
`;
const SubTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 2.5rem;
  @media ${(props) => props.theme.mobile} {
    margin-bottom: 2rem;
  }
`;
const Bold = styled.strong`
  font-weight: 700;
`;
const Video = styled.video<{ ref: any }>`
  width: 100%;
  border-radius: 0.75rem;
  box-shadow: 0px 0px 50px 0px #d8d8d8;
`;
export default VideoSection;
