import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Footer from 'components/_common/Footer';
import ScrollToTop from 'components/_common/ScrollToTop';
import Application from 'pages/Application';
import Faq from 'pages/Faq';
import Home from 'pages/Home';
import NotFound from 'pages/NotFound';
import Refund from 'pages/Refund';
import Trial from 'pages/Trial';
import RefundMedical from 'components/Refund/RefundMedical';
import TaxRefund from 'components/Refund/TaxRefund';
import Service from 'pages/Service';
import Franchisee from 'components/Service/Franchisee';
import Policy from 'components/Service/Policy';
import FranchiseeDetail from 'components/Service/FranchiseeDetail';
import PolicyDetail from 'components/Service/PolicyDetail';
import Layout from 'components/_common/Layout';
import GlobalPayment from 'pages/GlobalPayment';
import ApplicationComplete from 'pages/ApplicationComplete';
import ReactQuill from 'pages/ReactQuill';

function RouterContainer() {
  return (
    <Router>
      <Layout>
        <ScrollToTop />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/1' element={<Home />} />
          <Route path='/tf' element={<Home />} />
          <Route path='/faq' element={<Faq />} />
          <Route path='/application' element={<Application />} />
          <Route path='/reactQuill' element={<ReactQuill />} />
          <Route
            path='/application/complete'
            element={<ApplicationComplete />}
          />
          <Route path='/refund' element={<Refund />}>
            <Route index element={<TaxRefund />} />
            <Route path='medical' element={<RefundMedical />} />
            <Route path='global-payment' element={<GlobalPayment />} />
          </Route>
          <Route path='/service' element={<Service />}>
            <Route index element={<Franchisee />} />
            <Route path='policy' element={<Policy />} />
            <Route path='franchisee/detail' element={<FranchiseeDetail />} />
            <Route path='policy/detail' element={<PolicyDetail />} />
          </Route>
          <Route path='/trial' element={<Trial />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
        <Footer />
      </Layout>
    </Router>
  );
}

export default RouterContainer;
