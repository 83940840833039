import { isMobile } from 'react-device-detect';
import { ReadDummyClass } from './_common/readMethodClass';

interface IQnaContentItem {
  subTitle: string;
  subContent?: string[];
}

export interface IQnaItemProps {
  id: number;
  title: string;
  height: number;
  content: IQnaContentItem[];
}

/**
 * Qna page dummy class list
 * @class DummyQnaQuestion
 */

export class DummyQnaQuestion extends ReadDummyClass<IQnaItemProps> {
  private data: Array<IQnaItemProps>;

  constructor() {
    super();
    this.data = [
      {
        id: 1,
        title: '사후면세(TAX FREE)가 무엇인가요?',
        height: isMobile ? 125 : 100,
        content: [
          {
            subTitle:
              '외국인 관광객이 사후면세판매장으로 지정받은 매장에서 상품을 구매 시, 부가세 부분만큼의 할인을 제공하는 국가 정책입니다.',
          },
        ],
      },
      {
        id: 2,
        title: '즉시 환급이 무엇인가요?',
        height: 100,
        content: [
          {
            subTitle:
              '매장에서 물품 구매 시, 물품에 포함된 부가세액을 즉시 차감하여 결제하는 제도입니다.',
          },
        ],
      },
      {
        id: 3,
        title: '환급창구 운영사는 무엇인가요?',
        height: 100,
        content: [
          {
            subTitle:
              '각 지방국세청의 허가를 득해야만, 영위할 수 있는 사업으로, 환급 절차에 대한 진행을 대행해 주는 사업자입니다.',
          },
        ],
      },
      {
        id: 4,
        title: '사후면세 환급대상자는 어떻게 구분되나요?',
        height: 175,
        content: [
          {
            subTitle: '대상자',
            subContent: [
              '대한민국 내에서 무소득자',
              '외국인 관광객(국내 체류 기간 6개월 미만)',
              '해외교포(2년 이상 해외 거주자, 국내 체류 기간 3개월 미만)',
            ],
          },
        ],
      },
      {
        id: 5,
        title: '부가가치세 지급은 어떻게 해야 하나요?',
        height: isMobile ? 150 : 125,
        content: [
          {
            subTitle:
              '면세판매 사업자는 분기별 부가가치세 신고 기간에 정산내역을 첨부하여 관할세무서에 부가가치세 영세율로 신고하기 때문에 부가세를 지불\n하지 않습니다.',
          },
        ],
      },
      {
        id: 6,
        title: '사후면세판매장 등록은 어떻게 하나요?',
        height: isMobile ? 300 : 250,
        content: [
          {
            subTitle: '오프라인',
            subContent: [
              "관할세무서에 '외국인 관광객 면세판매장 지정' 신청서를 접수합니다.",
            ],
          },
          {
            subTitle: '온라인',
            subContent: [
              '홈택스_신청/제출 - 일반 세무서류 신청 - 면세판매 검색(민원 명 찾기) - 면세판매장 지정 신청',
            ],
          },
          {
            subTitle: '위 방법 또는 우편으로도 진행이 가능합니다.',
          },
        ],
      },
      {
        id: 7,
        title: 'KTP 가맹신청은 어떻게 하나요?',
        height: 150,
        content: [
          {
            subTitle:
              'KTP 홈페이지에 "가맹점 신청"을 통해 진행이 가능하며, 이메일 신청도 가능합니다.\ne-mail: ktp@ktaxpay.com',
          },
        ],
      },
      {
        id: 9,
        title: 'KTP 계약 후 필요한 기기는 없나요?',
        height: 100,
        content: [
          {
            subTitle:
              'KTP는 스마트폰 App만 있으면 되기에, App 이외에 기기는 사용되지 않습니다.',
          },
        ],
      },
      {
        id: 10,
        title: `가맹점 신청이 거절되었는데,${
          isMobile ? '\n' : ' '
        }재신청할 수 있는 방법이 있나요?`,
        height: 100,
        content: [
          {
            subTitle:
              'KTP 홈페이지 "가맹점 신청" 또는 ktp@ktaxpay.com를 통해 재신청이 가능합니다.',
          },
        ],
      },
      {
        id: 11,
        title: '가맹점 신청이 거절되는 경우는 무엇인가요?',
        height: 100,
        content: [
          {
            subTitle:
              '가맹점 신청이 거절되는 사유는 가맹 가입 시 필요한 서류 누락 또는 잘못된 정보 입력 등이 있습니다.',
          },
        ],
      },
      {
        id: 12,
        title: '가맹점용 패키지의 구성이 어떻게 되어있나요?',
        height: isMobile ? 150 : 125,
        content: [
          {
            subTitle:
              'KTP 패키지의 구성품은 스마트폰 거치대, 매장용 스티커, POP팜플렛, 관광객 안내용 팜플렛으로 구성되어 있으며,\n가맹 계약 시 무료로 제공해 드리고 있습니다.',
          },
        ],
      },
    ];
  }

  read() {
    return this.data;
  }
}
