import styled from 'styled-components';

import TargetOne from 'assets/refund/taxRefundTarget_1.png';
import TargetTwo from 'assets/refund/taxRefundTarget_2.png';
import FlexWrap from 'components/_common/FlexWrap';
import Typography from 'components/_common/Typography';
import LazyImage from 'components/_common/LazyImage';
import useDeviceCheck from 'hooks/useDeviceCheck';

type InfoSectionProps = {
  data: { image: string; alt: string; title: string; content: string };
};
function InfoSection({
  data: { image, alt, title, content },
}: InfoSectionProps) {
  const { isTablet } = useDeviceCheck();
  return (
    <Background>
      <Wrapper>
        <Container>
          <Title>{title}</Title>
          <Image src={image} alt={alt} />
          <Content>{content}</Content>
          <SubTitle>환급 가능 대상자</SubTitle>
          <RefundTargetContainer
            gap={isTablet ? '4rem' : '10rem'}
            margin='0 auto'
            justifyContent='center'
          >
            <FlexWrap dir='column' alignItems='center'>
              <Icon src={TargetOne} />
              <RefundTargetTitle>외국인</RefundTargetTitle>
              <RefundTargetContent>
                국내 체류
                <br />
                6개월 미만
              </RefundTargetContent>
            </FlexWrap>
            <FlexWrap dir='column' alignItems='center'>
              <Icon src={TargetTwo} />
              <RefundTargetTitle>재외국민</RefundTargetTitle>
              <RefundTargetContent>
                외국 2년 이상 거주, <br /> 국내 3개월 미만 거주
              </RefundTargetContent>
            </FlexWrap>
          </RefundTargetContainer>
          <Typography color='#80848A' size='1rem'>
            *단, 구매일로부터 3개월 이내 출국 필수
          </Typography>
        </Container>
      </Wrapper>
      <div className='custom-shape-divider-bottom-1'>
        <svg
          data-name='Layer 1'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 1200 120'
          preserveAspectRatio='none'
        >
          <path
            d='M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z'
            className='shape-fill'
          ></path>
        </svg>
      </div>
    </Background>
  );
}
const Background = styled.div`
  position: relative;
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1190px;
  width: 100%;
  margin: 0 auto;
  padding: 6.75rem 7.75rem 6.25rem;
  @media ${(props) => props.theme.tablet} {
    padding: 3.35rem 1.25rem 3.35rem;
  }
`;
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: pre-wrap;
`;
const Title = styled.h2`
  font-size: 2rem;
  line-height: 150%;
  text-align: center;
  font-weight: 700;
  @media ${(props) => props.theme.tablet} {
    font-size: 1.5rem;
    margin-top: 2rem;
  }
`;
const Content = styled.p`
  font-size: 1rem;
  line-height: 150%;
  text-align: center;
  color: #4d4d4d;
`;
const Image = styled.img`
  width: 100%;
  height: 25.4rem;
  border-radius: 0.75rem;
  object-fit: cover;
  margin: 2rem 0 1.75rem;
  @media ${(props) => props.theme.tablet} {
    height: 20rem;
  }
  @media ${(props) => props.theme.mobile} {
    height: 12.5rem;
  }
`;
const SubTitle = styled.h3`
  font-size: 1.5rem;
  margin: 4.5rem 0 3rem;
`;
const Icon = styled.img`
  width: 6.5rem;
  height: 4.5rem;
`;
const RefundTargetContainer = styled(FlexWrap)`
  margin-bottom: 3.5rem;
`;
const RefundTargetTitle = styled.span`
  font-size: 1.3rem;
  margin: 1.25rem 0 1.5rem;
  font-weight: 700;
`;
const RefundTargetContent = styled.p`
  font-size: 1rem;
  line-height: 150%;
  text-align: center;
  color: #80848a;
`;
export default InfoSection;
