import React from 'react';
import styled from 'styled-components';
import { getGraphColor } from 'utils/common';
import { VictoryAxis } from 'victory-axis';
import { VictoryBar } from 'victory-bar';
import { VictoryChart } from 'victory-chart';

import activeProfileIcon from 'assets/home/SalesIncrease/active_profile.png';
import arrowIcon from 'assets/home/SalesIncrease/arrow.png';
import profileIcon from 'assets/home/SalesIncrease/profile.png';
import FlexWrap from 'components/_common/FlexWrap';
import Icon from 'components/_common/Icon';
import Typography from 'components/_common/Typography';
import useDeviceCheck from 'hooks/useDeviceCheck';

const data = [
  { x: 1, y: 2.6, label: '2,519,118 명' },
  { x: 2, y: 1, label: '967,003 명' },
  { x: 3, y: 3.3, label: '3,198,017 명' },
  { x: 4, y: 4, label: '3,470,158 명' },
];

type SalesIncreaseGraphContentProps = {
  isActive: boolean;
};

function SalesIncreaseGraphContent({
  isActive,
}: SalesIncreaseGraphContentProps) {
  const { isMobile, isTablet } = useDeviceCheck();

  return (
    <GraphContentContainer>
      <GraphContainer>
        <Typography size='1.31rem' fontWeight='700' color='#4D4D4D'>
          연도별 외국인 관광객 증가율
        </Typography>
        <IncreaseContainer
          gap={isTablet ? '1.5rem' : '4rem'}
          alignItems='center'
          margin='1.25rem 0'
        >
          <Increase dir='column'>
            <Typography size='1.125rem' color='#999' lineHeight='130%'>
              2020년도 5월기준
              <br />
              12만명 방문
            </Typography>
            <IncreaseIconContainer alignItems='center' gap='1rem'>
              <Icon imgUrl={profileIcon} width='1.5rem' height='1.5rem' />
              <Typography
                size='1.75rem'
                fontWeight={isMobile ? '700' : '500'}
                lineHeight={isMobile ? '28px' : '44px'}
                color='#999'
              >
                29%
              </Typography>
            </IncreaseIconContainer>
          </Increase>
          <Icon imgUrl={arrowIcon} width='24px' height='24px' />
          <Increase dir='column'>
            <Typography size='1.125rem' color='#1856CD' lineHeight='130%'>
              2023년도 5월 기준
              <br />
              <Typography fontWeight='700'>80만명 방문</Typography>
            </Typography>
            <IncreaseIconContainer alignItems='center' gap='1rem'>
              <Icon imgUrl={activeProfileIcon} width='2rem' height='2rem' />
              <Typography size='1.75rem' fontWeight='700' color='#1856CD'>
                727%
              </Typography>
            </IncreaseIconContainer>
          </Increase>
        </IncreaseContainer>
        {isActive && (
          <VictoryChart
            domainPadding={{ x: 60 }}
            animate={{ duration: 400 }}
            width={650}
            height={isMobile ? 184 : 300}
          >
            <VictoryAxis
              style={{
                axis: {
                  stroke: '#000000',
                  strokeWidth: 2,
                },
                tickLabels: {
                  textAlign: 'center',
                  fontSize: 14,
                  fontWeight: 400,
                  fill: '#5F6165',
                  fontFamily: 'Pretendard',
                },
                ticks: {
                  size: isMobile ? 10 : 5,
                  padding: -4,
                },
              }}
              tickValues={[1, 2, 3, 4]}
              tickFormat={['2020', '2021', '2022', '2023']}
            />
            <VictoryBar
              data={data}
              style={{
                data: {
                  fill: ({ index }) => getGraphColor(+index!),
                },
                labels: {
                  fontFamily: 'Pretendard',
                  fontSize: 14,
                  lineHeight: 24,
                  fill: '#1856CD',
                },
              }}
              cornerRadius={4}
              barRatio={1}
            />
          </VictoryChart>
        )}
      </GraphContainer>
      <GraphContent>
        <PercentContainer gap={isMobile ? '0.5rem' : '1.5rem'}>
          <Typography size='1.325rem' fontWeight='600' color='#1856CD'>
            작년 대비 외국인 증가율은?
          </Typography>
          <PercentText>727%</PercentText>
        </PercentContainer>
        <Typography
          size='1rem'
          fontWeight={isMobile ? '400' : '500'}
          color='#191919'
        >
          한국문화정보연구원 자료에 의하면 외국인 관광객이{isMobile && <br />}
          작년 5월 기준 727%증가했어요.
          {!isMobile && <br />}
          꾸준히 증가하는{isMobile && <br />} 외국인 관광객을 텍스리펀으로
          사로잡아보세요!
        </Typography>
      </GraphContent>{' '}
    </GraphContentContainer>
  );
}

const GraphContentContainer = styled(FlexWrap)`
  flex-direction: column;
  align-items: center;
  padding: 2.5rem 0 6.25rem;
  @media ${(props) => props.theme.mobile} {
    padding: 0.75rem 0 4.38rem;
  }
`;
const GraphContent = styled(FlexWrap)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 1.35rem;
  opacity: 1;
  @media ${(props) => props.theme.mobile} {
    text-align: left;
  }
`;

const PercentContainer = styled(FlexWrap)`
  margin-top: 2.6rem;
  @media ${(props) => props.theme.mobile} {
    flex-direction: column;
    align-items: center;
  }
`;
const PercentText = styled.p`
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 150%;
  border: 1px solid #030303;
  padding: 0.1rem 1.5rem 0.06rem;
  border-radius: 0.75rem;
  @media ${(props) => props.theme.mobile} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const GraphContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 40px 0px #dde4ef;
  border-radius: 20px;
  padding: 32px 0;
`;
const IncreaseContainer = styled(FlexWrap)``;
const Increase = styled(FlexWrap)`
  @media ${(props) => props.theme.mobile} {
    justify-content: space-between;
    height: 88px;
  }
`;
const IncreaseIconContainer = styled(FlexWrap)``;

export default SalesIncreaseGraphContent;
