import React from 'react';
import LazyImage from 'components/_common/LazyImage';
import styled from 'styled-components';

interface IProps {
  text?: string;
  author?: string;
  imgUrl: string;
  width: string;
  height: string;
  alt: string;
}

function ReviewItem({ imgUrl, alt, width, height }: IProps) {
  return (
    <CardContainer className='cardItem'>
      <Image src={imgUrl} width={width} height={height} alt={alt} />
    </CardContainer>
  );
}
const CardContainer = styled.div`
  display: flex;
  color: #000000;
  height: 100%;
  @media ${(props) => props.theme.tablet} {
  }
`;

const Image = styled.img<{ width: string; height: string }>`
  border-radius: 8px;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;

export default ReviewItem;
