import { useEffect, useState } from 'react';

function useDeviceCheck() {
  const [isMobile, setIsMobile] = useState(isMobileCheck());
  const [isTablet, setIsTablet] = useState(isTabletCheck());

  function isTabletCheck() {
    return window.innerWidth < 1024;
  }
  function isMobileCheck() {
    return window.innerWidth < 576;
  }

  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsMobile(isMobileCheck());
      setIsTablet(isTabletCheck());
    });
    return () =>
      window.removeEventListener('resize', () => {
        setIsMobile(isMobileCheck());
        setIsTablet(isTabletCheck());
      });
  }, []);

  return { isMobile, isTablet };
}

export default useDeviceCheck;
