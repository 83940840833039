import React from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import InnerLayout from './InnerLayout';

interface IProps {
  links: ILink[];
}

interface ILink {
  text: string;
  link: string;
  pathname: string;
}

function TabButton({ links }: IProps) {
  const { pathname } = useLocation();

  return (
    <Container>
      <Inner>
        <Tabs>
          {links.map((item, index) => (
            <Link key={index} to={item.link}>
              <Tab active={item.pathname === pathname}>{item.text}</Tab>
            </Link>
          ))}
        </Tabs>
      </Inner>
    </Container>
  );
}

const Container = styled.section`
  background-color: #ffffff;
  text-align: center;
  padding: 54px 0;
  @media ${(props) => props.theme.mobile} {
    position: sticky;
    top: 30px;
    padding: 36px 0 0px 0;
    z-index: 10;
  }
`;
const Inner = styled(InnerLayout)`
  @media ${(props) => props.theme.tablet} {
    padding: 0;
  }
`;
const Tabs = styled.ul`
  display: flex;
  justify-content: center;
  gap: 110px;
  @media ${(props) => props.theme.tablet} {
    gap: 0;
    justify-content: space-around;
  }
`;

const Tab = styled.li<{ active: boolean }>`
  font-size: 1.2rem;
  line-height: 150%;
  cursor: pointer;
  font-weight: ${(props) => (props.active ? 700 : 500)};
  color: ${(props) => (props.active ? '#191919' : '#80848A')};
  padding: 0 1rem 0.8rem;
  border-bottom: ${(props) => (props.active ? '0.13rem' : '0')} solid #030303;
`;

export default TabButton;
