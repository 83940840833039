import React from 'react';
import styled from 'styled-components';

import AppStore from 'assets/common/apple_store.png';
import PlayStore from 'assets/common/play_store.png';
import FlexWrap from './FlexWrap';

interface IProps {
  isSmall?: boolean;
  color?: string;
  borderWidth?: number;
  margin?: string;
}

function StoreButtons({
  isSmall = false,
  color,
  borderWidth = 1,
  margin,
}: IProps) {
  return (
    <ButtonWrapper margin={margin}>
      <StyledLink
        href='https://play.google.com/store/apps/details?id=com.successmode.ktp&hl=ko&gl=US'
        target='_blank'
        color={color}
        borderWidth={borderWidth}
      >
        <StoreImage alt='KTP play store.' src={PlayStore} />
        <StyledText isSmall={isSmall} color={color}>
          Google Play
        </StyledText>
      </StyledLink>
      <StyledLink
        href='https://apps.apple.com/kr/app/ktp/id1566544989'
        target='_blank'
        color={color}
        borderWidth={borderWidth}
      >
        <StoreImage alt='KTP app store.' src={AppStore} />
        <StyledText color={color} isSmall={isSmall}>
          App Store
        </StyledText>
      </StyledLink>
    </ButtonWrapper>
  );
}

const ButtonWrapper = styled(FlexWrap)<{
  isTrial?: boolean;
  isGlobalpay?: boolean;
  margin?: string;
}>`
  gap: 0.44rem;
  margin: ${(props) => props.margin};
`;

const StoreImage = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`;

const StyledLink = styled.a<{ color?: string; borderWidth?: number }>`
  padding: 0 1rem;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 3.25rem;
  border: ${(props) => props.borderWidth}px solid
    ${(props) => props.color || '#191919'};
  border-radius: 0.5rem;
  background-color: white;
  transition: all 200ms ease-in;
  :hover,
  :active {
    background-color: #edeeef;
  }
`;
const StyledText = styled.span<{
  isSmall: boolean;
  color?: string;
}>`
  white-space: nowrap;
  font-size: ${(props) => (props.isSmall ? '1rem' : '1.25rem')};
  margin-left: 17px;
  font-weight: 400;
  color: ${(props) => props.color || '#191919'};
  @media ${(props) => props.theme.tablet} {
    font-size: 1rem;
  }
`;
export default StoreButtons;
