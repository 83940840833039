import { useContext } from 'react';
import {
  TrialDispatch,
  TrialDispatchContext,
} from 'components/context/TrialContextProvider';

function useTrialDispatch(): TrialDispatch {
  const dispatch = useContext(TrialDispatchContext);

  return dispatch;
}

export default useTrialDispatch;
