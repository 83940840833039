import { QueryClient, QueryClientProvider } from 'react-query';
import { PersistGate } from 'redux-persist/integration/react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import RouterContainer from 'router';
import { ThemeProvider } from 'styled-components';
import { theme, GlobalStyle } from 'styles';
import { store, persistor } from 'store';
import ChannelService from 'components/_common/ChannelToc';
import { useEffect } from 'react';
import './App.css';

const queryClient = new QueryClient();

function App() {
  const channelService = new ChannelService();

  useEffect(() => {
    channelService.boot();
    return () => {
      channelService.shutdown();
    };
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <HelmetProvider>
              <GlobalStyle />
              <RouterContainer />
            </HelmetProvider>
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
