import styled from 'styled-components';
import FlexWrap from './FlexWrap';
interface IProps {
  imgUrl: string;
  title: string;
  content?: string;
}

function TopBanner({ title, content, imgUrl }: IProps) {
  const onClickMoveInquiry = () => {
    const target = document.querySelector('#inquiry');
    target?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div id='page-banner'>
      <Backdrop dir='column' imgUrl={imgUrl}>
        <Container dir='column' alignItems='flex-start'>
          <Title>{title}</Title>
          <Content>{content}</Content>
          <Button onClick={onClickMoveInquiry}>도입 문의하기</Button>
        </Container>
      </Backdrop>
    </div>
  );
}

const Backdrop = styled(FlexWrap)<{ imgUrl: string }>`
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(${(props) => props.imgUrl});
  background-size: cover;
  background-position: center;
  padding: 9.75rem 7.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  @media ${(props) => props.theme.tablet} {
    padding: 7.35rem 1.25rem 4.35rem;
  }
`;
const Container = styled(FlexWrap)`
  max-width: 1190px;
  width: 100%;
`;

const Title = styled.h1`
  color: #fff;
  font-size: 2rem;
  line-height: 150%;
  white-space: pre-line;
  text-align: center;
  font-weight: 700;
`;
const Content = styled.p`
  word-break: keep-all;
  margin-top: 50px;
  color: white;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 150%;
  white-space: pre-line;
`;
const Button = styled.button`
  padding: 0.75rem 2.5rem;
  font-size: 1rem;
  background-color: #246cf6;
  color: #fff;
  border-radius: 0.25rem;
  margin-top: 2rem;
  :hover {
    opacity: 0.85;
  }
`;
export default TopBanner;
