import {
  ITrialProps,
  TrialStateContext,
} from 'components/context/TrialContextProvider';
import { useContext } from 'react';

function useTrialState(): ITrialProps {
  const state = useContext(TrialStateContext);

  return state;
}

export default useTrialState;
