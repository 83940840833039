import { ReactNode, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { SlideUp } from 'animation';
import arrowIcon from 'assets/home/KtpExample/arrow.png';
import DownloadIcon from 'assets/globalPay/download.png';
import CopyLinkIcon from 'assets/globalPay/copy_link.png';
import FlexWrap from 'components/_common/FlexWrap';
import LazyImage from 'components/_common/LazyImage';
import Typography from 'components/_common/Typography';
import StoreButtons from 'components/_common/StoreButtons';

import useDeviceCheck from 'hooks/useDeviceCheck';

type ProcessItemProps = {
  item: {
    title: string;
    keyword: string;
    content: string;
    action?: 'DOWNLOAD' | 'COPY_LINK';
    image: string;
  };
  idx: number;
  actionCallback?: () => void;
};

function ProcessItem({ item, idx, actionCallback }: ProcessItemProps) {
  const [isActive, setIsActive] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  const { title, keyword, action, image, content } = item;
  const { isTablet } = useDeviceCheck();

  const observerCallback: IntersectionObserverCallback = (entries) => {
    if (entries[0].isIntersecting) {
      setIsActive(true);
    }
  };

  useEffect(() => {
    let observer: IntersectionObserver;
    if (divRef.current) {
      observer = new IntersectionObserver(observerCallback, {
        threshold: 0.6,
      });
      observer.observe(divRef.current);
    }
    return () => observer && observer.disconnect();
  }, []);

  return (
    <Container ref={divRef} isReverse={(idx + 1) % 2 === 0}>
      <LazyImage src={image} width='23rem' height='18rem' alt='도입 방법.' />
      <ContentContainer>
        <TopContent>
          <Typography size='1.3rem' fontWeight='500' color='#1856CD'>
            {keyword}
          </Typography>
          <Typography
            size={isTablet ? '1.5rem' : '1.75rem'}
            fontWeight='700'
            color='#191919'
            margin={isTablet ? '0 0 1rem' : '0 0 2rem'}
          >
            {title}
          </Typography>
          {action && (
            <ActionBtn onClick={actionCallback}>
              {action === 'DOWNLOAD' ? '다운로드 하기' : '메일 주소 복사'}
              <ActionIcon
                src={action === 'DOWNLOAD' ? DownloadIcon : CopyLinkIcon}
              />
            </ActionBtn>
          )}
        </TopContent>
        <BottomContent>
          {isTablet && title === `KTP 앱 다운로드` && (
            <StoreButtons margin='0 0 2.5rem' />
          )}
          <Typography size='1rem' fontWeight='500' color='#191919'>
            {content}
          </Typography>
          {action === 'COPY_LINK' && !isTablet && (
            <Email onClick={actionCallback}>ktp@ktaxpay.com</Email>
          )}
        </BottomContent>
      </ContentContainer>
    </Container>
  );
}

const Container = styled.div<{ isReverse: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isReverse ? 'row-reverse' : 'row')};
  width: 100%;
  gap: 10rem;
  padding: 6.75rem 0;
  @media ${(props) => props.theme.tablet} {
    gap: 0;
    flex-direction: column;
    padding: 2.81rem 0;
    align-items: center;
  }
`;
const ContentContainer = styled(FlexWrap)`
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  @media ${(props) => props.theme.tablet} {
    margin-top: 2.64rem;
    align-items: center;
    width: 100%;
  }
`;
const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;
  @media ${(props) => props.theme.tablet} {
    text-align: center;
    white-space: normal;
  }
`;

const BottomContent = styled(FlexWrap)`
  flex-direction: column;
  white-space: pre-wrap;
  gap: 12px;

  @media ${(props) => props.theme.tablet} {
    align-items: center;
    text-align: center;
  }
`;
const ActionBtn = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #1856cd;
  font-size: 1.312rem;
  margin-top: -1.3rem;
  @media ${(props) => props.theme.tablet} {
    margin: 0 auto 1rem;
  }
`;
const ActionIcon = styled.img`
  width: 1.25rem;
  height: 1.25rem;
`;
const Email = styled.button`
  text-decoration: underline;
  color: #246cf6;
  font-size: 1rem;
  text-align: left;
`;
export default ProcessItem;
