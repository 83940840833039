import React from 'react';
import { Link } from 'react-router-dom';
import { refundRoutes } from 'routes';

import styled from 'styled-components';

const subLinks = [
  {
    text: '매장 환급',
    link: refundRoutes.taxRefund,
    pathname: '/refund',
  },
  {
    text: '병원 환급',
    link: refundRoutes.medical,
    pathname: '/refund/medical',
  },
  {
    text: '해외 간편 결제',
    link: refundRoutes.globalPayment,
    pathname: '/refund/global-payment',
  },
];
function SubMenu() {
  return (
    <Container className='subMenu'>
      {subLinks.map((link, idx) => (
        <MenuLink key={idx} to={link.link}>
          <Menu>{link.text}</Menu>
        </MenuLink>
      ))}
      {<SubMenuBg className='menu_bg' />}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  gap: 60px;
  position: absolute;
  top: 50px;
  left: 0px;
  width: 500px;
  height: 60px;
  pointer-events: none;
  transition: height 300ms ease-in;
  padding-top: 20px;
`;

const MenuLink = styled(Link)`
  display: flex;
  align-items: center;
  transition: height 300ms ease-in;
  overflow: hidden;
  height: 0px;
`;

const Menu = styled.div`
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  cursor: pointer;
  color: #191919;
  :hover {
    opacity: 0.6;
  }
`;
const SubMenuBg = styled.div`
  position: fixed;
  height: 0px;
  z-index: -1;
  top: 70px;
  left: 0px;
  background-color: #fff;

  width: 100vw;
  transition: height 300ms ease-in;
`;
export default SubMenu;
