import styled from 'styled-components';

const PhoneContainer = styled.div`
  background-color: white;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 45px;
`;

export default PhoneContainer;
