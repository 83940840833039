import React from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';

import InnerLayout from 'components/_common/InnerLayout';
import { PolicyItemContent } from 'constants/Policy';
import PolicyList from '../PolicyList';
import PolicyDetail1 from './PolicyDetail1';

function PolicyDetail() {
  const {
    state: { detail, index },
  } = useLocation();

  const DetailType = () => {
    switch (detail.type) {
      case 1:
        return (
          <PolicyDetail1
            detail={detail}
            PolicyContentArray={PolicyItemContent[index]?.content}
          />
        );
      default:
        return <span>준비중...</span>;
    }
  };

  return (
    <>
      <DetailType />
      <Container>
        <Inner>
          <Title>최근에 올라온 지원정책</Title>
          <PolicyList policyList={PolicyItemContent.slice(0, 6)} />
        </Inner>
      </Container>
    </>
  );
}

const Container = styled.div`
  background-color: #f2f2f2;
  text-align: center;
  padding: 40px 0 100px 0;
  @media ${(props) => props.theme.mobile} {
    padding: 40px 0 100px 0;
  }
`;

const Inner = styled(InnerLayout)``;

const Title = styled.p`
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
  text-align: left;
  @media ${(props) => props.theme.mobile} {
    font-size: 22px;
    line-height: 30px;
  }
`;

export default PolicyDetail;
