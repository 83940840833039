import React from 'react';
import styled from 'styled-components';

import MainContainerLayout from 'components/_common/MainContainerLayout';
import BrowserTitle from 'components/_common/BrowserTitle';
import TrialSection from 'components/Trial/TrialSection';
import TrialContextProvider from 'components/context/TrialContextProvider';
import StoreButtons from 'components/_common/StoreButtons';
import IPhone from 'assets/trial/iphone_frame.png';
import useDeviceCheck from 'hooks/useDeviceCheck';

function Trial() {
  const { isTablet } = useDeviceCheck();
  return (
    <MainContainerLayout>
      <TrialContextProvider>
        <BrowserTitle
          title='체험해보기 | 택스프리 케이티피'
          description='소상공인을 위한 무약정, 무료 즉시환급 솔루션 KTP에 가입하여 간단하게 TAX FREE 매장이 될 수 있습니다!'
          url='https://www.ktaxpay.com/trial'
        />
        <Wrapper>
          <ContentContainer>
            {isTablet && (
              <Title>
                TAX FREE 버튼을 눌러
                <br />
                KTP만의 환급 절차를
                <br />
                체험해보세요
              </Title>
            )}
            {!isTablet && (
              <Container>
                <Title>
                  TAX FREE 버튼을 눌러
                  <br />
                  KTP만의 환급 절차를
                  <br />
                  체험해보세요
                </Title>
                <StoreButtons />
              </Container>
            )}
            <TrialContainer>
              <PhoneFrame
                width='397px'
                height='786px'
                src={IPhone}
                alt='핸드폰.'
              />
              <TrialSection />
            </TrialContainer>
            {isTablet && <StoreButtons />}
          </ContentContainer>
        </Wrapper>
      </TrialContextProvider>
    </MainContainerLayout>
  );
}
const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media ${(props) => props.theme.tablet} {
    height: 49rem;
    flex-direction: column;
    justify-content: space-between;
  }
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1190px;
  width: 100%;
  margin: 0 auto;
  padding: 6.75rem 7.75rem 6.25rem;
  @media ${(props) => props.theme.tablet} {
    padding: 4rem 1rem;
  }
`;
const TrialContainer = styled.div`
  position: relative;
  @media ${(props) => props.theme.tablet} {
    transform: scale(60%);
    margin-top: -29rem;
    height: 20rem;
  }
`;

const PhoneFrame = styled.img`
  z-index: 1;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 160px;
`;

const Title = styled.h1`
  font-size: 1.75rem;
  line-height: 150%;
  text-align: center;
  margin-bottom: 4.62rem;
  @media ${(props) => props.theme.tablet} {
    transform: scale(50%);
    font-size: 2rem;
  }
`;
export default Trial;
