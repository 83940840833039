import React from 'react';
import styled from 'styled-components';

function Layout({ children }: any) {
  return (
    <LayoutContainer>
      <Main>{children}</Main>
    </LayoutContainer>
  );
}

const LayoutContainer = styled.div`
  width: 100%;
  /* overflow: hidden; */
`;

const Main = styled.main`
  width: 100%;
  position: relative;
`;

export default Layout;
