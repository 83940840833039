import FlexWrap from 'components/_common/FlexWrap';
import useDeviceCheck from 'hooks/useDeviceCheck';
import React from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import ProcessItem from './ProcessItem';

type ProcessSectionProps = {
  data: Array<{
    title: string;
    linkUrl?: string;
    linkLabel?: string;
    keyword: string;
    content: {
      explain: string;
      items?: Array<string>;
    };
    image: string;
  }>;
};
function ProcessSection({ data }: ProcessSectionProps) {
  const { pathname } = useLocation();
  const { isMobile } = useDeviceCheck();

  return (
    <Wrapper>
      <Container>
        <Title>
          <ColoredTitle>KTP</ColoredTitle>
          {pathname === '/refund/medical'
            ? `미용성형 환급 서비스${isMobile ? '\n' : ''} 도입 방법은?`
            : '택스리펀 서비스 도입 방법은?'}
        </Title>
        <List>
          {data.map((item, idx) => (
            <ProcessItem key={idx} item={item} idx={idx} length={data.length} />
          ))}
        </List>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f4f8ff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
`;
const Container = styled.div`
  max-width: 1190px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6.75rem 7.75rem 6.25rem;
  @media ${(props) => props.theme.tablet} {
    padding: 3.35rem 1.25rem 3.35rem;
  }
`;
const Title = styled.h2`
  white-space: pre-wrap;
  font-size: 2rem;
  line-height: 150%;
  text-align: center;
  font-weight: 700;
  @media ${(props) => props.theme.mobile} {
    font-size: 1.5rem;
  }
`;
const ColoredTitle = styled(Title)`
  color: #246cf6;
  display: inline;
  margin-right: 0.5rem;
`;
const List = styled(FlexWrap)`
  flex-direction: column;
  width: 100%;
`;
export default ProcessSection;
