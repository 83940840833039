import { ReactNode, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { SlideUp } from 'animation';
import arrowIcon from 'assets/home/KtpExample/arrow.png';
import arrowBlueIcon from 'assets/refund/taxRefund/arrow_blue.png';
import FlexWrap from 'components/_common/FlexWrap';
import LazyImage from 'components/_common/LazyImage';
import Typography from 'components/_common/Typography';
import StoreButtons from 'components/_common/StoreButtons';
import Icon from 'components/_common/Icon';
import { useKTPExample } from 'hooks/useKTPExample';
import useDeviceCheck from 'hooks/useDeviceCheck';

type ProcessItemProps = {
  item: {
    title: string;
    linkUrl?: string;
    linkLabel?: string;
    keyword: string;
    content: {
      explain: string;
      items?: Array<string>;
    };
    image: string;
  };
  idx: number;
  length: number;
};

function ProcessItem({ item, idx, length }: ProcessItemProps) {
  const [isActive, setIsActive] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  const { title, keyword, content, image, linkUrl, linkLabel } = item;
  const { isTablet } = useDeviceCheck();

  const observerCallback: IntersectionObserverCallback = (entries) => {
    if (entries[0].isIntersecting) {
      setIsActive(true);
    }
  };

  useEffect(() => {
    let observer: IntersectionObserver;
    if (divRef.current) {
      observer = new IntersectionObserver(observerCallback, {
        threshold: 0.6,
      });
      observer.observe(divRef.current);
    }
    return () => observer && observer.disconnect();
  }, []);

  return (
    <Container ref={divRef} isReverse={(idx + 1) % 2 === 0}>
      <LazyImage src={image} width='23rem' height='18rem' alt='도입 방법.' />
      <ContentContainer>
        <TopContent>
          <Typography size='1.3rem' fontWeight='500' color='#1856CD'>
            {keyword}
          </Typography>
          <Typography
            size={isTablet ? '1.5rem' : '1.75rem'}
            fontWeight='700'
            color='#191919'
            margin={isTablet ? '0 0 1rem' : '0 0 2rem'}
          >
            {title}
          </Typography>
          {linkUrl && (
            <Link href={linkUrl} target='_blank'>
              {linkLabel}
              <LinkArrow src={arrowBlueIcon} />
            </Link>
          )}
        </TopContent>
        <BottomContent>
          {isTablet && title === `KTP 앱\n다운로드 및 회원가입` && (
            <StoreButtons margin='0 0 2.5rem' />
          )}
          {content?.items && (
            <FlexWrap alignItems='center' gap='12px' margin='0 0 1rem'>
              {content?.items?.map((item, i) => (
                <>
                  <Progress>{item}</Progress>
                  {i !== 2 && (
                    <Icon
                      imgUrl={arrowIcon}
                      width='1.375rem'
                      height='1.375rem'
                    />
                  )}
                </>
              ))}
            </FlexWrap>
          )}
          <Typography size='1rem' fontWeight='500' color='#191919'>
            {content.explain}
          </Typography>
        </BottomContent>
      </ContentContainer>
    </Container>
  );
}

const Container = styled.div<{ isReverse: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isReverse ? 'row-reverse' : 'row')};
  width: 100%;
  gap: 10rem;
  padding: 6.75rem 0;
  @media ${(props) => props.theme.tablet} {
    gap: 0;
    flex-direction: column;
    padding: 2.81rem 0;
    align-items: center;
  }
`;
const ContentContainer = styled(FlexWrap)`
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  @media ${(props) => props.theme.tablet} {
    margin-top: 2.64rem;
    align-items: center;
    width: 100%;
  }
`;
const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;
  @media ${(props) => props.theme.tablet} {
    text-align: center;
    white-space: normal;
  }
`;

const BottomContent = styled(FlexWrap)`
  flex-direction: column;
  white-space: pre-wrap;
  gap: 12px;

  @media ${(props) => props.theme.tablet} {
    align-items: center;
    text-align: center;
  }
`;
const Progress = styled(FlexWrap)`
  text-align: center;
  line-height: 150%;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 5rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #1856cd;
  background-color: #fff;
  border: 1px solid #1856cd;
  border-radius: 50%;
`;
const Link = styled.a`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #1856cd;
  font-size: 1.312rem;
  margin-top: -1.3rem;
  @media ${(props) => props.theme.tablet} {
    margin: 0 auto 1rem;
  }
`;
const LinkArrow = styled.img`
  width: 1.25rem;
  height: 1.25rem;
`;

export default ProcessItem;
