export function checkWebpSupport(webp: string, img: string) {
  // Webp를 지원하는 여부를 확인
  // 오래된 브라우저는 canvas를 지원 하지 않음.
  const elem = document.createElement('canvas');
  if (!!(elem.getContext && elem.getContext('2d'))) {
    if (elem.toDataURL('image/webp').indexOf('data:image/webp') == 0) {
      return webp;
    }
  }
  return img;
}
