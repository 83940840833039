import React from 'react';
import styled from 'styled-components';
import PhoneContainer from 'components/Trial/_common/PhoneContainer';
import PhoneHeader from 'components/Trial/_common/PhoneHeader';
import ContentInner from 'components/Trial/_common/ContentInner';
import StoreButtons from 'components/_common/StoreButtons';

import iphoneMockupMobile from 'assets/home/TopSection/iphone_mockup_m.png';

function DownloadFrame() {
  return (
    <PhoneContainer>
      <PhoneHeader keyword='APP_DOWNLOD' />
      <ContentInner>
        <Title>
          지금 KTP앱을 <br />
          다운로드하세요!
        </Title>
        <StoreButtons isSmall />
      </ContentInner>
      <PhoneImgMobile
        width='320px'
        height='400px'
        src={iphoneMockupMobile}
        alt='모바일폰.'
      />
    </PhoneContainer>
  );
}
const Title = styled.h2`
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  margin: 28px 0;
`;
const PhoneImgMobile = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 0 0 45px 0;
`;
export default DownloadFrame;
