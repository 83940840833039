import styled from 'styled-components';

import ContentInner from 'components/Trial/_common/ContentInner';
import FlexWrap from 'components/_common/FlexWrap';
import CloseWhite from 'assets/trial/close_white.png';
import ArrowImg from 'assets/trial/arrow_up.png';
import Taxfree from 'assets/trial/mode_scan.png';
import useTrialActions from 'hooks/useTrialActions';

function SelectModeGuide() {
  const { onMove, isShow, onCloseModal } = useTrialActions();
  return (
    <Wrapper isShow={isShow} onClick={onCloseModal}>
      <CloseButton>
        <CloseImage width='100%' height='100%' src={CloseWhite} alt='닫기.' />
      </CloseButton>
      <ContentInner>
        <FlexWrap dir='column' alignItems='center' margin='120px 0 0 0'>
          <TaxFreeBtn onClick={onMove}>
            <Icon width='40px' height='40px' src={Taxfree} alt='텍스프리.' />{' '}
            TAX FREE
          </TaxFreeBtn>
          <StyledImg width='100%' height='100%' src={ArrowImg} alt='화살표.' />
          <Notification>‘TAX FREE’ 버튼을 클릭해보세요</Notification>
        </FlexWrap>
      </ContentInner>
    </Wrapper>
  );
}

const Wrapper = styled.div<{ isShow: boolean }>`
  position: absolute;
  display: ${(props) => (props.isShow ? 'block' : 'none')};
  height: 100%;
  inset: 0;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 40px;
`;
const Button = styled.button`
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.primaryTextColor};
  color: #0b42ad;
  font-size: 28px;
  line-height: 32px;
  background-color: #e9f0fe;
  transition: all ease-in 200ms;
  cursor: default;
`;
const TaxFreeBtn = styled(Button)`
  width: 100%;
  padding: 40px 46px;
  margin-bottom: 20px;
  gap: 30px;
  cursor: pointer;
  background-color: '#005F83';
  :hover {
    opacity: 0.8;
  }
`;
const Icon = styled.img``;
const CloseButton = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  top: 70px;
  right: 20px;
`;

const CloseImage = styled.img`
  object-fit: cover;
`;

const StyledImg = styled.img`
  width: 44px;
  height: 56px;
`;

const Notification = styled.p`
  font-weight: 300;
  margin-top: 8px;
  text-align: center;
  color: #fff;
  font-size: 20px;
  line-height: 28px;
`;

export default SelectModeGuide;
