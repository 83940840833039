import React from 'react';
import styled from 'styled-components';

import ContentInner from 'components/Trial/_common/ContentInner';
import useTrialState from 'hooks/useTrialState';
import useTrialActions from 'hooks/useTrialActions';
import CloseWhite from 'assets/trial/close_white.png';
import ArrowImg from 'assets/trial/arrow_down.png';
import FlexWrap from 'components/_common/FlexWrap';
import Input from 'components/_common/Input';

interface IWrapperProps {
  isShow: boolean;
}
function PurchaseInfoGuide() {
  const { toggleMark } = useTrialState();
  const { onToggleModal } = useTrialActions();

  return (
    <Wrapper isShow={toggleMark} onClick={onToggleModal}>
      <CloseButton>
        <CloseImage width='100%' height='100%' src={CloseWhite} alt='닫기.' />
      </CloseButton>
      <ContentInner>
        <FlexWrap dir='column'>
          <Notification>상품 금액을 입력해보세요</Notification>
          <StyledImg width='100%' height='100%' src={ArrowImg} alt='화살표.' />
          <InputWrapper>
            <StyledInput placeholder='상품금액을 입력하세요' />
          </InputWrapper>
        </FlexWrap>
      </ContentInner>
    </Wrapper>
  );
}
const Wrapper = styled.div<IWrapperProps>`
  position: absolute;
  display: ${(props) => (props.isShow ? 'block' : 'none')};
  height: 100%;
  width: 500px;
  inset: 0;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 48px 48px 43px 43px;
  @media ${(props) => props.theme.tablet} {
    border-radius: 0;
  }
`;
const StyledInput = styled(Input)`
  text-align: right;
  border: none;
  font-size: 15px;
`;
const InputWrapper = styled.div`
  padding-right: 28px;
`;
const CloseButton = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  top: 70px;
  right: 20px;
`;

const CloseImage = styled.img`
  object-fit: cover;
`;

const StyledImg = styled.img`
  width: 44px;
  height: 56px;
  margin: 0px 0 8px 120px;
`;

const Notification = styled.p`
  font-weight: 300;
  margin: 95px 0 8px;
  text-align: center;
  color: #fff;
  font-size: 20px;
  line-height: 28px;
`;
export default React.memo(PurchaseInfoGuide);
