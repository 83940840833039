import React from 'react';
import styled from 'styled-components';

import PhoneContainer from 'components/Trial/_common/PhoneContainer';
import PhoneHeader from 'components/Trial/_common/PhoneHeader';
import CheckIcon from 'assets/trial/check.png';
import FlexWrap from 'components/_common/FlexWrap';
import useTrialActions from 'hooks/useTrialActions';
import useTrialState from 'hooks/useTrialState';
import { comma } from 'utils/format';
import Icon from 'components/_common/Icon';

function RefundResultFrame() {
  const { onMove } = useTrialActions();
  const { refundInfo } = useTrialState();
  const { payments, refunds, sales } = refundInfo;

  return (
    <PhoneContainer>
      <PhoneHeader keyword='REFUND_COMPLETE' />
      <InnerContent>
        <Notification>
          <FlexWrap alignItems='center'>
            <CheckWrapper>
              <Icon width='20px' height='20px' imgUrl={CheckIcon} alt='확인.' />
            </CheckWrapper>
            <NotiText>매장환급이 완료되었습니다.</NotiText>
          </FlexWrap>
        </Notification>
        <Price>
          <h5>결제금액</h5>
          <p>
            <span>{payments.toLocaleString()}</span>
            <span>원</span>
          </p>
        </Price>
        <ResultContainer>
          <ResultWrapper>
            <Label>상품금액</Label>
            <TextWrapper>
              <div>{comma(sales)}</div>
              <div>원</div>
            </TextWrapper>
          </ResultWrapper>
          <ResultWrapper>
            <Label>환급액</Label>
            <TextWrapper>
              <div>{refunds.toLocaleString()}</div>
              <div>원</div>
            </TextWrapper>
          </ResultWrapper>
          <ResultWrapper>
            <Label>결제금액</Label>
            <TextWrapper>
              <div>{payments.toLocaleString()}</div>
              <div>원</div>
            </TextWrapper>
          </ResultWrapper>
        </ResultContainer>
        <Button onClick={onMove}>확인</Button>
      </InnerContent>
    </PhoneContainer>
  );
}
const InnerContent = styled.div`
  width: 350px;
`;
const Notification = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75px;
  background: #edeeef;
  color: #246cf6;
  margin-top: 42px;
`;

const CheckWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
`;

const NotiText = styled.p`
  font-size: 14px;
  line-height: 22px;
`;

const Price = styled.div`
  margin: 20px 20px 28px;
  & h5 {
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
  }
  & p {
    & span:nth-of-type(1) {
      font-size: 36px;
      line-height: 54px;
      color: #246cf6;
    }
    & span:nth-of-type(2) {
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
      margin-left: 4px;
      color: ${(props) => props.theme.fontColorContent};
    }
  }
`;

const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;
  margin: 0 20px;
  border-top: 2px solid #000;
  & > div:nth-of-type(2) {
    border-bottom: 1px dashed ${(props) => props.theme.fontColorFooter};
    padding-bottom: 16px;
  }
`;

const ResultWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  & div:nth-of-type(1) {
    font-size: 24px;
    line-height: 32px;
  }
  & div:nth-of-type(2) {
    margin: 0 0 0 4px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: ${(props) => props.theme.fontColorContent};
  }
`;

const Label = styled.div`
  font-size: 16px;
  line-height: 24px;
`;
const Button = styled.button`
  position: absolute;
  outline: none;
  cursor: pointer;
  display: block;
  width: calc(100% - 32px);
  font-size: 30px;
  line-height: 54px;
  padding: 10px 0;
  border-radius: 4px;
  font-family: 'Pretendard', sans-serif;
  background-color: #246cf6;
  color: #fff;
  bottom: 20px;
  left: 16px;
`;
export default RefundResultFrame;
