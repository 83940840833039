import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import Typography from 'components/_common/Typography';

type GlobalModalProps = {
  isMail?: boolean;
  isMailCopy?: boolean;
  setState: Dispatch<SetStateAction<boolean>>;
};

function GlobalModal({ isMail, isMailCopy, setState }: GlobalModalProps) {
  return (
    <Backdrop>
      <ModalContainer>
        <Typography
          size='22px'
          lineHeight='1.36em'
          fontWeight='bold'
          color='#05191f'
        >
          {isMail && isMailCopy
            ? '메일 주소 복사'
            : isMail && !isMailCopy
            ? '메일 주소 복사 실패'
            : '서류 다운로드'}
        </Typography>
        <Typography
          size='18px'
          lineHeight='1.5em'
          fontWeight='500'
          color='#666'
          margin='24px 0 16px 0'
        >
          {isMail && isMailCopy
            ? '메일주소를 복사했습니다.\n복사한 주소로 작성한 서류를 보내주세요.'
            : isMail && !isMailCopy
            ? '메일 주소를 복사를 지원하지 않는 브라우저입니다.\n직접 복사하거나 PC로 다시 시도해주세요.'
            : '신청에 필요한 서류를 한번에 다운로드합니다.'}
        </Typography>
        <Typography
          size='14px'
          lineHeight='1.5em'
          fontWeight='500'
          color='#999'
        >
          {isMail
            ? ' ktp@ktaxpay.com'
            : '해외 간편 결제 (큐릭) 이용 신청서 1부\n해외 간편 결제 (큐릭) 이용 계약서 1부'}
        </Typography>
        <ModalButton onClick={() => setState(false)}>
          {isMail && !isMailCopy ? '닫기' : '확인'}
        </ModalButton>
      </ModalContainer>
    </Backdrop>
  );
}

const Backdrop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
`;
const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 480px;
  height: 288px;
  background-color: #ffffff;
  border-radius: 24px;
  text-align: center;
  white-space: pre-wrap;
`;
const ModalButton = styled.button`
  font-size: 16px;
  line-height: 1.5em;
  font-weight: bold;
  margin-top: 32px;
  background-color: #246cf6;
  padding: 12px 65px;
  color: #fff;
  border-radius: 8px;
`;

export default GlobalModal;
