import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

import FlexWrap from 'components/_common/FlexWrap';
import InnerLayout from 'components/_common/InnerLayout';
import { PolicyItemContent } from 'constants/Policy';
import Search from './Search';
import { IPolicyList } from 'types/service';
import PolicyList from './PolicyList';

function PolicyContent() {
  const [isAddButton, setIsAddButton] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [policyList, setPolicyList] = useState<IPolicyList[]>([]);

  useEffect(() => {
    const newArray = PolicyItemContent.slice(0, 6 * currentIndex);
    setPolicyList(newArray);
  }, []);

  useEffect(() => {
    showAddButton();
  }, [policyList]);

  const showAddButton = () => {
    const isShow = policyList.length >= currentIndex * 6;
    setIsAddButton(isShow);
  };

  const onAdd = () => {
    setCurrentIndex(currentIndex + 1);
    setPolicyList(PolicyItemContent.slice(0, 6 * currentIndex + 1));
  };

  return (
    <>
      {!isMobile && (
        <Search
          searchList={PolicyItemContent}
          setSearchList={setPolicyList}
          placeholder='궁금한 정책 찾아보기'
        />
      )}
      <Container>
        <Inner>
          <Header>
            <Title>
              <span style={{ color: '#808080' }}>
                한 번에 알아보는 {isMobile && <br />}
              </span>
              지원정책 소식
            </Title>
            {isMobile && (
              <Search
                searchList={PolicyItemContent}
                setSearchList={setPolicyList}
                placeholder='궁금한 정책 찾아보기'
              />
            )}
          </Header>
          <PolicyList policyList={policyList} />
          {isAddButton && <AddButton onClick={onAdd}>+ 더보기</AddButton>}
        </Inner>
      </Container>
    </>
  );
}

const Container = styled.div`
  background-color: #ffffff;
  text-align: center;
  margin: 40px 0 108px;
`;

const Inner = styled(InnerLayout)``;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${(props) => props.theme.mobile} {
    flex-direction: column;
    gap: 40px;
  }
`;

const Title = styled.h3`
  font-size: 30px;
  line-height: 38px;
  font-weight: 700;
  text-align: left;
  @media ${(props) => props.theme.mobile} {
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    span {
      font-size: 20px;
      line-height: 28px;
      font-weight: 500;
    }
  }
`;

const PolicyItem = styled(FlexWrap)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  :hover {
    p {
      position: relative;
      ::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #000000;
      }
    }
  }
  @media ${(props) => props.theme.mobile} {
    align-items: flex-start;
  }
`;

const AddButton = styled.div`
  margin-top: 60px;
  font-size: 22px;
  line-height: 30px;
  font-weight: 700;
  color: #4d4d4d;
  cursor: pointer;
  @media ${(props) => props.theme.mobile} {
  }
`;

export default PolicyContent;
