import React, { ReactElement } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

import LazyImage from 'components/_common/LazyImage';
import { Link } from 'react-router-dom';

interface IProps {
  idx: number;
  image: string;
  contentTitle: string;
  length: number;
  PolicyItem: ReactElement;
}

function PolicyTitleImage({
  idx,
  image,
  contentTitle,
  PolicyItem,
  length,
}: IProps) {
  return (
    <>
      {idx === 0 ? (
        <>
          <LazyImage
            src={image}
            width='100%'
            height={isMobile ? '220px' : '480px'}
            alt='정책 참고자료.'
            borderRadius='8px'
          />
          {PolicyItem}
          <DivideLine />
        </>
      ) : (
        <>
          <Title>{contentTitle}</Title>
          {PolicyItem}
          {idx + 1 === length ? (
            <Link to='/refund'>
              <LazyImage
                src={image}
                width='100%'
                height={isMobile ? '220px' : '480px'}
                alt='정책 참고자료.'
                borderRadius='8px'
              />
            </Link>
          ) : (
            <LazyImage
              src={image}
              width='100%'
              height={isMobile ? '220px' : '480px'}
              alt='정책 참고자료.'
              borderRadius='8px'
            />
          )}
        </>
      )}
    </>
  );
}

const Image = styled.img`
  border-radius: 6px;
  object-fit: contain;
`;

const Title = styled.h4`
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
  text-align: left;
  @media ${(props) => props.theme.mobile} {
    font-size: 20px;
    line-height: 28px;
  }
`;

const DivideLine = styled.div`
  width: 100%;
  height: 0.5px;
  background-color: #585656;
`;

export default PolicyTitleImage;
