import React from 'react';
import styled from 'styled-components';

function ReactQuill() {
  const content = `<p><strong class=\"ql-size-large\">안녕하세요안녕하세요안녕하세요안녕하세요안녕하세요안녕하세요안녕하세요안녕하세요안녕하세요안녕하세요안녕하세요안녕하세요안녕하세요~~~~</strong></p><p><img src=\"https://ktp-image.s3.ap-northeast-2.amazonaws.com/notice/2023-11-02T15%3A04%3A10.026899\" alt=\"image\"></p><p>이미지 입니다.</p>`;
  return (
    <Wrapper>
      <ContentContainer
        dangerouslySetInnerHTML={{ __html: content }}
      ></ContentContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  img {
  }
  @media ${(props) => props.theme.mobile} {
    img {
      width: 100%;
      object-fit: contain;
      padding: 0 10px;
    }
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: 46px 0px;
  height: 80%;
  overflow: auto;
`;

export default ReactQuill;
