import React from 'react';
import styled from 'styled-components';

import ContentInner from '../_common/ContentInner';
import PhoneContainer from '../_common/PhoneContainer';
import PhoneHeader from '../_common/PhoneHeader';
import TaxfreeIcon from 'assets/trial/mode_scan.png';
import CancelIcon from 'assets/trial/mode_cancel.png';
import SalesIcon from 'assets/trial/mode_sales.png';
import MypageIcon from 'assets/trial/mode_mypage.png';
import FlexWrap from 'components/_common/FlexWrap';
import useTrialActions from 'hooks/useTrialActions';
import SelectModeGuide from '../modal/SelectModeGuide';

function SelectMode() {
  const { onMove } = useTrialActions();
  return (
    <Wrapper>
      <PhoneContainer>
        <PhoneHeader keyword='HOME' />
        <ContentInner>
          <Title>
            원하는 기능을
            <br />
            <Bold>선택해주세요</Bold>
          </Title>
          <TaxFreeBtn onClick={onMove}>
            <Icon
              width='40px'
              height='40px'
              src={TaxfreeIcon}
              alt='텍스프리.'
            />
            TAX FREE
          </TaxFreeBtn>
          <BtnWrapper>
            <SquareBtnWrapper dir='column'>
              <SquareBtn>
                <Icon
                  width='40px'
                  height='40px'
                  src={CancelIcon}
                  alt='텍스프리 취소.'
                />
                TAX FREE 취소
              </SquareBtn>
              <SquareBtn>
                <Icon
                  width='40px'
                  height='40px'
                  src={SalesIcon}
                  alt='매출내역.'
                />
                매출 내역
              </SquareBtn>
            </SquareBtnWrapper>
            <VerticalBtn>
              <Icon
                width='40px'
                height='40px'
                src={MypageIcon}
                alt='마이페이지.'
              />
              마이페이지
            </VerticalBtn>
          </BtnWrapper>
        </ContentInner>
        <SelectModeGuide />
      </PhoneContainer>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  z-index: 6;
  width: 100vw;
`;
const Title = styled.h2`
  font-size: 22px;
  line-height: 30px;
  font-weight: 400;
  margin-bottom: 60px;
`;
const BtnWrapper = styled(FlexWrap)`
  gap: 20px;
  width: 100%;
`;
const SquareBtnWrapper = styled(FlexWrap)`
  gap: 20px;
  flex: 1 1 50%;
`;
const Button = styled.button`
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #80848a;
  color: #3a3b3e;
  font-size: 28px;
  line-height: 32px;
  background-color: #f5f6f7;
  transition: all ease-in 200ms;
  cursor: default;
`;
const TaxFreeBtn = styled(Button)`
  display: flex;
  justify-content: center;
  padding: 40px 46px;
  margin-bottom: 20px;
  gap: 30px;
  cursor: pointer;
  color: #0b42ad;
  border: 1px solid #1856cd;
  font-size: 28px;
  line-height: 32px;
  font-weight: 500;
  background-color: #e9f0fe;
  :hover {
    background-color: rgba(0, 95, 131, 0.15);
  }
`;
const SquareBtn = styled(Button)`
  flex-direction: column;
  padding: 20px 0;
  flex: 1;
  gap: 20px;
  font-size: 20px;
  line-height: 28px;
`;
const VerticalBtn = styled(SquareBtn)`
  padding: 92px 0;
  flex: 1 1 50%;
`;
const Icon = styled.img``;
const Bold = styled.strong`
  font-weight: 500;
`;
export default SelectMode;
