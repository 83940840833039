import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

import LoadingView from 'components/_common/LoadingView';
import { routes } from 'routes';

function ApplicationComplete() {
  const location = useLocation();
  const navigate = useNavigate();
  const scrollY = location?.state?.yPosition || 0;
  useEffect(() => {
    alert('도입문의 신청이 완료되었습니다.');
    navigate(routes.home, {
      state: {
        yPosition: scrollY ?? 0,
      },
    });
  }, []);

  return <LoadingView />;
}

export default ApplicationComplete;
