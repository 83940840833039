import React from 'react';
import styled from 'styled-components';
import Typography from 'components/_common/Typography';
import AppScanImg from 'assets/globalPay/passport.png';
import FlexWrap from 'components/_common/FlexWrap';
import { benefitKeywords } from 'constants/GlobalPay';
import useDeviceCheck from 'hooks/useDeviceCheck';

function Benefits() {
  const { isTablet } = useDeviceCheck();
  return (
    <Container>
      <Inner>
        {isTablet && (
          <TitleContainer>
            <Typography size='1.7rem' fontWeight='bold' color='#191919'>
              추가 설치 없이 텍스 리펀까지!
            </Typography>
            <Typography size='1.2rem' color='#808080' margin='16px 0 37px 0'>
              해외 간편 결제와 텍스 리펀을 함께 이용해보세요.
              <br />
              KTP 앱으로 한 번에 신청 가능합니다.
            </Typography>
          </TitleContainer>
        )}
        <PhoneImage src={AppScanImg} />
        <ContentContainer>
          {!isTablet && (
            <>
              <Typography size='2rem' fontWeight='bold' color='#191919'>
                추가 설치 없이 텍스 리펀까지!
              </Typography>
              <Typography
                size='1.375rem'
                color='#808080'
                margin='16px 0 54px 0'
              >
                해외 간편 결제와 텍스 리펀을 함께 이용해보세요.
                <br />
                KTP 앱으로 한 번에 신청 가능합니다.
              </Typography>
            </>
          )}
          <Typography
            size={isTablet ? '1.2rem' : '1.375rem'}
            fontWeight='700'
            color='#191919'
            margin='0 0 23px 0'
          >
            지금 텍스 리펀과 해외 간편 결제를{isTablet && <br />} 동시
            신청하시면
          </Typography>
          <FlexWrap gap={isTablet ? '8px' : '20px'}>
            {benefitKeywords.map((item, idx) => (
              <Keyword key={idx}>
                <Typography
                  size={isTablet ? '0.875rem' : '1rem'}
                  fontWeight='500'
                  color={isTablet ? '#5f6165' : '#191919'}
                >
                  {item.keyword}
                </Typography>
                <Typography
                  size={isTablet ? '1rem' : '1.2rem'}
                  fontWeight='600'
                  color='#246cf6'
                >
                  {item.benefit}
                </Typography>
              </Keyword>
            ))}
          </FlexWrap>
          <FlexWrap dir='column' justifyContent='center'>
            <Typography size='0.875rem' color='#b3b3b3' margin='2rem 0 3rem'>
              본 행사는 선착순 조기 종료 될 수 있습니다.
            </Typography>
            <Typography
              size={isTablet ? '18px' : '22px'}
              fontWeight='600'
              color='#246cf6'
            >
              빠른 상담 02-6275-8011
            </Typography>
          </FlexWrap>
        </ContentContainer>
      </Inner>
    </Container>
  );
}

const Container = styled.section`
  position: relative;
  width: 100vw;
  padding: 160px 0 240px;
  @media ${(props) => props.theme.tablet} {
    padding: 116px 0px 80px;
  }
`;
const Inner = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  max-width: 1190px;
  margin: 0 auto;
  align-items: center;
  padding: 0 8rem;
  @media ${(props) => props.theme.tablet} {
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    gap: 0;
  }
`;
const PhoneImage = styled.img`
  width: 21rem;
  height: 30rem;
  @media ${(props) => props.theme.desktop} {
    z-index: -1;
    transform: scale(130%) translate(-20px, 20px);
  }
  @media ${(props) => props.theme.tablet} {
    width: 29rem;
    height: 42rem;
    transform: translateX(20px);
  }
`;
const TitleContainer = styled(FlexWrap)`
  flex-direction: column;
  align-items: center;
  text-align: center;
`;
const ContentContainer = styled.div`
  @media ${(props) => props.theme.tablet} {
    text-align: center;
  }
`;

const Keyword = styled(FlexWrap)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.62rem;
  padding: 2.31rem 1.5rem;
  background-color: #fff;
  border-radius: 0.75rem;
  box-shadow: 0px 0px 28px 0px #dde4ef;
  @media ${(props) => props.theme.tablet} {
    gap: 0px;
    padding: 2rem 1rem;
  }
`;

export default Benefits;
