import React from 'react';

import Definition from 'components/GlobalPay/Definition';
import BrowserTitle from 'components/_common/BrowserTitle';
import MainContainerLayout from 'components/_common/MainContainerLayout';
import Procedure from 'components/GlobalPay/Procedure';
import Benefits from 'components/GlobalPay/Benefits';
import RefundAplication from 'components/_common/RefundAplication';
import BusinessType from 'components/GlobalPay/BusinessType';

function GlobalPayment() {
  return (
    <MainContainerLayout>
      <BrowserTitle
        title='해외 간편 결제 | 알리페이 위챗페이'
        description='알리페이, 위챗페이, 알리페이 플러스 등 중국인관광객을 위한 KTP해외결제 신청페이지'
        url='https://www.ktaxpay.com/refund/global-payment'
      />
      <Definition />
      <BusinessType />
      <Procedure />
      <Benefits />
      <RefundAplication />
    </MainContainerLayout>
  );
}

export default GlobalPayment;
