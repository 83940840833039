import React from 'react';
import styled from 'styled-components';
import closeImg from 'assets/common/close.png';
import dotImg from 'assets/common/dot.png';

function RefundModal({ setIsModal }: any) {
  return (
    <Container>
      <Title>개인정보 수집 및 이용 안내</Title>
      <ContentContainer>
        <Content style={{ marginBottom: '20px' }}>
          개인정보보호법 등 관련 법률에 따라 개인정보 수집 시 정보 주체에게
          사전고지하여 이에 대한 동의를 받습니다.
        </Content>
        <Content>
          · 개인정보의 수집 및 이용 목적: 서비스 이용에 따른 상담업무 처리를
          위한 본인 식별, 본인 의사 확인 및 상담 결과 통보
        </Content>
        <Content>
          · 수집하는 개인정보 항목: 이름, 연락처, 이메일, 가맹점명, 문의사항
        </Content>
        <Content>· 개인정보 보유 및 이용 기간: 동의 일로부터 6개월</Content>
      </ContentContainer>
      <CloseImg src={closeImg} onClick={() => setIsModal(false)} />
    </Container>
  );
}

const Container = styled.div`
  max-width: 80%;
  min-width: 1180px;
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 35px;
  border-radius: 4px;
  background-color: #fff;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 10px 0 rgba(25, 25, 25, 0.2);
  @media ${(props) => props.theme.mobile} {
    min-width: 340px;
    overflow-y: auto;
    padding: 20px 20px 20px 20px;
    top: 55%;
  }
`;

const Title = styled.p`
  padding-top: 30px;
  font-size: 32px;
  line-height: 44px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 32px;
  @media ${(props) => props.theme.mobile} {
    font-size: 24px;
    line-height: 36px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding: 20px 28px;
  background-color: #f2f2f2;
`;

const Content = styled.p`
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  text-align: left;
  @media ${(props) => props.theme.mobile} {
    font-size: 16px;
    line-height: 24px;
  }
`;

const DotImg = styled.img`
  width: 4px;
  height: 4px;
  object-fit: contain;
`;

const CloseImg = styled.img`
  position: absolute;
  top: 32px;
  right: 32px;
  width: 18px;
  height: 18px;
  cursor: pointer;
`;

export default RefundModal;
