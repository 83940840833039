import { useCallback, useMemo, useRef, useState } from 'react';
import useTrialDispatch from 'hooks/useTrialDispatch';
import { IRefundInfoProps } from 'components/context/TrialContextProvider';

// Frame 사이 간격을 띄우기 위해 margin-right: 85가 적용되어 있음, 따라서 해당 너비(85) 추가 이동 필요
// 1. 움직이는 너비를 인자값을 통해 받는다.
// 2. 일정 이하 innerWidth 일때 받은 인자값을 하지만 모바일이라면 윈도우 너비를
function useTrialActions() {
  const [isShow, setIsShow] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const passportRef = useRef<HTMLDivElement>(null);
  const dispatch = useTrialDispatch();

  const moveWidth = useMemo(() => {
    return 430;
  }, []);

  const onMove = useCallback(() => {
    dispatch({
      type: 'NEXT',
      payload: moveWidth,
    });
  }, [dispatch, moveWidth]);

  const onBack = useCallback(() => {
    dispatch({ type: 'PREV', payload: moveWidth });
  }, [dispatch, moveWidth]);

  const onBackWithToggle = useCallback(() => {
    dispatch({ type: 'PREV', payload: moveWidth });
    setTimeout(() => {
      dispatch({ type: 'TOGGLE_MARK', payload: null });
    }, 310);
  }, [dispatch, moveWidth]);

  const onHome = useCallback(() => {
    dispatch({ type: 'HOME', payload: null });
    setTimeout(() => {
      dispatch({ type: 'TOGGLE_TRANSITION', payload: null });
    }, 150);
  }, [dispatch]);

  const onToggleModal = useCallback(() => {
    dispatch({ type: 'TOGGLE_MARK', payload: null });
  }, [dispatch]);

  const onMoveWithToggle = useCallback(() => {
    dispatch({
      type: 'NEXT_WITH_TOGGLE',
      payload: moveWidth,
    });
  }, [dispatch, moveWidth]);

  // props에 따라 전환하기 고려 (리팩토링)
  const onMoveWithFlowScan = useCallback(() => {
    dispatch({ type: 'FLOW_CHANGE', payload: true });
    dispatch({
      type: 'NEXT',
      payload: moveWidth,
    });
  }, [dispatch, moveWidth]);

  const onMoveWithFlowMart = useCallback(() => {
    dispatch({ type: 'FLOW_CHANGE', payload: false });
    dispatch({
      type: 'NEXT_WITH_TOGGLE',
      payload: moveWidth,
    });
  }, [dispatch, moveWidth]);

  const onSetRefundInfo = useCallback(
    (refundInfo: IRefundInfoProps) => {
      dispatch({ type: 'SET_REFUND_INFO', payload: refundInfo });
    },
    [dispatch]
  );

  const onResetRefundInfo = useCallback(() => {
    dispatch({ type: 'RESET_REFUND_INFO', payload: null });
  }, [dispatch]);
  const onCloseModal = () => {
    setIsShow(false);
  };
  const visibleLoading = () => {
    setIsLoading(true);
  };
  const unVisibleLoading = () => {
    setIsLoading(false);
  };
  const passportSlide = () => {
    if (!passportRef.current) return;
    passportRef.current.style.transform = 'translateX(-50%)';
    passportRef.current.style.left = '50%';
    visibleLoading();
    setTimeout(() => {
      onMove();
      unVisibleLoading();
      if (!passportRef.current) return;
      passportRef.current.style.transform = 'translateX(-148%)';
    }, 2000);
  };
  return {
    onMove,
    onBack,
    onHome,
    onToggleModal,
    onMoveWithToggle,
    onBackWithToggle,
    onMoveWithFlowScan,
    onMoveWithFlowMart,
    onSetRefundInfo,
    onResetRefundInfo,
    isShow,
    isLoading,
    onCloseModal,
    setIsLoading,
    passportRef,
    passportSlide,
  };
}

export default useTrialActions;
