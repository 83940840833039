import styled from 'styled-components';

import MainImgPC from 'assets/globalPay/main_pc.jpg';
import MainImgTablet from 'assets/globalPay/main_tablet.jpg';
import MainImgMobile from 'assets/globalPay/main_mobile.jpg';
import PayTypesImg from 'assets/globalPay/pay_types.png';
import useDeviceCheck from 'hooks/useDeviceCheck';

function Definition() {
  const { isTablet, isMobile } = useDeviceCheck();

  return (
    <Wrapper>
      <Container>
        <Title>KTP 해외 간편 결제란?</Title>
        <MainImg
          src={isMobile ? MainImgMobile : isTablet ? MainImgTablet : MainImgPC}
        />
        <Description>
          알리페이, 위챗페이 등 {isMobile && <br />}전 세계인이 사용하는 간편
          결제 수단을 <br />앱 하나로 관리하는 스마트 페이 솔루션입니다.
        </Description>
        {!isTablet && <PayTypes src={PayTypesImg} />}
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1190px;
  width: 100%;
  margin: 0 auto;
  padding: 6.75rem 7.75rem 6.25rem;
  @media ${(props) => props.theme.tablet} {
    padding: 3.35rem 1.25rem 3.35rem;
  }
`;
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: pre-wrap;
`;

const Title = styled.h2`
  font-size: 2rem;
  line-height: 150%;
  font-weight: 700;
  @media ${(props) => props.theme.tablet} {
    font-size: 1.5rem;
    margin-top: 2rem;
  }
`;
const MainImg = styled.img`
  width: 100%;
  height: 25.4rem;
  border-radius: 0.75rem;
  object-fit: cover;
  margin: 2rem 0 1.75rem;
  @media ${(props) => props.theme.tablet} {
    height: 20rem;
  }
  @media ${(props) => props.theme.mobile} {
    height: 12.5rem;
  }
`;
const PayTypes = styled.img`
  width: 60%;
  object-fit: contain;
`;
const Description = styled.p`
  font-size: 1rem;
  line-height: 150%;
  color: #3a3b3e;
  text-align: center;
  margin-bottom: 1.2rem;
`;
export default Definition;
