import { TaxRefundContent } from 'constants/TaxRefund';
import InfoSection from './InfoSection';
import RefundAplication from 'components/_common/RefundAplication';
import ProcessSection from './ProcessSection';

function TaxRefund() {
  const {
    infoSection: { taxRefund },
    processSection: { taxRefund: processTaxRefund },
  } = TaxRefundContent;

  return (
    <>
      <InfoSection data={taxRefund} />
      <ProcessSection data={processTaxRefund} />
      <RefundAplication />
    </>
  );
}
export default TaxRefund;
