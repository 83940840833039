export const routes = {
  home: '/',
  refund: '/refund',
  service: '/service',
  faq: '/faq',
  application: '/application',
  trial: '/trial',
  applicationComplete: '/application/complete',
};

export const refundRoutes = {
  taxRefund: '/refund',
  medical: '/refund/medical',
  globalPayment: '/refund/global-payment',
};

export const serviceRoutes = {
  franchisee: '/service',
  franchiseeDetail: '/service/franchisee/detail',
  policy: '/service/policy',
  policyDetail: '/service/policy/detail',
};
