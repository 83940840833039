import TabButton from 'components/_common/TabButton';
import React from 'react';
import { serviceRoutes } from 'routes';
import FranchiseeContent from './FranchiseeContent';

const links = [
  {
    text: '가맹점 소개',
    link: serviceRoutes.franchisee,
    pathname: '/service',
  },
  {
    text: '지원정책 소식',
    link: serviceRoutes.policy,
    pathname: '/service/policy',
  },
];

function Franchisee() {
  return (
    <>
      <TabButton links={links} />
      <FranchiseeContent />
    </>
  );
}

export default Franchisee;
