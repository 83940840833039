import styled from 'styled-components';

import Typography from 'components/_common/Typography';
import useSlideUpImage from 'hooks/useSlideUpImage';
import SalesIncreaseGraphContent from './SalesIncreaseGraphContent';
import useDeviceCheck from 'hooks/useDeviceCheck';

function SalesIncrease() {
  const { divRef, isActive } = useSlideUpImage(0.15);
  const { isTablet } = useDeviceCheck();

  return (
    <Wrapper>
      <Container ref={divRef}>
        <Title>
          매출 증가에 도움이 되는{isTablet ? <br /> : ' '}
          <Typography color='#1856CD'>TAX REFUND</Typography>{' '}
        </Title>
        <SalesIncreaseGraphContent isActive={isActive} />
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1190px;
  width: 100%;
  margin: 0 auto;
  padding: 6.75rem 7.75rem 6.25rem;
  @media ${(props) => props.theme.tablet} {
    padding: 3.35rem 1.25rem 3.35rem;
  }
`;
const Container = styled.div`
  width: 100%;
  flex-direction: column;
`;
const Title = styled.h2`
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
`;

export default SalesIncrease;
