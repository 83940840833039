import React, { createContext, Dispatch, useReducer } from "react";
import { isMobile } from "react-device-detect";

export interface IRefundInfoProps {
  sales: string;
  refunds: string;
  payments: string;
}

export interface ITrialProps {
  transformWidth: number;
  toggleMark: boolean;
  toggleTransition: boolean;
  refundInfo: IRefundInfoProps;
}

type Action =
  | "NEXT"
  | "PREV"
  | "TOGGLE_MARK"
  | "PREV_WITH_TOGGLE"
  | "NEXT_WITH_TOGGLE"
  | "HOME"
  | "TOGGLE_TRANSITION"
  | "SET_REFUND_INFO"
  | "RESET_REFUND_INFO"
  | "FLOW_CHANGE";

// Swiper width: 460px
interface IAction {
  type: Action;
  payload: number | IRefundInfoProps | boolean | null;
}

const initialState = {
  transformWidth: 0,
  toggleMark: true,
  toggleTransition: true,
  refundInfo: {
    sales: "",
    refunds: "0",
    payments: "0",
  },
};

function reducer(state: ITrialProps, action: IAction) {
  switch (action.type) {
    case "NEXT":
      return {
        ...state,
        transformWidth: state.transformWidth + (action.payload as number),
      };
    case "PREV":
      return {
        ...state,
        transformWidth: state.transformWidth - (action.payload as number),
        refundInfo: initialState.refundInfo,
      };
    case "PREV_WITH_TOGGLE":
      return {
        ...state,
        toggleMark: true,
        transformWidth: state.transformWidth - (action.payload as number),
        refundInfo: initialState.refundInfo,
      };
    case "NEXT_WITH_TOGGLE":
      return {
        ...state,
        toggleMark: true,
        transformWidth: state.transformWidth + (action.payload as number),
      };
    case "TOGGLE_MARK":
      return {
        ...state,
        toggleMark: !state.toggleMark,
      };
    case "HOME":
      return {
        ...state,
        refundInfo: initialState.refundInfo,
        toggleTransition: !state.toggleTransition,
        transformWidth: isMobile ? 475 : 460,
        toggleMark: true,
      };
    case "TOGGLE_TRANSITION":
      return {
        ...state,
        toggleTransition: !state.toggleTransition,
      };
    case "SET_REFUND_INFO":
      return {
        ...state,
        refundInfo: {
          ...(action.payload as IRefundInfoProps),
        },
      };
    case "RESET_REFUND_INFO":
      return {
        ...state,
        refundInfo: initialState.refundInfo,
      };
    case "FLOW_CHANGE":
      return {
        ...state,
        isScan: action.payload as boolean,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

export type TrialDispatch = Dispatch<IAction>;

export const TrialStateContext = createContext<ITrialProps>(initialState);
export const TrialDispatchContext = createContext<TrialDispatch>(() => null);

interface IProps {
  children: React.ReactNode;
}

function TrialContextProvider({ children }: IProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <TrialStateContext.Provider value={state}>
      <TrialDispatchContext.Provider value={dispatch}>
        {children}
      </TrialDispatchContext.Provider>
    </TrialStateContext.Provider>
  );
}

export default TrialContextProvider;
