import React, {
  Dispatch,
  FormEventHandler,
  SetStateAction,
  useEffect,
  useRef,
} from 'react';
import styled from 'styled-components';

import InnerLayout from 'components/_common/InnerLayout';
import SearchImg from 'assets/service/search.png';
import { IFranchiseeList, IPolicyList } from 'types/service';

interface IProps {
  placeholder: string;
  searchList: IFranchiseeList[] | IPolicyList[];
  setSearchList:
    | Dispatch<SetStateAction<IPolicyList[]>>
    | Dispatch<SetStateAction<IFranchiseeList[]>>;
}

function Search({ searchList, setSearchList, placeholder }: IProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const keyword = inputRef.current!.value.trim();

    const newArray: any[] = [];
    searchList.forEach((item) => {
      if (item.keyword.trim().includes(keyword)) {
        newArray.push(item);
      }
    });
    setSearchList(newArray);
    inputRef.current!.value = '';
  };

  return (
    <Container>
      <Inner>
        <Form onSubmit={onSubmit}>
          <Input ref={inputRef} placeholder={placeholder} />
          <Icon src={SearchImg} width='24px' height='24px' />
        </Form>
      </Inner>
    </Container>
  );
}

const Container = styled.div`
  background-color: #ffffff;
  text-align: center;
`;

const Inner = styled(InnerLayout)``;

const Form = styled.form`
  display: flex;
  justify-content: space-between;
  width: 300px;
  padding: 10px 20px;
  border: 1px solid #666666;
  border-radius: 50px;
  @media ${(props) => props.theme.mobile} {
    width: 90vw;
  }
`;

const Input = styled.input`
  font-size: 14px;
  line-height: 20px;
  border: none;
  outline: none;
  font-weight: 500;
  flex: 1;
  ::placeholder {
    color: #999999;
  }
`;

const Icon = styled.img`
  cursor: pointer;
`;

export default Search;
