import React from 'react';
import styled from 'styled-components';
import { IFranchiseeList } from 'types/service';

interface IProps {
  item: IFranchiseeList;
}

function FranchisseItem({ item }: IProps) {
  return (
    <>
      <Title>
        {item.address} / {item.sectors}
      </Title>
      <Icon src={item.icon} width='24px' height='24px' alt='부제목 아이콘.' />
    </>
  );
}

const Title = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #666666;
`;

const Icon = styled.img`
  object-fit: contain;
  :hover {
  }
`;

export default FranchisseItem;
