import { Spin } from "animation";
import styled, { css } from "styled-components";
import FlexWrap from "./FlexWrap";

interface IProps {
  isGuideText?: boolean;
  isDark?: boolean;
}
function LoadingView({ isGuideText = true, isDark = true }: IProps) {
  return (
    <Container isDark={isDark}>
      <FlexWrap dir="column" alignItems="center">
        {isGuideText && (
          <GuideText>
            최대 1-2분 소요됩니다.
            <br />
            잠시만 기다려주세요.
          </GuideText>
        )}
        <LoadingSpinner />
      </FlexWrap>
    </Container>
  );
}
const Container = styled.div<{ isDark: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  ${(props) =>
    props.isDark &&
    css`
      background-color: rgba(0, 0, 0, 0.5);
    `}
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const GuideText = styled.p`
  color: White;
  margin-bottom: 12px;
  text-align: center;
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
`;
const LoadingSpinner = styled.div`
  width: 50px;
  height: 50px;
  border: 8px solid white;
  border-top: 8px solid ${(props) => props.theme.primaryColor};
  border-radius: 50%;
  animation: ${Spin} 700ms linear infinite;
  @media ${(props) => props.theme.mobile} {
    width: 36px;
    height: 36px;
    border: 6px solid white;
    border-top: 6px solid ${(props) => props.theme.primaryColor};
  }
`;
export default LoadingView;
