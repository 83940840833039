import ktpExampleRefund1 from 'assets/home/KtpExample/refund1.png';
import ktpExampleRefundWebp1 from 'assets/home/KtpExample/refundWebp1.webp';
import ktpExampleRefund2 from 'assets/home/KtpExample/refund2.png';
import ktpExampleRefundWebp2 from 'assets/home/KtpExample/refundWebp2.webp';
import ktpExampleRefund3 from 'assets/home/KtpExample/refund3.png';
import ktpExampleRefundWebp3 from 'assets/home/KtpExample/refundWebp3.webp';
import ktpExampleGlobal1 from 'assets/home/KtpExample/global1.png';
import ktpExampleGlobalWebp1 from 'assets/home/KtpExample/globalWebp1.webp';
import ktpExampleGlobal2 from 'assets/home/KtpExample/global2.png';
import ktpExampleGlobalWebp2 from 'assets/home/KtpExample/globalWebp2.webp';
import ktpExampleGlobal3 from 'assets/home/KtpExample/global3.png';
import ktpExampleGlobalWebp3 from 'assets/home/KtpExample/globalWebp3.webp';
import ktpExampleReport1 from 'assets/home/KtpExample/report1.png';
import ktpExampleReportWebp1 from 'assets/home/KtpExample/reportWebp1.webp';
import ktpExampleReport2 from 'assets/home/KtpExample/report2.png';
import ktpExampleReportWebp2 from 'assets/home/KtpExample/reportWebp2.webp';
import ktpExampleReport3 from 'assets/home/KtpExample/report3.png';
import ktpExampleReportWebp3 from 'assets/home/KtpExample/reportWebp3.webp';

import section1ImgWebp from 'assets/home/common/section1.webp';
import section1Img from 'assets/home/common/section1.png';
import section2ImgWebp from 'assets/home/common/section2.png';
import section2Img from 'assets/home/common/section2.png';
import section3ImgWebp from 'assets/home/common/section3.png';
import section3Img from 'assets/home/common/section3.png';
import section4ImgWebp from 'assets/home/common/section4.png';
import section4Img from 'assets/home/common/section4.png';
import section5ImgWebp from 'assets/home/common/section5.png';
import section5Img from 'assets/home/common/section5.png';
import usageFeeImg from 'assets/home/KTPService/usageFee.png';
import promotionImg from 'assets/home/KTPService/promotion.png';
import administrationImg from 'assets/home/KTPService/administration.png';

import brand1 from 'assets/home/VariableBrand/brand1.png';
import brand2 from 'assets/home/VariableBrand/brand2.png';
import brand3 from 'assets/home/VariableBrand/brand3.png';
import brand4 from 'assets/home/VariableBrand/brand4.png';
import brand5 from 'assets/home/VariableBrand/brand5.png';
import brand6 from 'assets/home/VariableBrand/brand6.png';
import brand7 from 'assets/home/VariableBrand/brand7.png';
import brand8 from 'assets/home/VariableBrand/brand8.png';
import brand9 from 'assets/home/VariableBrand/brand9.png';
import brand10 from 'assets/home/VariableBrand/brand10.png';

import newsBackground1 from 'assets/home/KTPNews/newsBackground1.png';
import newsBackground2 from 'assets/home/KTPNews/newsBackground2.png';
import { serviceRoutes } from 'routes';
import { checkWebpSupport } from 'utils/checkWebpSupport';
import { isMobile } from 'react-device-detect';

import bannerIcon1 from 'assets/home/TopSection/bannerIcon1.png';
import bannerIconWhite1 from 'assets/home/TopSection/bannerIcon_white1.png';
import bannerIcon2 from 'assets/home/TopSection/bannerIcon2.png';
import bannerIconWhite2 from 'assets/home/TopSection/bannerIcon_white2.png';
import bannerIcon3 from 'assets/home/TopSection/bannerIcon3.png';
import bannerIconWhite3 from 'assets/home/TopSection/bannerIcon_white3.png';
import bannerIcon4 from 'assets/home/TopSection/bannerIcon4.png';
import bannerIconWhite4 from 'assets/home/TopSection/bannerIcon_white4.png';
import Typography from 'components/_common/Typography';

import alipayIcon1 from 'assets/globalPay/global_alipay1.png';
import alipayIcon2 from 'assets/globalPay/global_alipay2.png';
import wechatpayIcon from 'assets/globalPay/global_wechatpay.png';
import paynowIcon from 'assets/globalPay/global_paynow.png';
import RefundAnalyze from 'assets/globalPay/RefundAnalyze.png';

export const KTPExampleContent = [
  {
    title: `쉽고 간편해서\n5초면 환급이 끝!`,
    keyword: '첫번째,',
    content: {
      explain: (
        <Typography fontWeight='500'>
          <Typography fontWeight='700'>단 5초만에 환급이 완료</Typography>되는
          <br />
          KTP만의 간편함을 느낄 수 있어요
        </Typography>
      ),
      items: ['여권스캔', '금액입력', '환급완료'],
    },
    images: [
      checkWebpSupport(ktpExampleRefundWebp1, ktpExampleRefund1),
      checkWebpSupport(ktpExampleRefundWebp2, ktpExampleRefund2),
      checkWebpSupport(ktpExampleRefundWebp3, ktpExampleRefund3),
    ],
  },
  {
    title: `텍스프리와\n해외결제를 합쳤다!`,
    keyword: '두번째,',
    content: {
      explain: (
        <Typography fontWeight='500'>
          <Typography fontWeight='700'>
            텍스프리 뿐 아니라 8가지 해외 간편 결제까지!
          </Typography>
          <br />
          매장에서 사용하고 있는 스마트폰, 태블릿으로
          <br />
          KTP 앱을 바로 사용해보세요
        </Typography>
      ),
      items: [alipayIcon2, alipayIcon1, , wechatpayIcon, paynowIcon],
    },
    images: [
      checkWebpSupport(ktpExampleGlobalWebp1, ktpExampleGlobal1),
      checkWebpSupport(ktpExampleGlobalWebp2, ktpExampleGlobal2),
      checkWebpSupport(ktpExampleGlobalWebp3, ktpExampleGlobal3),
    ],
  },
  {
    title: `똑똑한 KTP 앱이\n제공하는 매출리포트`,
    keyword: '세번째,',
    content: {
      explain: (
        <Typography fontWeight='500'>
          외국인 고객 분석이 필요하신가요?
          <br />
          앱에서 제공하는 매출, 고객분석을 통해
          <br />
          <Typography fontWeight='700'>마케팅에 활용</Typography>
          해보세요
        </Typography>
      ),
      items: [RefundAnalyze],
    },
    images: [
      checkWebpSupport(ktpExampleReportWebp1, ktpExampleReport1),
      checkWebpSupport(ktpExampleReportWebp2, ktpExampleReport2),
      checkWebpSupport(ktpExampleReportWebp3, ktpExampleReport3),
    ],
  },
];
export const HomeKtpExampleFilter = [
  {
    id: 1,
    filter: '5초 환급',
    title: '“여권 스캔 - 금액 입력 - 환급완료”',
    content: 'KTP만의 간편한 방식으로\n스캔부터 환급완료까지 단 10초면 끝!',
    active: true,
  },
  {
    id: 2,
    filter: 'KTP 매출 리포트',
    title: '한 눈에 보기 쉬운 환급 매출!',
    content:
      '전 주/월 대비 환급 비교부터 고객 데이터 분석까지!\n복잡한 환급 매출을 간편하게 모바일로 확인하세요.',
    active: false,
  },
  {
    id: 3,
    filter: '면세판매장 신청대행',
    title: isMobile
      ? '“회원가입 – 면세판매장 대리신청 –\n신청완료”'
      : '“회원가입 – 면세판매장 대리신청 – 신청완료”',
    content: isMobile
      ? '복잡하고 불편한 서류 신고는 이제 그만!\n대리 신청부터 결과 확인까지 한 번에 KTP 앱을 통해\n간편하게 신청하세요.'
      : '복잡하고 불편한 서류 신고는 이제 그만!\n대리 신청부터 결과 확인까지 한 번에 KTP 앱을 통해\n간편하게 신청하세요.',
    active: false,
  },
];

export const KTPBenefitsContent = [
  {
    id: 1,
    topContent: 'TAX REFUND란?',
    title: 'TAX REFUND,\n알고계신가요?',
    content: isMobile
      ? 'TAX REFUND란 외국인 관광객에게 부가가치세 및 개별소비세를\n환급해주는 제도를 말합니다. TAX REFUND를 통해 외국인\n관광객 유치 뿐 아니라 매출까지 향상 시킬 수 있습니다.  '
      : 'TAX REFUND란?\n외국인 관광객에게 부가가치세 및 개별소비세를 환급해주는 제도입니다.\n텍스리펀드를 통해 외국인 관광객 유치 뿐 아니라 매출까지 향상 시킬 수 있습니다.',
    image: checkWebpSupport(section1ImgWebp, section1Img),
    isLeftImg: false,
  },
  {
    id: 2,
    topContent: '혜택',
    title: '이용료 0원\n환급 서비스',
    content:
      '아직도 이용료 내면서 택스리펀 하나요?\n스마트폰만 있으면 간편하게 사용 가능한 KTP서비스!\n평생 무료로 이용해 보세요.',
    image: checkWebpSupport(section2ImgWebp, section2Img),
    isLeftImg: true,
  },
  {
    id: 3,
    topContent: '혜택',
    title: '면세판매장\n신청대행 서비스',
    content:
      '매장 운영으로 바쁘신 사업주 분들을 위한 신청대행 서비스!\n필요한 서류 업무를 방문 지원 뿐 아니라 간편하게 KTP 앱에서\n신청하실 수 있습니다.',
    image: checkWebpSupport(section3ImgWebp, section3Img),
    isLeftImg: false,
  },
  {
    id: 4,
    topContent: '혜택',
    title: '매장 맞춤\n홍보물 제공',
    content:
      '오직 KTP에서만 제공하는 맞춤 서비스.\n매장에 어울리지 않는 홍보물을 이제 그만!\n분위기와 컨셉에 맞춰 제공해드립니다.',
    image: checkWebpSupport(section4ImgWebp, section4Img),
    isLeftImg: true,
  },
  {
    id: 5,
    topContent: '혜택',
    title: '해외 간편 결제\n가능',
    content:
      '위쳇페이, 알리페이 등 해외 결제 요청에도 당황하지마세요.\nKTP는 해외 간편 결제까지 가능합니다.',
    image: checkWebpSupport(section5ImgWebp, section5Img),
    isLeftImg: false,
  },
];
export const HomeNewsContent = [
  {
    id: 1,
    topContent: '가맹점 소개',
    content: '외국인 고객까지 생각하는\nKTP 가맹점',
    backgaroundImg: newsBackground1,
    bottomContent: '더보기 >',
    link: serviceRoutes.franchisee,
  },
  {
    id: 2,
    topContent: '지원정책 소개',
    content: '한 번에 알아보는\n지원정책 소식',
    backgaroundImg: newsBackground2,
    bottomContent: '더보기 >',
    link: serviceRoutes.policy,
  },
];

export const KTPServiceConent = [
  {
    id: 1,
    keywordTitle: '이용료',
    keywords: ['PC', '모바일', '태블릿', 'CAT 단말기'],
    title: '단말기, 서비스 이용료 0원',
    content: `매장 맞춤 단말기와${isMobile ? '\n' : ''} 서비스를${
      !isMobile ? '\n' : ''
    }월 이용료 없이 제공하고 있어요.`,
    image: usageFeeImg,
  },
  {
    id: 2,
    keywordTitle: '홍보물',
    keywords: ['깃발형 현수막', '매장 스티커', 'X배너'],
    title: '매장 맞춤 홍보물 제공',
    content: `매장 분위기와 상황에 맞는${!isMobile ? '\n' : ''}디자인으로${
      isMobile ? '\n' : ''
    }홍보물을 제공해드리고 있어요`,
    image: promotionImg,
  },
  {
    id: 3,
    keywordTitle: '행정처리',
    keywords: [],
    title: '면세판매장 신청대행서비스',
    content: `복잡하고 귀찮은 면세판매지정신청${
      isMobile ? '\n' : ''
    }신고 대행서비스부터${!isMobile ? '\n' : ''}세무사도 어려워하는${
      isMobile ? '\n' : ''
    }면세 세금 신고 자료까지 제공해드려요!`,
    image: administrationImg,
  },
];
export const VariableBrandImages = [
  brand1,
  brand2,
  brand3,
  brand4,
  brand5,
  brand6,
  brand7,
  brand8,
  brand9,
  brand10,
  brand1,
  brand2,
  brand3,
  brand4,
  brand5,
  brand6,
  brand7,
  brand8,
  brand9,
  brand10,
];

export const BannerContentItems = [
  { icon: isMobile ? bannerIconWhite1 : bannerIcon1, keyword: '간편 환급' },
  { icon: isMobile ? bannerIconWhite2 : bannerIcon2, keyword: '해외 결제' },
  { icon: isMobile ? bannerIconWhite3 : bannerIcon3, keyword: '매출 분석' },
  { icon: isMobile ? bannerIconWhite4 : bannerIcon4, keyword: '친절 세무' },
];
