import { useEffect, useRef, useState } from "react";

interface IProps {
  src: string;
}
export function useLazyImageObserver({ src }: IProps) {
  const [imageSrc, setImageSrc] = useState<string>();
  const imageRef = useRef<any>();

  useEffect(() => {
    let observer: IntersectionObserver;
    if (imageRef && !imageSrc) {
      observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setImageSrc(src);
            observer.unobserve(imageRef.current);
          }
        },
        { threshold: 0, rootMargin: "150px" }
      );
      observer.observe(imageRef.current);
    }
    return () => {
      observer && observer.disconnect();
    };
  }, [imageRef, imageSrc, src]);

  return { imageSrc, imageRef };
}
