import React from 'react';
import styled, { css } from 'styled-components';
import Arrow from 'assets/faq/arrow.png';
import ArrowActive from 'assets/faq/arrow_active.png';
interface IActiveProps {
  isActive: boolean;
}

const SButton = styled.div<IActiveProps>`
  ${(props) =>
    !props.isActive &&
    css`
      transform: rotate(90deg);
    `}
`;

const ToggleIcon = styled.img`
  width: 30px;
  height: 30px;
  @media ${(props) => props.theme.mobile} {
    width: 20px;
    height: 20px;
  }
`;

interface IProps {
  isActive: boolean;
}

function ToggleButton({ isActive }: IProps) {
  return (
    <SButton isActive={isActive}>
      <ToggleIcon src={isActive ? ArrowActive : Arrow} alt='화살표.' />
    </SButton>
  );
}

export default ToggleButton;
