import React, { useState } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import KTPExampleItem from './KTPExampleItem';
import FlexWrap from 'components/_common/FlexWrap';
import { KTPExampleContent } from 'constants/Home';
import Typography from 'components/_common/Typography';
import Experiencing from './Experiencing';

function KTPExample() {
  return (
    <Wrapper>
      <Container>
        <Title>
          <Typography size='2rem' fontWeight='700' color='#3A3B3E'>
            <Typography color='#1856CD'>KTP</Typography>를 사용해야하는 이유!
          </Typography>
        </Title>
        <List>
          {KTPExampleContent.map((item, idx) => (
            <KTPExampleItem
              key={idx}
              item={item}
              idx={idx}
              length={KTPExampleContent.length}
            />
          ))}
        </List>
      </Container>
      <Experiencing />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding-top: 6.75rem;
  background-color: #f4f8ff;
  overflow: hidden;
  @media ${(props) => props.theme.tablet} {
    flex-direction: column;
    padding-top: 4.35rem;
  }
`;
const Container = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 1190px;
  width: 100%;
  flex-direction: column;
  padding: 0 6.5rem;
  @media ${(props) => props.theme.tablet} {
    padding: 0 1rem;
  }
`;
const Title = styled.div`
  text-align: center;
`;
const List = styled(FlexWrap)`
  flex-direction: column;
  width: 100%;
`;

export default KTPExample;
